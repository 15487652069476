import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FeedbackFormFields } from '../../enums/feedback-form-fields.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FeedbackConfig } from './feedback-config';
import { NotificationService, ErrorMessageParser } from '@xpo-ltl/data-api';
import { take, finalize, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'xpo-utils-feedback',
  template: `<div [formGroup]="userFeedbackFormGroup" class="feedback-container">
  <h2 class="mat-dialog-title feedback-container-title">User Feedback</h2>
  <mat-dialog-content>
    <div class="feedback-container-content" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field>
        <input matInput type="text" placeholder="From" maxlength="50" autocomplete="off" [formControlName]="feedbackFormFields.USER">
        <mat-error>
          <div class="mat-error" *ngIf="hasError('required', feedbackFormFields.USER)">Required</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" placeholder="Subject" maxlength="100" autocomplete="off" [formControlName]="feedbackFormFields.SUBJECT">
        <mat-error>
          <div class="mat-error" *ngIf="hasError('required', feedbackFormFields.SUBJECT)">Required</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <textarea matInput maxTextareaAutosize placeholder="Message" autocomplete="off" [formControlName]="feedbackFormFields.MESSAGE"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="feedback-container-actions">
    <button mat-button color="primary" (click)="cancelClicked()" tabindex="-1">Cancel</button>
    <button mat-button color="primary" (click)="sendClicked()" tabindex="-1" [disabled]="!userFeedbackFormGroup.valid || sendingEmail">Send</button>
  </mat-dialog-actions>
</div>
`,
  styles: [`.feedback-container{width:400px}.feedback-container-actions{display:flex;flex-direction:row;justify-content:flex-end;margin-right:-20px}`]
})
export class FeedbackComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) config: FeedbackConfig,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FeedbackComponent>,
    private notificationService: NotificationService,
    private emailService: EmailService) {
    this.feedbackConfig = this.mapConfig(config);
  }

  public feedbackFormFields = FeedbackFormFields;
  public userFeedbackFormGroup: FormGroup;
  public sendingEmail = false;

  private feedbackConfig: FeedbackConfig;

  ngOnInit() {
    this.initializeForm();
  }

  public hasError(error: string, field: FeedbackFormFields): boolean {
    return this.userFeedbackFormGroup.get(field).hasError(error) && !this.userFeedbackFormGroup.get(field).untouched;
  }

  public cancelClicked() {
    this.dialogRef.close(false);
  }

  public sendClicked() {
    this.sendingEmail = true;

    const formValue = this.userFeedbackFormGroup.getRawValue();
    this.emailService.sendEmail(
      formValue[FeedbackFormFields.USER],
      this.feedbackConfig.fromEmail,
      this.feedbackConfig.toEmail,
      formValue[FeedbackFormFields.SUBJECT],
      `${formValue[FeedbackFormFields.MESSAGE]} - ${formValue[FeedbackFormFields.USER]}`
    ).pipe(
      take(1),
      catchError(error => {
        this.notificationService.showSnackBarMessage(`${ErrorMessageParser.parseMessage(error) || error}.`, { durationInMillis: 5000, status: 'error' });
        return of(undefined);
      }),
      finalize(() => this.sendingEmail = false)
    ).subscribe(results => {
      if (results) {
        this.dialogRef.close(true);
      }
    });
  }

  private mapConfig(config: FeedbackConfig): FeedbackConfig {
    return {
      disableFromNameInput: config.disableFromNameInput || false,
      disableSubjectInput: config.disableSubjectInput || false,
      fromEmail: config.fromEmail || '',
      fromName: config.fromName || config.fromEmail || '',
      invalidCharacterPattern: config.invalidCharacterPattern || '',
      message: config.message || '',
      subject: config.subject || '',
      title: config.title || 'Feedback',
      toEmail: config.toEmail || ''
    };
  }

  private initializeForm() {
    this.userFeedbackFormGroup = this.formBuilder.group({
      [FeedbackFormFields.USER]: new FormControl(
        {
          value: this.feedbackConfig.fromName,
          disabled: this.feedbackConfig.disableFromNameInput
        },
        [Validators.required]
      ),
      [FeedbackFormFields.SUBJECT]: new FormControl(
        {
          value: this.feedbackConfig.subject,
          disabled: this.feedbackConfig.disableSubjectInput
        },
        [Validators.required, Validators.pattern(this.feedbackConfig.invalidCharacterPattern)]
      ),
      [FeedbackFormFields.MESSAGE]: new FormControl(
        {
          value: this.feedbackConfig.message,
          disabled: false
        },
        [Validators.required, Validators.pattern(this.feedbackConfig.invalidCharacterPattern)]
      )
    });
  }
}

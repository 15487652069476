import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {

  // Lookbehind regex is still unsupported in most browsers
  // private sentenceCaseRegex = new RegExp('(?<=\\.|!|\\?)[\\s]+', 'gm');

  // using lookahead instead (with reversed string)
  private sentenceCaseRegex = new RegExp('[\\s]+(?=\\.|!|\\?)', 'gm');

  transform(value: string, args?: any): any {
    return value ? this.toSentenceCase(value) : '';
  }

  private toSentenceCase(value: string) {
    return value
      .toLowerCase()
      .split('').reverse().join('')
      .split(this.sentenceCaseRegex)
      .map(str => _.upperFirst(str.split('').reverse().join('')))
      .reverse().join(' ');
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { detect } from 'detect-browser';

import { MatDialog } from '@angular/material';
import { Observable, of } from 'rxjs';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import * as _ from 'lodash';
import { BrowserGuardDialogComponent } from './browser-guard-dialog.component';

@Injectable()
export class BrowserGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private dialog: MatDialog, private config: ConfigManagerService) { }

  canActivate(
      ): boolean | Observable<boolean> | Promise<boolean> {
    const browserInfo = detect();

    const setting = this.config.getSetting('compatibleBrowsers');
    const compatibleBrowsers = setting ?
      _.split((setting as string || '').toUpperCase(), ',') : undefined;

    const appRedirectUrl = this.config.getSetting('appRedirectUrl');
    const url = appRedirectUrl  ? appRedirectUrl  : '';

    if (
      browserInfo.name
      && compatibleBrowsers
      && compatibleBrowsers.length > 0
      && _.indexOf(compatibleBrowsers, browserInfo.name.toUpperCase()) < 0
    ) {
      this.dialog.open(
        BrowserGuardDialogComponent,
        { data: { appName: this.config.getSetting('appName'), compatibleBrowsers: compatibleBrowsers, appRedirectUrl: url }, disableClose: true }
      );

      return of(false);
    }

    return of(true);
  }
}

import { Directive, HostListener, Input } from '@angular/core';
import { EventKeyCode } from '../enums/event-keycode.enum';

@Directive({
  selector: '[xpoAllowCharacters]'
})
export class XpoAllowCharactersDirective {
  // Key values taken from: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
  private navigationKeys = [
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'End',
    'Home',
    'PageDown',
    'PageUp',
    'Up',
    'Down',
    'Right',
    'Left'
  ];
  private editingKeys = [
    'Backspace',
    'Clear',
    'Copy',
    'CrSel',
    'Cut',
    'Delete',
    'EraseEof',
    'ExSel',
    'Insert',
    'Paste',
    'Redo',
    'Undo',
    'Del'
  ];
  private whiteSpaceKeys = ['Enter', 'Tab'];
  private modifierKeys = [
    'Alt',
    'AltGraph',
    'CapsLock',
    'Control',
    'Fn',
    'FnLock',
    'Hyper',
    'Meta',
    'ScrollLock',
    'Shift',
    'Super',
    'Symbol',
    'SymbolLock',
    'OS',
    'Scroll'
  ];
  private functionKeys = [
    'F1',
    'F2',
    'F3',
    'F4',
    'F5',
    'F6',
    'F7',
    'F8',
    'F9',
    'F10',
    'F11',
    'F12'
  ];
  constructor() {}

  @Input('xpoAllowCharacters') xpoAllowCharacters: string | RegExp;

  @HostListener('keydown', ['$event'])
  handleClickEvent(event) {
    const keyCode = event.which || event.charCode || event.keyCode;

    if (event.key && this.isSpecialKey(event)) {
      return true;
    }

    if (keyCode === EventKeyCode.V && !!event.ctrlKey) {
      return true;
    }

    return this.xpoAllowCharacters instanceof RegExp
      ? this.xpoAllowCharacters.test(event.key)
      : this.xpoAllowCharacters.indexOf(event.key) >= 0;
  }

  @HostListener('paste', ['$event'])
  handlePasteEvent(event) {
    const data = event.clipboardData.getData('text');
    let shouldPaste = true;

    if (this.xpoAllowCharacters instanceof RegExp) {
      data.split('').forEach(char => {
        if (shouldPaste && !(this.xpoAllowCharacters as RegExp).test(char)) {
          shouldPaste = false;
        }
      });
    } else {
      this.xpoAllowCharacters.split('').forEach(char => {
        if (shouldPaste && data.indexOf(char) >= 0) {
          shouldPaste = false;
        }
      });
    }

    if (!shouldPaste) {
      event.preventDefault();
    }

    return shouldPaste;
  }

  private isSpecialKey(keyboardEvent: KeyboardEvent) {
    return (
      this.navigationKeys.some(
        item => item.toUpperCase() === keyboardEvent.key.toUpperCase()
      ) ||
      this.editingKeys.some(
        item => item.toUpperCase() === keyboardEvent.key.toUpperCase()
      ) ||
      this.whiteSpaceKeys.some(
        item => item.toUpperCase() === keyboardEvent.key.toUpperCase()
      ) ||
      this.modifierKeys.some(
        item => item.toUpperCase() === keyboardEvent.key.toUpperCase()
      ) ||
      this.functionKeys.some(
        item => item.toUpperCase() === keyboardEvent.key.toUpperCase()
      )
    );
  }
}

import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function xpoWholeNumberValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {

    if (!control.value) {
      return null;
    }

    if (!isNaN(control.value) && (Number.parseFloat(control.value) === Number.parseInt(control.value))
      && (Number.parseInt(control.value) >= 0)) {
      return null;
    } else {
      return {'wholeNumberValidator': {value: control.value}};
    }
  };
}

@Directive({
  selector: '[xpoWholeNumberValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: XpoWholeNumberValidatorDirective, multi: true}]
})
export class XpoWholeNumberValidatorDirective implements Validator {
  validate(control: AbstractControl): {[key: string]: any} {
    return xpoWholeNumberValidator()(control);
  }
}


export enum OptimizerParameterCd {
	EQPCPH = 'EQPCPH',
	EQPCPHAFTERSOFTMAX = 'EQPCPHAFTERSOFTMAX',
	EQPCPKM = 'EQPCPKM',
	EQPFXCOST = 'EQPFXCOST',
	EQPMAXSOFTDURINSS = 'EQPMAXSOFTDURINSS',
	EQPRTDURINSS = 'EQPRTDURINSS',
	EQPTRVLDURMULTIPLE = 'EQPTRVLDURMULTIPLE',
	OPZGBLENDTM = 'OPZGBLENDTM',
	OPZGBLSTARTTM = 'OPZGBLSTARTTM',
	OPZGRPDELAYDUR_0 = 'OPZGRPDELAYDUR0',
	OPZGRPDELAYDUR_1 = 'OPZGRPDELAYDUR1',
	OPZMAXSEARCHLMT = 'OPZMAXSEARCHLMT',
	OPZPRIMRY = 'OPZPRIMRY',
	OPZTMOUT = 'OPZTMOUT',
	SHMCPH_AFTER_STARTTM = 'SHMCPH_AFTER_STARTTM',
	SHMCPH_B_4_STARTTM = 'SHMCPH_B4_STARTTM',
	SHMPRPTM = 'SHMPRPTM'}


import {EquipmentSizeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EquipmentSizeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EquipmentSizeCd ) {
      this._values.push(EquipmentSizeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EquipmentSizeCd {

    for ( const obj in EquipmentSizeCd ) {
      if (EquipmentSizeCd[obj] === value){
        return EquipmentSizeCd[obj] as EquipmentSizeCd;
      }
    }
  }
}

const EquipmentSizeCdHelper = new EquipmentSizeCdHelperEnumHelperClass();
export default EquipmentSizeCdHelper;

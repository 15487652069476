import {HandlingUnitTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class HandlingUnitTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HandlingUnitTypeCd ) {
      this._values.push(HandlingUnitTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HandlingUnitTypeCd {

    for ( const obj in HandlingUnitTypeCd ) {
      if (HandlingUnitTypeCd[obj] === value){
        return HandlingUnitTypeCd[obj] as HandlingUnitTypeCd;
      }
    }
  }
}

const HandlingUnitTypeCdHelper = new HandlingUnitTypeCdHelperEnumHelperClass();
export default HandlingUnitTypeCdHelper;

import {DoorPlanActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class DoorPlanActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DoorPlanActionCd ) {
      this._values.push(DoorPlanActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DoorPlanActionCd {

    for ( const obj in DoorPlanActionCd ) {
      if (DoorPlanActionCd[obj] === value){
        return DoorPlanActionCd[obj] as DoorPlanActionCd;
      }
    }
  }
}

const DoorPlanActionCdHelper = new DoorPlanActionCdHelperEnumHelperClass();
export default DoorPlanActionCdHelper;

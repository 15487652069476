import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negativeCurrencyParens'
})
export class NegativeCurrencyParensPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || Number.parseInt(value) === Number.NaN || value === '-') {
      return value || '';
    }
    const valueAsString = `${value}`;
    return valueAsString.charAt(0) === '-' ? `(${valueAsString.substring(1, valueAsString.length)})` : valueAsString;
  }
}

import {CustomerDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerDetailCd ) {
      this._values.push(CustomerDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerDetailCd {

    for ( const obj in CustomerDetailCd ) {
      if (CustomerDetailCd[obj] === value){
        return CustomerDetailCd[obj] as CustomerDetailCd;
      }
    }
  }
}

const CustomerDetailCdHelper = new CustomerDetailCdHelperEnumHelperClass();
export default CustomerDetailCdHelper;

import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function xpoTimeFormatValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    if (control.value) {
      return /^([01]\d|2[0-3]):?([0-5]\d)$/.test(control.value) ? null : { 'xpoTimeFormat': control.value };
    }

    return null;
  };
}

@Directive({
  selector: '[xpoTimeFormatValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: XpoTimeFormatValidatorDirective, multi: true}]
})
export class XpoTimeFormatValidatorDirective implements Validator {
  validate(control: AbstractControl): {[key: string]: any} {
    return xpoTimeFormatValidator()(control);
  }
}

import {ModuleExecutorDataExtractTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ModuleExecutorDataExtractTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ModuleExecutorDataExtractTypeCd ) {
      this._values.push(ModuleExecutorDataExtractTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ModuleExecutorDataExtractTypeCd {

    for ( const obj in ModuleExecutorDataExtractTypeCd ) {
      if (ModuleExecutorDataExtractTypeCd[obj] === value){
        return ModuleExecutorDataExtractTypeCd[obj] as ModuleExecutorDataExtractTypeCd;
      }
    }
  }
}

const ModuleExecutorDataExtractTypeCdHelper = new ModuleExecutorDataExtractTypeCdHelperEnumHelperClass();
export default ModuleExecutorDataExtractTypeCdHelper;

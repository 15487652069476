import { Component } from '@angular/core';

@Component({
  selector: 'xpo-warnings',
  template: `<div class="warnings-component">
    <ng-content></ng-content>
</div>
`,
  styles: [`.warnings-component{position:absolute;top:3em;box-sizing:border-box;width:100%;overflow:hidden;color:#ff6d00;font-size:75%}`],
})
export class XpoWarningsComponent {
  constructor() {}
}

import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[xpoMaxNumberLength]',
})
export class XpoMaxNumberLengthDirective {
  @Input() xpoMaxLength: number;
  @Input() xpoIgnoreDecimal: boolean;

  constructor(public elementRef: ElementRef) {}

  @HostListener('paste', ['$event']) onPaste($event) {
    const data = this.xpoIgnoreDecimal ? $event.clipboardData.getData('text').replace('.', '') : $event.clipboardData.getData('text');
    if (data && data.length > this.xpoMaxLength || (data.length + this.elementRef.nativeElement.value.length) > this.xpoMaxLength) {
      return false;
    }
  }

  @HostListener('keypress', ['$event']) onKeyPress($event) {
    const data = this.xpoIgnoreDecimal ? $event.target.value.replace('.', '') : $event.target.value;
    if (data && data.length >= this.xpoMaxLength) {
      return false;
    }
  }
}


import { Component, Input } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {ConfigManagerProperties} from '../../enums/config-manager-properties.enum';

@Component({
  selector: 'xpo-utils-region-banner',
  template: `<div *ngIf="!isProd" class="region-banner" fxLayout="column" fxLayoutAlign="center start">
  <div class="region-banner-content" fxLayout="row" fxLayoutAlign="center center">
    <span class="region-banner__non-prod">Non-Prod</span>
    <span *ngIf="showRegion" class="region-banner__region">{{ region }}</span>
  </div>
</div>
`,
  styles: [`.region-banner{display:flex;width:100%;background-color:#d50000;align-self:center;height:20px}.region-banner-content{align-self:center}.region-banner__non-prod{margin:auto 8px auto auto;font-weight:400;font-size:12px;color:#fff;text-transform:uppercase}.region-banner__region{margin:auto;font-weight:400;font-size:12px;color:#fff;text-transform:uppercase}`]
})
export class RegionBannerComponent {

  constructor(private configManagerService: ConfigManagerService) { }
  @Input() showRegion: boolean;

  public get isProd(): boolean {
    return this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }
  public get region(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
  }
}


export enum ExceptionStatusCd {
	ACKNOWLEDGED = 'Acknowledged',
	APPROVED = 'Approved',
	CLOSED = 'Closed',
	COMPLETED = 'Completed',
	DECLINED = 'Declined',
	EXPIRED = 'Expired',
	NEW = 'New',
	PENDING = 'Pending',
	RESOLVED = 'Resolved',
	RESPONDED = 'Responded'}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../../material.module';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { BrowserGuardDialogComponent } from './browser-guard-dialog.component';
import { BrowserGuard } from './browser-guard.service';

@NgModule({
  declarations: [BrowserGuardDialogComponent],
  imports: [CommonModule, MaterialModule],
  providers: [
    BrowserGuard,
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
  entryComponents: [BrowserGuardDialogComponent],
})
export class XpoBrowserGuardModule {}

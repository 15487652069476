import {CustOperationHoursTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustOperationHoursTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustOperationHoursTypeCd ) {
      this._values.push(CustOperationHoursTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustOperationHoursTypeCd {

    for ( const obj in CustOperationHoursTypeCd ) {
      if (CustOperationHoursTypeCd[obj] === value){
        return CustOperationHoursTypeCd[obj] as CustOperationHoursTypeCd;
      }
    }
  }
}

const CustOperationHoursTypeCdHelper = new CustOperationHoursTypeCdHelperEnumHelperClass();
export default CustOperationHoursTypeCdHelper;

import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	CreateExceptionRqst,
	CreateExceptionResp,
	CreateExceptionPath,
	DeleteExceptionResp,
	DeleteExceptionPath,
	ListExceptionsResp,
	ListExceptionsQuery,
	ListLinehaulExceptionsResp,
	ListLinehaulExceptionsQuery,
	ListSummarizedExceptionsByLocationResp,
	ListSummarizedExceptionsByLocationQuery,
	ListSummarizedExceptionsByStatusResp,
	ListSummarizedExceptionsByStatusQuery,
	ListSummarizedExceptionsByTypeResp,
	ListSummarizedExceptionsByTypeQuery,
	SearchExceptionsRqst,
	SearchExceptionsResp,
	UpdateExceptionRqst,
	UpdateExceptionPath,
	CreateExpectationsRqst,
	CreateExpectationsResp,
	CreateExpectationsPath,
	GetExpectationByInstIdResp,
	GetExpectationByInstIdPath,
	ListExpectationsResp,
	ListExpectationsQuery,
	ListExpectationsPath,
	UpdateExpectationStatusRqst,
	UpdateExpectationStatusPath,
	CreateExpectationTypeRqst,
	CreateExpectationTypeResp,
	CreateExpectationTypePath,
	DeleteExpectationTypeResp,
	DeleteExpectationTypePath,
	ListExpectationTypesResp,
	UpdateExpectationTypeRqst,
	UpdateExpectationTypeResp,
	UpdateExpectationTypePath,
	DeleteExpectationTypeRoleQuery,
	DeleteExpectationTypeRolePath,
	UpsertExpectationTypeRoleRqst,
	UpsertExpectationTypeRoleResp,
	AddItemToQueueRqst,
	AddItemToQueueResp,
	ApproveItemRqst,
	ApproveItemResp,
	AssignItemRqst,
	AssignItemResp,
	CancelItemRqst,
	CancelItemResp,
	DeclineItemRqst,
	DeclineItemResp,
	GetItemResp,
	GetItemPath,
	ListItemsResp,
	ListItemsQuery,
	ListItemsByApplicationIdResp,
	ListItemsByApplicationIdQuery,
	ListItemsByQueueNameResp,
	ListItemsByQueueNameQuery,
	ListItemsByQueueNamePath,
	ListPrioritiesResp,
	ListQueueRoleAssignmentsResp,
	ListQueueRoleAssignmentsQuery,
	ListStatusLogsForItemResp,
	ListStatusLogsForItemQuery,
	ListWorkflowQueuesResp,
	UnassignItemRqst,
	UnassignItemResp,
	UpdateItemStatusRqst,
	UpdateItemStatusResp,
	UpsertPriorityRqst,
	UpsertQueueRqst,
	UpsertQueueRoleAssignmentRqst,
	DeleteRulePath,
	ExecuteRuleRqst,
	ExecuteRuleResp,
	GetRuleResp,
	GetRulePath,
	GetRuleByNameResp,
	GetRuleByNamePath,
	ListRulesResp,
	ListRulesQuery,
	ListRulesByExpressionsRqst,
	ListRulesByExpressionsResp,
	ListRulesByRulesetNameResp,
	ListRulesByRulesetNameQuery,
	ListRulesForExecutionResp,
	ListRulesForExecutionQuery,
	UpsertRuleRequest,
	UpsertRuleResp,
	ExportExpectationUnfilteredRqst,
	SearchExpectationUnfilteredRqst,
	SearchExpectationUnfilteredResp,
	ChangesFeedExpectationUnfilteredRqst,
	RegisterFilterExpectationUnfilteredRqst,
	RegisterFilterExpectationUnfilteredResp,
	UnregisterFilterExpectationUnfilteredRqst,
	BulkUpdateElasticDocumentExpectationResp,
	DeleteElasticDocumentExpectationResp,
	DeleteElasticDocumentExpectationPath,
	GetElasticDocumentExpectationResp,
	GetElasticDocumentExpectationQuery,
	GetElasticDocumentExpectationPath,
	GetElasticDocumentExpectationCountResp,
	GetElasticDocumentExpectationCountQuery,
	UpdateElasticDocumentExpectationRqst,
	UpdateElasticDocumentExpectationResp,
	UpdateElasticDocumentExpectationPath
} from './api-exceptionmanagement';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class ExceptionManagementApiService extends BaseService {
	private static ExceptionManagementApiEndpoint = 'exceptionmanagementApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates a freight exception.
	*/
	public createException(request: CreateExceptionRqst,
							   pathParams: CreateExceptionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateExceptionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations/{expectationInstId}/{expectationDetailSequenceNbr}/exceptions'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation deletes an Exception based on the exceptionInstId provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid exceptionInstId must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, the XCP_EXCEPTION and its children tables' records for the exceptionInstId are deleted.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public deleteException(
							   pathParams: DeleteExceptionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteExceptionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/exceptions/{exceptionInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation lists exceptions based on the filters selected.
	* <br/>
	* <br/>Pre-condition:
	* <br/>None
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If successful, a list of exceptions are returned based on the filter criteria selected.
	* <br/>2. If none are found, then a not found message is returned.
	* <br/>3. Otherwise an appropriate error message is returned.
	*/
	public listExceptions(
							   queryParams: ListExceptionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExceptionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/exceptions'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists exception and operation exception information based on the exception instance ID provided
	* <br/>OR retrieves a list of exceptions and their operation exception based on the planDate, ExceptionTypeCd, SIC, and ExceptionStatusCd.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. A valid exceptionInstId must be provided OR a valid plan date, alert type, SIC, and status.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the exception with its operation exception or an array of exceptions with their operation exception record that matches the input criteria is returned.
	* <br/>2. Otherwise an appropriate error message is returned.  
	*/
	public listLinehaulExceptions(
							   queryParams: ListLinehaulExceptionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLinehaulExceptionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/linehaul-exceptions'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists summarized exceptions by location including: count, type, and oldest timestamp for Exceptions or Alerts sorted by date, group, type, status, exceptionType, locationCd, and locationFilters grouped by Region, District or SIC.
	* <br/>			
	* <br/>Pre-conditions:
	* <br/>1. The following fields must be provided and valid: planDate, exceptionSummaryCd, exceptionSummaryTypeCd, exceptionStatusCd, and exceptionTypeCd.
	* <br/>2. The following fields are optional and must be valid if provided: locationTypeCd and locationFilters.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the parameters (date, group, type, status and ExceptionType and/or locationTypeCd-locationFilter) are valid, return the exception/alerts count data.
	* <br/>2. If locationTypeCd is not empty return the Exceptions/Alerts for the provided locationTypeCd.  Otherwise, return Exception/Alerts for all locationTypeCd.
	* <br/>3. If locationFilter is not empty return the Exceptions/Alerts for the provided locationFilter.  Otherwise, return Exceptions/Alerts for all locationFilters.
	* <br/>4. If there are no such data for the provided parameters, then an empty list is returned.
	* <br/>5. Otherwise an appropriate error message is returned.
	*/
	public listSummarizedExceptionsByLocation(
							   queryParams: ListSummarizedExceptionsByLocationQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSummarizedExceptionsByLocationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/exceptions/location/summaries'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists exception and alert summaries which include the count of Alerts/Exceptions and the age of the oldest Alert/Exception for each status for a given date and location (LH or Ops Region/District/SIC).
	* <br/>			
	* <br/>Pre-conditions:
	* <br/>1. A valid plan date must be provided.
	* <br/>2. The exceptionLocationTypeCd is optional. If it isn't empty, a list of valid location filters must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the dashboard view date is valid, then return the exception/alerts count data.
	* <br/>2. If both parameters are empty, retreive information for all Operational and Linehaul Regions.
	* <br/>3. If exceptionLocationTypeCd is not empty and locationFilters is not empty, retrieve filtered count data by the selected location.
	* <br/>4. If there are no such data, then an empty list is returned.
	* <br/>5. If the data is not valid and/or only one of the location parameters is provided, an appropriate error message is returned.
	*/
	public listSummarizedExceptionsByStatus(
							   queryParams: ListSummarizedExceptionsByStatusQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSummarizedExceptionsByStatusResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/exceptions/status/summaries'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists summarized exceptions by type.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The following fields must be provided and vaild: planDate, exceptionSummaryCd, exceptionSummaryTypeCd, and exceptionStatusCd.
	* <br/>5. If locationTypeCd is not empty, a list of valid locationFilters must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all parameters are valid and not empty, then a list of filtered operational regions or linehaul region by type is retruned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listSummarizedExceptionsByType(
							   queryParams: ListSummarizedExceptionsByTypeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSummarizedExceptionsByTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/exceptions/type/summaries'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Search Exceptions
	* <br/>        Pre-condition: Filter criteria is supplied
	* <br/>        Post-condition: List of exceptions that fit the supplied filter is returned
	* <br/>        
	*/
	public searchExceptions(request: SearchExceptionsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchExceptionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/searches/exceptions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation updates an exception.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. A valid exception must exist.
	* <br/>2. A valid exceptionInstId and operationName or exception must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the change to the exception is persisted to the database.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public updateException(request: UpdateExceptionRqst,
							   pathParams: UpdateExceptionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/exceptions/{exceptionInstId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Create expectations for an entity. E.g. SIC, Door, Trailer, Shipment etc.
	* <br/>        Pre-condition: A valid list of ExpectationHeader is passed in.
	* <br/>        	Required: typeCd
	* <br/>        		At least one of shiftCd, expectationSic, doorNbr, trailerId or proNbr
	* <br/>        		At least one expectationDetail
	* <br/>        Post-condition: The list of ExpectationHeaders is persisted to the database
	* <br/>        
	*/
	public createExpectations(request: CreateExpectationsRqst,
							   pathParams: CreateExpectationsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateExpectationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations/{typeCd}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation retrieves an expectation header based on the instance ID that is provided.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. A valid expectationInstId must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the expectation header that matches the input criteria is returned.
	* <br/>2. Otherwise an appropriate error message is returned.     
	*/
	public getExpectationByInstId(
							   pathParams: GetExpectationByInstIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetExpectationByInstIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations/{expectationInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists expectations based on filters that are selected.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Optional query paramters are selected.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If there are expectations that match the filters, then a list of them is returned.
	* <br/>2. Otherwise a not found message is returned.
	* <br/>
	*/
	public listExpectations(
							   pathParams: ListExpectationsPath,
							   queryParams: ListExpectationsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExpectationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations/{expectationOwnerTypeCd}/{expectationOwnerId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update expectation status
	* <br/>        Required: expectationInstId, expectationDetailSequenceNbr and the new status
	* <br/>        
	*/
	public updateExpectationStatus(request: UpdateExpectationStatusRqst,
							   pathParams: UpdateExpectationStatusPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations/{expectationInstId}/{expectationDetailSequenceNbr}/status'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Creates an expectation type.
	*/
	public createExpectationType(request: CreateExpectationTypeRqst,
							   pathParams: CreateExpectationTypePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateExpectationTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-types/{typeCd}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes an expectation type based on code and description.
	*/
	public deleteExpectationType(
							   pathParams: DeleteExpectationTypePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteExpectationTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-type/{typeCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Enter description
	*/
	public listExpectationTypes(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExpectationTypesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations-types'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Enter description
	*/
	public updateExpectationType(request: UpdateExpectationTypeRqst,
							   pathParams: UpdateExpectationTypePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateExpectationTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-type/{typeCd}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes an expectation type role based on expecation type role instance ID, employee role ID, and type code.
	*/
	public deleteExpectationTypeRole(
							   pathParams: DeleteExpectationTypeRolePath,
							   queryParams: DeleteExpectationTypeRoleQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-type-roles/{expectationTypeRoleInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Upserts an expectation type role.
	*/
	public upsertExpectationTypeRole(request: UpsertExpectationTypeRoleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertExpectationTypeRoleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-type-role'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Add item
	*/
	public addItemToQueue(request: AddItemToQueueRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<AddItemToQueueResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Approve item
	*/
	public approveItem(request: ApproveItemRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ApproveItemResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items/approve'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Assign an item.
	*/
	public assignItem(request: AssignItemRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<AssignItemResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Cancel an item.
	*/
	public cancelItem(request: CancelItemRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CancelItemResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items/cancel'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Decline an item.
	*/
	public declineItem(request: DeclineItemRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeclineItemResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items/decline'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Get Item
	*/
	public getItem(
							   pathParams: GetItemPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetItemResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items/{itemInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all Items that are available.
	*/
	public listItems(
							   queryParams: ListItemsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListItemsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all Items for an application inst id that are available.
	*/
	public listItemsByApplicationId(
							   queryParams: ListItemsByApplicationIdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListItemsByApplicationIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items/applications'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all Items for supplied Queue Name.
	*/
	public listItemsByQueueName(
							   pathParams: ListItemsByQueueNamePath,
							   queryParams: ListItemsByQueueNameQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListItemsByQueueNameResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items/queueName/{queueName}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* All Priorities that are available.
	*/
	public listPriorities(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPrioritiesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/priorities'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* All QueueRoleAssignments that are available.
	*/
	public listQueueRoleAssignments(
							   queryParams: ListQueueRoleAssignmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListQueueRoleAssignmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/queue-role-assignments'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all Items that are available.
	*/
	public listStatusLogsForItem(
							   queryParams: ListStatusLogsForItemQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListStatusLogsForItemResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items/status-logs'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all queues that are available.
	*/
	public listWorkflowQueues(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListWorkflowQueuesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/queues'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Unassign an item.
	*/
	public unassignItem(request: UnassignItemRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UnassignItemResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items/unassign'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Update item status
	*/
	public updateItemStatus(request: UpdateItemStatusRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateItemStatusResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/items/statuses'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Upsert a priority.
	*/
	public upsertPriority(request: UpsertPriorityRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/priorities'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Upsert a queue.
	*/
	public upsertQueue(request: UpsertQueueRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/queues'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Upsert a queueRoleAssignment.
	*/
	public upsertQueueRoleAssignment(request: UpsertQueueRoleAssignmentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xflow/queue-role-assignments'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Delete Rule
	*/
	public deleteRule(
							   pathParams: DeleteRulePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/{ruleInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Execute a rule
	*/
	public executeRule(request: ExecuteRuleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ExecuteRuleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/execute'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Get a Rule by identifier
	*/
	public getRule(
							   pathParams: GetRulePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetRuleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/{ruleInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* 
	* <br/>			Get full rule with rulesets, expressions and outcomes by the rule name.
	* <br/>			Pre-conditions:
	* <br/>				1. The current ruleName must be provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>				1. If input value is valid, then the rule will be returned. 
	* <br/>				2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public getRuleByName(
							   pathParams: GetRuleByNamePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetRuleByNameResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/name/{ruleName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all rules that are available.
	*/
	public listRules(
							   queryParams: ListRulesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all rules filtered by supplied expressions
	*/
	public listRulesByExpressions(request: ListRulesByExpressionsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRulesByExpressionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/rulesets'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Lists all rules that are available by ruleset name with inclusion and exclusion.
	*/
	public listRulesByRulesetName(
							   queryParams: ListRulesByRulesetNameQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRulesByRulesetNameResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/ruleset-names'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all rules ruturning minimum properties for rule execution
	*/
	public listRulesForExecution(
							   queryParams: ListRulesForExecutionQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRulesForExecutionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/executable-rules'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Upsert Rule
	*/
	public upsertRule(request: UpsertRuleRequest,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertRuleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Export documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All documents matching the filter will be exported as CSV or Excel format
	* <br/>		
	*/
	public exportExpectationUnfiltered(request: ExportExpectationUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-unfiltered/exports'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Documents matching the filter will be returned
	* <br/>		
	*/
	public searchExpectationUnfiltered(request: SearchExpectationUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchExpectationUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-unfiltered/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for expectation-unfiltered
	*/
	public changesFeedExpectationUnfiltered(request: ChangesFeedExpectationUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-unfiltered/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for ExpectationUnfiltered
	*/
	public registerFilterExpectationUnfiltered(request: RegisterFilterExpectationUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterExpectationUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-unfiltered/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for ExpectationUnfiltered
	*/
	public unregisterFilterExpectationUnfiltered(request: UnregisterFilterExpectationUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-unfiltered/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Elastic document for Expectation
	*/
	public deleteElasticDocumentExpectation(
							   pathParams: DeleteElasticDocumentExpectationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteElasticDocumentExpectationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/expectation-unfiltered/_doc/{expectationInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get Elastic document from Expectation index for given primary key
	*/
	public getElasticDocumentExpectation(
							   pathParams: GetElasticDocumentExpectationPath,
							   queryParams: GetElasticDocumentExpectationQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentExpectationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/expectation-unfiltered/_doc/{expectationInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get document count from Expectation index
	*/
	public getElasticDocumentExpectationCount(
							   queryParams: GetElasticDocumentExpectationCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentExpectationCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/expectation-unfiltered/_count'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update Elastic document for Expectation
	*/
	public updateElasticDocumentExpectation(request: UpdateElasticDocumentExpectationRqst,
							   pathParams: UpdateElasticDocumentExpectationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateElasticDocumentExpectationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/expectation-unfiltered/_doc/{expectationInstId}/_update'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(ExceptionManagementApiService.ExceptionManagementApiEndpoint);
	}
}

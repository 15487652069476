import { FormArray, ValidatorFn } from '@angular/forms';

export function xpoArrayCannotContainValidator(controlKey: string, value: string | string[], alias?: string): ValidatorFn {
  return (formArray: FormArray): {[key: string]: any} => {
    if (controlKey && value && formArray.value) {
      const errorKey = alias || 'arrayCannotContain';

      if (value instanceof Array) {
        const results: any[] =  formArray.value.filter(val => value.some((v) => {
          if (val[controlKey]) {
            return val[controlKey].toUpperCase() === v.toUpperCase();
          }
        }));

        return results && results.length ? {[errorKey]: results.map(v => v[controlKey])} : null;
      } else {
        // If array has one instance of a variable, return error
        return formArray.value.filter(val => {
          if (val[controlKey]) {
            return val[controlKey].toUpperCase() === value.toUpperCase();
          }
        }).length > 0 ? {[errorKey]: value} : null;
      }
    }

    // Is valid
    return null;
  };
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';

export interface BrowserGuardDialogComponentData {
  appName: string;
  compatibleBrowsers: string;
  appRedirectUrl: string;
}

@Component({
  selector: 'xpo-browser-guard-dialog',
  template: `<div class="browser-guard-dialog">
  <div class="browser-guard-dialog__header">Incompatible Browser Detected</div>
  <div class="browser-guard-dialog__content">
    <div>The browser you are using is not supported by {{ data.appName }}.</div>
    <div>Please copy the following URL and paste it in {{ data.compatibleBrowsers }}.</div>
    <div class="browser-guard-dialog__content-aligned">{{ appUrl }}<mat-icon (click)="saveClicked()">file_copy</mat-icon></div>
  </div>
</div>
`,
  styles: [`.browser-guard-dialog__header{color:#4a4a4a;font-size:18px;font-weight:500;margin:8px}.browser-guard-dialog__content{color:#4a4a4a;font-size:14px;margin:8px}.browser-guard-dialog__content-aligned{display:flex;align-items:center}.browser-guard-dialog__content-aligned .mat-icon{margin-left:4px}.mat-icon{cursor:pointer!important}`]
})
export class BrowserGuardDialogComponent {

  appUrl = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: BrowserGuardDialogComponentData, private clipboardService: ClipboardService) {
    this.appUrl = data.appRedirectUrl ? data.appRedirectUrl : window.location.href;
  }

  public getHref(browser: string): string {
    if (browser === 'CHROME') {
      return 'http://www.google.com/chrome';
    } else if (browser === 'IE' || browser === 'EDGE') {
      return 'https://www.microsoft.com/en-us/windows/microsoft-edge';
    } else {
      return 'https://www.google.com/search?q=browser';
    }
  }

  public saveClicked(): void {
    this.clipboardService.copyFromContent(this.appUrl);
  }
}

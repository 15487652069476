import { SentenceCasePipe } from './sentence-case.pipe';
import { EmptyValuePipe } from './empty-value.pipe';
import { CommonModule } from '@angular/common';
import { CamelCaseToHyphenPipe } from './camel-case-to-hyphen.pipe';
import { CurrencyCodePipe } from './currency-code.pipe';
import { EmptyCurrencyPipe } from './empty-currency.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { MillisToDaysPipe } from './millis-to-days.pipe';
import { NegativeCurrencyParensPipe } from './negative-currency-parens.pipe';
import { PastPresentFuturePipe } from './past-present-future.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { PluralPipe } from './plural.pipe';
import { RoundPipe } from './round.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TimeFormatterPipe } from './time-formatter.pipe';
import { NgModule } from '@angular/core';
import { ToRegexPipe } from './to-regex.pipe';
import { ProFormatterPipe } from './pro-formatter.pipe';

const pipeModules = [
  CamelCaseToHyphenPipe,
  CurrencyCodePipe,
  EmptyCurrencyPipe,
  EnumToArrayPipe,
  MillisToDaysPipe,
  NegativeCurrencyParensPipe,
  PastPresentFuturePipe,
  PhoneNumberPipe,
  PluralPipe,
  RoundPipe,
  SafeHtmlPipe,
  TimeFormatterPipe,
  ToRegexPipe,
  EmptyValuePipe,
  SentenceCasePipe,
  ProFormatterPipe
];

@NgModule({
  declarations: pipeModules,
  imports: [CommonModule],
  exports: pipeModules,
  providers: pipeModules
})
export class PipeModule { }

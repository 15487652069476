import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'emptyCurrency'
})
export class EmptyCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any, args?: any): any {

    const placeholder = args ? args : '-';

    if (value === placeholder || !value) {
      return placeholder;
    }
    if (Number.isNaN(Number.parseFloat(value))) {
      return value;
    }
    const convertedValue = Number.parseFloat(value);
    if (convertedValue === Number.NaN || convertedValue === 0) {
      return placeholder;
    }
    return super.transform(`${convertedValue}`);
  }
}

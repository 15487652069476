import { FormArray, ValidatorFn } from '@angular/forms';

export function xpoArrayMustContainValidator(controlKey: string, requiredValueFactory: () => string | string[], alias?: string): ValidatorFn {
  return (formArray: FormArray): {[key: string]: any} => {
    if (controlKey && formArray.value) {
      const errorKey = alias || 'arrayMustContain';
      const formArrayValue: any[] = formArray.value;
      const requiredValue = requiredValueFactory();

      if (requiredValue instanceof Array) {
        const results: any[] = requiredValue.filter(required => !formArrayValue.some(v => v[controlKey] === required));

        return results && results.length ? {[errorKey]: results} : null;
      } else {
        return formArrayValue.filter(val => {
          return  val[controlKey] ? val[controlKey].toUpperCase() === requiredValue.toUpperCase() : false;
        }).length <= 0 ? {[errorKey]: requiredValue} : null;
      }
    }

    // Is valid
    return null;
  };
}

import {
  Component,
  Input,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnDestroy,
  Renderer2,
  AfterViewInit
} from '@angular/core';
import { MatSelect } from '@angular/material';
import {
  coerceNumberProperty,
  coerceBooleanProperty
} from '@angular/cdk/coercion';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../classes';
import { PaginatorEventType } from '../../enums/paginator-event-type.enum';
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * Change event object that is emitted when the user selects a
 * different page size or navigates to another page.
 */
export class PageEvent {
  pageIndex: number;
  previousPageIndex?: number;
  pageSize: number;
  length: number;
  eventType: string;
}

export enum ScrollDirection {
  ASC = 'asc',
  DESC = 'desc'
}

@Component({
  selector: 'xpo-utils-paginator',
  template: `<div class="paginator-container">
  <div class="paginator-page-select" [ngClass]="{'paginator-dropdown__select-padding': this.currentPageIndex.toString().length >= 4}">
    <span>Page: </span>
    <mat-form-field class="paginator-page-dropdown">
      <mat-select #pageSelectDropdown class="paginator-page-dropdown-select" [ngStyle]="{'width': this.dropdownWidth}" [style.width.px]="this.dropdownWidth"  [value]="displayPageIndex" (selectionChange)="changePageSelect($event.value)">
        <mat-option [value]='page' *ngFor="let page of (pages$ | async)">{{ page }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="paginator-page-size">
    <div class="paginator-page-size-label">Items per page: </div>

    <mat-form-field class="paginator-page-dropdown">
      <mat-select class="paginator-page-dropdown-select" id="pageSizeDropdown" [value]="pageSize" (selectionChange)="changePageSize($event.value)">
        <mat-option *ngFor="let pageSizeOption of pageSizeOptions" [value]="pageSizeOption">
          {{pageSizeOption}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="paginator-range-actions">
    <div class="paginator-range-label">
      {{getRangeLabel()}}
    </div>

    <button mat-icon-button type="button" class="paginator-navigation-btn" id="paginatorFirstBtn" (click)="firstPage()" [disabled]="!hasPreviousPage()"
      *ngIf="showFirstLastButtons">
      <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
      </svg>
    </button>
    <button mat-icon-button type="button" class="paginator-navigation-btn" id="paginatorPrevBtn" (click)="previousPage()" [disabled]="!hasPreviousPage()">
      <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </svg>
    </button>
    <button mat-icon-button type="button" class="paginator-navigation-btn" id="paginatorNextBtn" (click)="nextPage()" [disabled]="!hasNextPage()">
      <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </button>
    <button mat-icon-button type="button" class="paginator-navigation-btn" id="paginatorLastBtn" (click)="lastPage()" [disabled]="!hasNextPage()"
      *ngIf="showFirstLastButtons">
      <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
      </svg>
    </button>
  </div>
</div>
`,
  styles: [`.paginator-container{display:flex;align-items:center;justify-content:flex-end;min-height:56px;flex-wrap:wrap-reverse;font-size:12px;color:rgba(0,0,0,.54);background:#fff}.paginator-container .mat-form-field-wrapper{margin-top:5px}.paginator-container .mat-form-field-underline{display:none}.paginator-page-size{display:flex;align-items:center}.paginator-page-size-label{margin-right:8px;margin-left:16px}.paginator-page-dropdown{width:33px}.paginator-page-dropdown-select{text-align:center}.paginator-range-label{margin:0 8px 0 16px}.paginator-range-actions{display:flex;align-items:center}.paginator-icon{width:28px;fill:currentColor;padding-bottom:4px}.paginator-dropdown__select-padding{padding-right:8px}`],
  encapsulation: ViewEncapsulation.None
})
export class PaginatorComponent implements AfterViewInit, OnDestroy {
  DEFAULT_PAGE_SIZE = 10;
  private pagesSubject = new BehaviorSubject<number[]>([]);
  public pages$ = this.pagesSubject.asObservable();

  public currentDataLength = 0;
  public currentPageIndex = 0;
  public currentPageSize = 10;
  public pageSizeOptions = [10, 15, 20];
  private _showFirstLastButtons = false;

  private dropdownRangeStart = 1;
  private dropdownRangeEnd = 1;
  private lastDropdownScrollTop = 0;

  private unsubscriber: Unsubscriber = new Unsubscriber();

  @ViewChild('pageSelectDropdown')
  pageSelectDropdown: MatSelect;

  @Input()
  get displayPageIndex(): number {
    return this.currentPageIndex + 1;
  }
  set displayPageIndex(displayIndex: number) {
    this.currentPageIndex = displayIndex - 1;
  }

  @Input()
  get pageIndex(): number {
    return this.currentPageIndex;
  }
  set pageIndex(value: number) {
    this.currentPageIndex = Math.max(coerceNumberProperty(value), 0);
  }

  @Input()
  get pageSize(): number {
    return this.currentPageSize;
  }
  set pageSize(value: number) {
    this.currentPageSize = Math.max(coerceNumberProperty(value), 0);
    this._updateDisplayedPageSizeOptions();
  }

  @Input()
  get length(): number {
    return this.currentDataLength;
  }
  set length(value: number) {
    this.currentDataLength = coerceNumberProperty(value);
    this.buildPageNumberList();
  }

  @Input()
  get showFirstLastButtons(): boolean {
    return this._showFirstLastButtons;
  }
  set showFirstLastButtons(value: boolean) {
    this._showFirstLastButtons = coerceBooleanProperty(value);
  }

  @Output()
  readonly page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  get totalPageCount(): number {
    return Math.ceil(this.length / this.pageSize);
  }

  get dropdownWidth(): string {
    return `${(this.currentPageIndex.toString().length * 8) + 24}px`;
  }

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.pageSelectDropdown.openedChange.pipe(takeUntil(this.unsubscriber.done)).subscribe((open) => {
      if (open) {
        const panel = this.pageSelectDropdown.panel.nativeElement;
        this.renderer.listen(panel, 'scroll', (event) => {
          this.loadOnScroll(event);
        });
      }
    });
  }

  /** Advances to the next page if it exists. */
  nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex++;
    this._emitPageEvent(previousPageIndex, PaginatorEventType.NextPage);
  }

  /** Move back to the previous page if it exists. */
  previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex--;
    this._emitPageEvent(previousPageIndex, PaginatorEventType.PrevPage);
  }

  /** Move to the first page if not already there. */
  firstPage(): void {
    // hasPreviousPage being false implies at the start
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = 0;
    this._emitPageEvent(previousPageIndex, PaginatorEventType.FirstPage);
  }

  /** Move to the last page if not already there. */
  lastPage(): void {
    // hasNextPage being false implies at the end
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.getNumberOfPages();
    this._emitPageEvent(previousPageIndex, PaginatorEventType.LastPage);
  }

  /** Whether there is a previous page. */
  hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  /** Whether there is a next page. */
  hasNextPage(): boolean {
    const numberOfPages = this.getNumberOfPages();
    return this.pageIndex < numberOfPages && this.pageSize !== 0;
  }

  /** Calculate the number of pages */
  getNumberOfPages(): number {
    return Math.ceil(this.length / this.pageSize) - 1;
  }

  /**
   * Changes the page size so that the first item displayed on the page will still be
   * displayed using the new page size.
   *
   * For example, if the page size is 10 and on the second page (items indexed 10-19) then
   * switching so that the page size is 5 will set the third page as the current page so
   * that the 10th item will still be displayed.
   */
  changePageSize(pageSize: number) {
    if (pageSize === this.pageSize) {
      return;
    }
    // Current page needs to be updated to reflect the new page size. Navigate to the page
    // containing the previous page's first item.
    const startIndex = this.pageIndex * this.pageSize;
    const previousPageIndex = this.pageIndex;

    this.pageIndex = Math.floor(startIndex / pageSize) || 0;
    this.pageSize = pageSize;
    this.buildPageNumberList();
    this._emitPageEvent(previousPageIndex, PaginatorEventType.UpdatePgSize);
  }

  changePageSelect(displayIndex: number) {
    if (displayIndex === this.displayPageIndex) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = displayIndex - 1;
    this._emitPageEvent(previousPageIndex, PaginatorEventType.UpdatePgDropdown);
  }

  getRangeLabel() {
    const start = this.returnStartAt() + 1;
    let end = this.returnStartAt() + this.pageSize;
    let range = '0';

    if (end > this.length) {
      end = this.length;
    }
    if (this.length) {
      range = `${start} - ${end}`;
    }

    return `${range} of ${this.length}`;
  }

  returnStartAt(): number {
    return this.pageIndex * this.pageSize;
  }

  returnNumberOfRows(startAt: number): number {
    let numOfRows = this.currentPageSize;

    if (
      this.currentDataLength > 0 &&
      startAt + numOfRows > this.currentDataLength
    ) {
      numOfRows = this.currentDataLength - startAt + 1;
    }

    return numOfRows;
  }

  loadOnScroll(event) {
    if (this.isAscending(event)) {
      this.loadAdditionalPages(ScrollDirection.ASC);
    } else if (this.isDescending(event)) {
      this.loadAdditionalPages(ScrollDirection.DESC);
    }

    this.lastDropdownScrollTop = event.target.scrollTop;
  }

  private isDescending(e): boolean {
    return e.target.scrollTop < this.lastDropdownScrollTop &&
           e.target.scrollTop < e.target.clientHeight &&
           this.dropdownRangeStart > 20;
  }

  private isAscending(e): boolean {
    return e.target.scrollTop > this.lastDropdownScrollTop &&
    this.getBottomScrollPosition(e) > (e.target.scrollHeight * .9);
  }

  private getBottomScrollPosition(e): number {
    return e.target.scrollTop + e.target.clientHeight;
  }

  private loadAdditionalPages(order): void {
    if (order === ScrollDirection.ASC) {
      this.pagesSubject.next(this.buildPageDropdownRange(ScrollDirection.ASC));
    } else if (order === ScrollDirection.DESC) {
      this.pagesSubject.next(this.buildPageDropdownRange(ScrollDirection.DESC));
    }
  }

  // Builds array of numbers from 1 to max page length for paginator dropdown menu.
  buildPageNumberList() {
    this.pagesSubject.next(this.buildPageDropdownRange());
  }

  buildPageDropdownRange(order?) {
    let start;
    let end;

    // If user closes out of the dropdown or selects an option, no order will be passed in. In this case
    // start and end values are reset to current display page index +/- 20.
    if (!order) {
      start = this.displayPageIndex - 20;
      end = this.displayPageIndex + 20;

        if (this.totalPageCount < end) {
          end = this.totalPageCount;
        }
    }

    // If scrolling in descending order, and reach the threshold, load 20 more items into array
    if (order === ScrollDirection.DESC) {
      start = this.dropdownRangeStart - 20;
      end = this.dropdownRangeEnd;
    }

    // If scrolling in asc order, and reach the threshold, load 20 more items into array
    if (order === ScrollDirection.ASC) {
      start = this.dropdownRangeStart;
      end = this.dropdownRangeEnd + 20;
    }

    // If start is within first 20 pages, set to 1
    if (start <= 20) {
      start = 1;
    }

    // If end is greater than total page count, set end to total page count
    if (this.dropdownRangeEnd >= this.totalPageCount) {
      end = this.totalPageCount;
    }

    // Set end range to +1 so that we can properly build out the range.
    end++;

    // Reset dropdown range start and end to new start and end values, or default to original value if none was set
    this.dropdownRangeStart = start || this.dropdownRangeStart;
    this.dropdownRangeEnd = end || this.dropdownRangeEnd;

    // Return range
    return _.range(start, end);
  }

  private _updateDisplayedPageSizeOptions() {
    // If no page size is provided, use the first page size option or the default page size.
    if (!this.pageSize) {
      this.pageSize =
        this.pageSizeOptions.length !== 0
          ? this.pageSizeOptions[0]
          : this.DEFAULT_PAGE_SIZE;
    }

    this.pageSizeOptions = this.pageSizeOptions.slice();

    if (this.pageSizeOptions.indexOf(this.pageSize) === -1) {
      this.pageSizeOptions.push(this.pageSize);
    }

    // Sort the numbers using a number-specific sort function.
    this.pageSizeOptions.sort((a, b) => a - b);
  }

  /** Emits an event notifying that a change of the paginator's properties has been triggered. */
  private _emitPageEvent(previousPageIndex: number, eventType: string) {
    this.page.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
      eventType
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }
}

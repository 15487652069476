import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

const nineDigitProRegexp = /'^[0-9]{9}$/;
const tenDigitRegexpProRegexp = /[0-9]{3}[-][0-9]{6}$/;
const elevenDigitProRegexp = /^[0][0-9]{3}[0][0-9]{6}$/;

export function proFormatValidator(digits: 9|10|11|'*'): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    let valid: boolean;

    switch (digits) {
      case 9:
        valid = nineDigitProRegexp.test(control.value);
        break;
      case 10:
        valid = tenDigitRegexpProRegexp.test(control.value);
        break;
      case 11:
        valid =  elevenDigitProRegexp.test(control.value);  // 01230456782
        break;
      case '*':
        valid = nineDigitProRegexp.test(control.value);
        if (!valid){
          valid = tenDigitRegexpProRegexp.test(control.value);
          if (!valid){
            valid =  elevenDigitProRegexp.test(control.value);
          }
        }
        break;
    }

    return valid || !control.value ? null : {'proFormatValidator': {value: control.value}};
  };
}

@Directive({
  selector: '[xpoProFormatValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: XpoProFormatValidatorDirective, multi: true}]
})
export class XpoProFormatValidatorDirective implements Validator {
  @Input() digits: 9|10|11;

  validate(control: AbstractControl): {[key: string]: any} {
    return this.digits ? proFormatValidator(this.digits)(control) : null;
  }
}

import { AbstractControl, ValidatorFn } from '@angular/forms';

export function xpoStringFormatValidator(formats: string[]): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    // Check if input format is valid
    if (formats && formats.length && formats.length > 0) {
      const value: string = control.value;
      const returnValue = {'stringFormat' : { 'format': formats, 'value': value }};

      // If the control doest jhave a value, return an error
      if (!value) {
        return returnValue;
      }

      for (let formatIndex = 0; formatIndex < formats.length; formatIndex++) {
        const format = formats[formatIndex];
        let valid = true;

        // If the format length doesnt match the input length, return
        if (!format || format.length !== value.length) {
          continue;
        }

        for (let i = 0; i < format.length; i++) {
          if (format.charAt(i) === '#') { // if # check if character is numeric
            if (Number.isNaN(Number.parseInt(value.charAt(i)))) {
              valid = false;
            }
          } else if (format.charAt(i) === '@') { // if @ check its a letter
            if (!Number.isNaN(Number.parseInt(value.charAt(i)))) {
              valid = false;
            }
          } else if (format.charAt(i) ===  'N') { // if N it can be either a number or letter
          } else {
            if (format.charAt(i) !== value.charAt(i)) { // Has to be exact character of the format
              valid =  false;
            }
          }

          // if the format is invlaid, go to the next one
          if (!valid) {

          } else { // if the format is valid, return that the format is valid
            return null;
          }
        }
      }

      // at this point none of the formats are valid return error
      return returnValue;
    }

    // Is valid
    return null;
  };
}

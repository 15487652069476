import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormatter',
  pure: true
})
export class TimeFormatterPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if (value && !value.includes(':')) { // Check if there is a value and it doesnt include a ':'
      if (value.length === 4) { // Example: 1230 -> 12:30
        return value.slice(0, 2) + ':' + value.slice(2, 4);
      } else if (value.length === 2) { // Example: 12 -> 12:00
        return value + ':00';
      }
    }

    return value;
  }
}

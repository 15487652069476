import * as moment_ from 'moment-timezone';
const moment = moment_;

export class DateTimeHelper {

  public static SERVER_TIME_ZONE = 'America/Los_Angeles';
  /**
   * condition time to two digit time
   * @param value
   * @returns
   */
  public;

  static padTime(value: number): string {

    if ((value !== undefined) && (value !== null) && (value % 1 === 0) && (value < 60) && (value >= 0)) {
      const retValue: string = '0' + value;
      return retValue.slice(-2);
    }
    return undefined;
  }

  /**
   * Will respect DST if you add across a DST boundary
   * @param date
   * @param  days
   * @returns
   */
  public static addDaysToDate( date: Date, days: number ): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  /**
   * Date received is presumed to be in hosts timezone.  Need to replace the time
   * with intended time from server side timezone.
   * @param date
   * @returns
   */
  public static conditionTimeFromServer(date: Date): Date {

    if (!date) {
      return date;
    }

    const newDate = moment(date).tz(DateTimeHelper.SERVER_TIME_ZONE);
    return new Date(newDate.year(), newDate.month(), newDate.date(), newDate.hour(), newDate.minute(), newDate.second(), 0);
  }

  /**
   * Date received is presumed to be in server timezone.  Need to condition date to be UTC date,
   * with intended date from server side timezone.
   * @param date
   * @returns date
   */
  public static conditionDateFromServer(date: Date): Date {

    if (!date) {
      return date;
    }

    const newDate = moment(date).tz(DateTimeHelper.SERVER_TIME_ZONE);

    const aDate = new Date(newDate.toDate());
    aDate.setUTCMonth(newDate.month(), newDate.date());
    aDate.setUTCFullYear(newDate.year());
    aDate.setUTCHours(0, 0, 0, 0);
    return aDate;
  }

  /**
   * Date from input type date is UTC based. The UTC date is the date we want
   * in ( SERVER_TIME_ZONE ) in the payload.
   * @param date
   * @returns date
   */
  public static conditionDateToServer(date: Date): Date {

    if (!date) {
      return date;
    }

    const offset = moment(date).tz(DateTimeHelper.SERVER_TIME_ZONE).utcOffset();
    const aDate = new Date(date.getTime());
    aDate.setUTCHours(0, 0, 0, 0);
    return new Date(aDate.getTime() - (offset * 60000));
  }

  /**
   * Date from input type date is UTC based. The UTC date is the date we want
   * in PST/PSD ( SERVER_TIME_ZONE ) in the payload. For passing time please
   * set the desired time in the UTCHours, UTCMinutes, UTCSeconds.
   * @param date
   * @returns Date
   */
  public static conditionTimeToServer(date: Date): Date {

    if (!date) {
      return date;
    }

    const offset = moment(date).tz(DateTimeHelper.SERVER_TIME_ZONE).utcOffset();

    return new Date(new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()).getTime() + (offset * 60000));

    //return new Date(date.getTime() - (offset * 60000));
  }
}

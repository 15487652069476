import {LoadUnloadIdentifierCd} from '../';
import {EnumHelper} from './enum-helper';

export class LoadUnloadIdentifierCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LoadUnloadIdentifierCd ) {
      this._values.push(LoadUnloadIdentifierCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LoadUnloadIdentifierCd {

    for ( const obj in LoadUnloadIdentifierCd ) {
      if (LoadUnloadIdentifierCd[obj] === value){
        return LoadUnloadIdentifierCd[obj] as LoadUnloadIdentifierCd;
      }
    }
  }
}

const LoadUnloadIdentifierCdHelper = new LoadUnloadIdentifierCdHelperEnumHelperClass();
export default LoadUnloadIdentifierCdHelper;

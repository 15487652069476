import {CustomerProfileDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerProfileDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerProfileDetailCd ) {
      this._values.push(CustomerProfileDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerProfileDetailCd {

    for ( const obj in CustomerProfileDetailCd ) {
      if (CustomerProfileDetailCd[obj] === value){
        return CustomerProfileDetailCd[obj] as CustomerProfileDetailCd;
      }
    }
  }
}

const CustomerProfileDetailCdHelper = new CustomerProfileDetailCdHelperEnumHelperClass();
export default CustomerProfileDetailCdHelper;

import { PipeTransform, Pipe } from '@angular/core';
import * as moment_ from 'moment';
const moment = moment_;

@Pipe({
  name: 'pastPresentFuture'
})
export class PastPresentFuturePipe implements PipeTransform {

  transform(value: Date, pastLabel: string, presentLabel: string, futureLabel: string): any {
    const startOfDay = moment().startOf('day').toDate();
    const endOfDay = moment().endOf('day').toDate();

    if (value < startOfDay) {
        return pastLabel;
    } else if (value > endOfDay) {
        return futureLabel;
    } else {
        return presentLabel;
    }
  }
}

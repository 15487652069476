import { NgModule } from '@angular/core';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import {LinehaulOperationsApiService} from './linehauloperations-api.service';

@NgModule({
  imports: [
    ConfigManagerModule
  ],
  providers: [
    LinehaulOperationsApiService,
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json'}},
  ]
})
export class SdkLinehaulOperationsModule { }

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FeedbackConfig } from '../components/feedback/feedback-config';
import { FeedbackComponent } from '../components/feedback/feedback.component';

@Injectable({ providedIn: 'root' })
export class FeedbackService {

  constructor(private dialog: MatDialog) { }

  public sendFeedback(feedbackConfig: FeedbackConfig): Observable<boolean> {
    const subject = new Subject<boolean>();

    const dialogRef = this.dialog.open(FeedbackComponent, { data: feedbackConfig });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      subject.next(result);
      subject.complete();
    });

    return subject.asObservable();
  }
}

import { AfterContentInit, Component, ComponentFactoryResolver, Inject, Type, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogTemplateInjectorDirective } from '../dialog-template-injector.directive';
import { InjectedDialogComponent } from '../xpo-dialog-manager.service';
import { AlertDialogData } from '../../interfaces';

@Component({
  selector: 'app-alert-wrapper',
  template: `<h1 mat-dialog-title
    class="alert-wrapper__title">{{data.titleText || 'NO TITLE PROVIDED'}}
</h1>
<div mat-dialog-content
     class="alert-wrapper__content">
  <ng-template appDialogTemplateInjector>
  </ng-template>
  <div *ngIf="data.contentText">
    {{data.contentText}}
  </div>
</div>
<div class="alert-wrapper__footer"
     mat-dialog-actions
     fxLayout="row"
     fxLayoutAlign="end center">
  <button *ngIf="!data?.hideDismissButton"
          mat-button
          tabindex="-1"
          (click)="handleCloseClick()"
          color="primary">{{data.dismissalText || 'Close'}}</button>
</div>
`,
  styles: [`.alert-wrapper__title{font-weight:400;margin-bottom:16px}.alert-wrapper__content{color:#757575}.alert-wrapper__content .mat-input-element,.alert-wrapper__content .mat-select-value-text *{color:#212121;font-size:14px}.alert-wrapper__content a{color:#0091ea}.alert-wrapper__content .mat-icon.mat-error{width:48px;height:48px;font-size:48px;margin:8px}`],
  encapsulation: ViewEncapsulation.None
})
export class AlertWrapperComponent implements AfterContentInit {
  @ViewChild(DialogTemplateInjectorDirective) messageContent: DialogTemplateInjectorDirective;

  constructor(private dialogRef: MatDialogRef<AlertWrapperComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
              private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngAfterContentInit() {
    if (this.data.contentComponent) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.contentComponent);
      const componentRef = this.messageContent.viewContainerRef.createComponent(componentFactory);
      (<InjectedDialogComponent>componentRef.instance).dataObservable = this.data.injectedData;
    }
  }

  handleCloseClick(): void {
    this.dialogRef.close();
  }

}

import { FormArray, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

export function xpoUniqueInListValidator(akey: string): ValidatorFn {
  return (formArray: FormArray): {[key: string]: any} => {
    if (akey && formArray && formArray.value) {

      const theValueArray = [];
      const errorSet = new Set();
      for (let i = 0; i < formArray.length; i++) {
        const aValue = formArray.controls[i].get(akey).value;
        if (aValue) {
          if (_.some(theValueArray, aValue)) {
            errorSet.add(aValue);
          } else {
            theValueArray.push(aValue);
          }
        }
      }
      if (errorSet.size > 0 ) {
        return {'uniqueInList': Array.from(errorSet)};
      }
    }
    return null;
  };
}


import { Directive, DoCheck, forwardRef, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../classes';
import { toArray, WarningOptions, WarningsUtils } from './xpo-warning-helper';
import { XpoWarningsDirective } from './xpo-warnings.directive';

@Directive({
  selector: '[xpoWarning]',
})
export class XpoWarningDirective implements OnInit, OnDestroy, DoCheck {
  rules: string[] = [];
  warningNames: string[] = [];
  subscription: Subscription;
  _states: Subject<string[]>;
  states: Observable<string[]>;

  private unsubscriber: Unsubscriber;

  @Input()
  set xpoWarning(value: WarningOptions) {
    this.warningNames = toArray(value);
  }

  @Input()
  set when(value: WarningOptions) {
    this.rules = toArray(value);
  }

  @HostBinding('hidden')
  hidden = true;

  constructor(@Inject(forwardRef(() => XpoWarningsDirective)) private xpoWarnings: XpoWarningsDirective) { }

  ngOnInit() {
    this._states = new Subject<string[]>();
    this.unsubscriber = new Unsubscriber();

    this.states = this._states.asObservable().pipe(
      takeUntil(this.unsubscriber.done),
      distinctUntilChanged()
    );

    const warnings = this.xpoWarnings.subject.pipe(
      filter(Boolean),
      filter(obj => !this.warningNames.indexOf(obj.warningName))
    );

    const states = this.states.pipe(map(ctrlStates => this.rules.every(rule => !ctrlStates.indexOf(rule))));

    if (!!this.xpoWarnings.xpoWarningsParentControl) {
      this.xpoWarnings.xpoWarningsParentControl
        .statusChanges
        .pipe(takeUntil(this.unsubscriber.done))
        .subscribe(() => {
          this.xpoWarnings.checkStatus();
        });
    }

    this.subscription = combineLatest(states, warnings)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(([ctrlStates, ctrlWarnings]) => {

        if (!this.xpoWarnings.xpoWarningsParentControl) {
          this.hidden = !(ctrlStates && WarningsUtils.hasWarning(ctrlWarnings.control, ctrlWarnings.warningName)
            && !ctrlWarnings.control.errors);
        } else {
          this.hidden = !(ctrlStates && WarningsUtils.hasWarning(ctrlWarnings.control, ctrlWarnings.warningName)
            && !ctrlWarnings.control.errors
            && this.xpoWarnings.xpoWarningsParentControl.valid);
        }
      });
  }

  ngDoCheck() {
    this._states.next(this.rules.filter(rule => (this.xpoWarnings.control as any)[rule]));
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.complete();
      this.unsubscriber = undefined;
    }
  }
}

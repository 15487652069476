/**
 * LinehaulOperations API
 * Version: 1.0
 * Build: 1.0.0.86
 */

import {
	AuditInfo,
	SearchRecordMessageHeader,
	XrtAttributeFilter,
	XrtSortExpression,
	XrtSearchRespHeader,
	ActionCd,
	BillClassCd,
	BillStatusCd,
	ChargeToCd,
	ConsigneeUnloadedTrailerCd,
	DeliveryInfoRequiredCd,
	DeliveryQualifierCd,
	DeltaSignCd,
	DeltaVarianceTypeCd,
	EnsembleExecutionStatusCd,
	ExemptReasonCd,
	FoodPoisonCd,
	InvoiceCurrencyCd,
	LateTenderCd,
	MetricStageCd,
	MetricStageSubCd,
	MetricTypeCd,
	MetricValueKeyCd,
	MetricVarianceCd,
	MinuetExecutionStatusCd,
	ModelGroupCd,
	MoveModeCd,
	MovementExceptionTypeCd,
	MovementStatusCd,
	NotificationCd,
	PhotoTypeCd,
	RatingCurrencyCd,
	RouteTypeCd,
	RunShiftCd,
	ServiceCalculationStatusCd,
	ServiceStatusCd,
	ShiftCd,
	ShipmentAcquiredTypeCd,
	ShipmentManagementRemarkTypeCd,
	ShipmentRemarkTypeCd,
	ShipmentSourceCd,
	ShipmentVolumeMethodCd,
	ShipmentVolumeTypeCd,
	ShipperLoadedTrailerCd,
	TrailerStatusCd,
	WarrantyStatusCd
} from '@xpo-ltl/sdk-common';

/**
 * Path parameters used to calculate LHO event metrics.
 */
export class CalculateLhoEventMetricsPath {
	/**
	 * The unique identifier for the location selected.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The type of event associated with the metric that needs to be updated.
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * The unique identifier for a model optimizer run.
	 * Required: true
	 * Array: false
	 */
	runId:number = undefined;
}
/**
 * Response to the request to calculate LHO event metrics.
 */
export class CalculateLhoEventMetricsResp {
	/**
	 * The metric that match the input search criteria.
	 * Required: false
	 * Array: false
	 */
	summaryMetric:SummaryMetric = undefined;
}
/**
 * Path parameters used to calculate SCO event metrics.
 */
export class CalculateScoEventMetricsPath {
	/**
	 * The unique identifier for the location selected.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The current shift code for the SIC.
	 * Required: true
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The date that the SCO event took place.
	 * Required: true
	 * Array: false
	 */
	callDate:string = undefined;
}
/**
 * Response to the request to calculate SCO event metrics.
 */
export class CalculateScoEventMetricsResp {
	/**
	 * The metric that match the input search criteria.
	 * Required: false
	 * Array: false
	 */
	summaryMetric:SummaryMetric = undefined;
}
/**
 * Path parameter used to create planning schedules.
 */
export class CreatePlanningSchedulesPath {
	/**
	 * The unique identifier for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Request to create planning schedules.
 */
export class CreatePlanningSchedulesRqst {
	/**
	 * The default dispatch local date time.
	 * Required: true
	 * Array: false
	 */
	defaultDispatchLocalDateTime:Date = undefined;
	/**
	 * The flag that indicates if this is for HSS.
	 * Required: true
	 * Array: false
	 */
	hssInd:boolean = undefined;
	/**
	 * The list of schedules that will be created.
	 * Required: true
	 * Array: true
	 */
	plannedSchedules:PlannedSchedule[] = undefined;
}
/**
 * Response to the request to return changes for specific sic code and shift.
 */
export class CreatePlanningSchedulesResp {
	/**
	 * The list of schedule identifiers that were created.
	 * Required: true
	 * Array: true
	 */
	scheduleIds:number[] = undefined;
}
export class GetLaneRemainingLoadInfoPath {
	/**
	 * The unique identifier for the equipment.
	 * Required: true
	 * Array: false
	 */
	equipmentInstId:number = undefined;
}
export class GetLaneRemainingLoadInfoResp {
	/**
	 * The current SIC code.
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The load to SIC code.
	 * Required: false
	 * Array: false
	 */
	loadToSicCd:string = undefined;
	/**
	 * The sum of the remaining pups and vans for the lane.
	 * Required: false
	 * Array: false
	 */
	remainingLoads:number = undefined;
	/**
	 * The remaining total weight of planned cross dock shipments in lane.
	 * Required: false
	 * Array: false
	 */
	remainingTotalWeight:number = undefined;
	/**
	 * The remaining total cube of planned cross dock shipments in lane.
	 * Required: false
	 * Array: false
	 */
	remainingTotalCube:number = undefined;
}
/**
 * Path parameters used to return details for a linehaul bypass trailer.
 */
export class GetLinehaulBypassTrailerPath {
	/**
	 * The unique identifier for the equipment.
	 * Required: true
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * The service center for the given trailer.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to retrieve a linehaul bypass trailer.
 */
export class GetLinehaulBypassTrailerResp {
	/**
	 * The linehaul bypass trailer that was retrieved.
	 * Required: false
	 * Array: false
	 */
	lnhBypassTrailer:LnhBypassTrailer = undefined;
}
/**
 * The list of metrics values when a trailer is closed.
 */
export class GetLoadMetricsPath {
	/**
	 * The unique identifier for the sic code selected.
	 * Required: true
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The current shift code of the sic code.
	 * Required: true
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * Timestamp the CLOS event had place.
	 * Required: true
	 * Array: false
	 */
	planDate:string = undefined;
}
/**
 * Response to the request of the list of metrics values when a trailer is closed.
 */
export class GetLoadMetricsResp {
	/**
	 * The metric that match the input search criteria.
	 * Required: false
	 * Array: false
	 */
	summaryMetric:SummaryMetric = undefined;
}
/**
 * Path parameter used to return the Load Request associated with the lhoLoadRequestId provided.
 */
export class GetLoadRequestPath {
	/**
	 * The unique identifier for the Load Request.
	 * Required: true
	 * Array: false
	 */
	lhoLoadRequestId:number = undefined;
}
/**
 * Response to the request to return the Load Request associated with the lhoLoadRequestId  provided.
 */
export class GetLoadRequestResp {
	/**
	 * The Load Request associated with the load request ID.
	 * Required: false
	 * Array: false
	 */
	loadRequest:LoadRequest = undefined;
	/**
	 * time since a LR can be approved or declined
	 * Required: false
	 * Array: false
	 */
	expirationDateFrom:Date = undefined;
	/**
	 * time until a LR can be approved or declined
	 * Required: false
	 * Array: false
	 */
	expirationDateTo:Date = undefined;
}
/**
 * Path parameters used to return the Load Request Reason Code associated with the reasonTypeCd and reasonCd provided.
 */
export class GetLoadRequestReasonCodePath {
	/**
	 * Identifies one of three types of reason codes. Â Valid values include: REASON_CD, APPROVE_REASON_CD, and DECLINE_REASON_CD.
	 * Required: true
	 * Array: false
	 */
	reasonTypeCd:string = undefined;
	/**
	 * Each reasonTypeCd can have one or more actual reasonCds.
	 * Required: true
	 * Array: false
	 */
	reasonCd:string = undefined;
}
/**
 * Response to the request to return the Load Request Reason Code associated with the reasonTypeCd and reasonCd provided.
 */
export class GetLoadRequestReasonCodeResp {
	/**
	 * The Load Request Reason Code associated with the reasonTypeCd and reasonCd.
	 * Required: false
	 * Array: false
	 */
	loadRequestReasonCode:LoadRequestReasonCode = undefined;
}
/**
 * Origin sic and ShiftCd of trailer.
 */
export class GetMoveToSicPath {
	/**
	 * The origin Sic code.
	 * Required: true
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * The shift code of the trailer.
	 * Required: true
	 * Array: false
	 */
	shiftCd:string = undefined;
}
/**
 * Query parameter used to resolve move to Sic.
 */
export class GetMoveToSicQuery {
	/**
	 * Loaded destination sic code.
	 * Required: false
	 * Array: false
	 */
	loadDestinationSicCd:string = undefined;
	/**
	 * Door Number, used when there is non loadDestination.
	 * Required: false
	 * Array: false
	 */
	doorNumber:string = undefined;
}
/**
 * The resultant move To sic for the given attributes
 */
export class GetMoveToSicResp {
	/**
	 * Move to Sic
	 * Required: true
	 * Array: false
	 */
	moveToSicCd:string = undefined;
}
/**
 * Path parameter used to retrieve details for an operations trailer.
 */
export class GetOperationsTrailerPath {
	/**
	 * The surrogate identifier for the equipment.
	 * Required: true
	 * Array: false
	 */
	equipmentId:number = undefined;
}
/**
 * Response to the request to retrieve details for an operations trailer.
 */
export class GetOperationsTrailerResp {
	/**
	 * The number associated with the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * The destination location at which the trailer will be broken.
	 * Required: false
	 * Array: false
	 */
	loadedDestination:string = undefined;
	/**
	 * The close status of the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerShiftCd:string = undefined;
	/**
	 * The current status for the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerCurrentStatus:string = undefined;
	/**
	 * The current timestamp.
	 * Required: false
	 * Array: false
	 */
	currentEvntDateTime:Date = undefined;
	/**
	 * The load trailer's origin SIC.
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * The equipment's current SIC.
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * The FAC move to SIC.
	 * Required: false
	 * Array: false
	 */
	facMoveToSicCd:string = undefined;
	/**
	 * The OB move to SIC.
	 * Required: false
	 * Array: false
	 */
	obMoveToSicCd:string = undefined;
	/**
	 * The timestamp when the trailer closed.
	 * Required: false
	 * Array: false
	 */
	closeDateTime:Date = undefined;
	/**
	 * The first move leg from the trailer destination (using FLO).
	 * Required: false
	 * Array: false
	 */
	firstMoveLegTrailer:string = undefined;
	/**
	 * Indicates a pre-model closing trailer.
	 * Required: false
	 * Array: false
	 */
	preModelClosingTrailerInd:boolean = undefined;
	/**
	 * Indicates a post-model closing trailer.
	 * Required: false
	 * Array: false
	 */
	postModelClosingTrailerInd:boolean = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Request to return the geo area for a location.
 */
export class GetTrailerContentsQuery {
	/**
	 * The unique identifier for the equipment.
	 * Required: true
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * The number associated with the trailer.
	 * Required: true
	 * Array: false
	 */
	trailerNumber:string = undefined;
}
/**
 * Response to the request to return the geo area for a location.
 */
export class GetTrailerContentsResp {
	/**
	 * The number associated with the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerNumber:string = undefined;
	/**
	 * The current location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The destination location at which the trailer will be broken.
	 * Required: false
	 * Array: false
	 */
	loadDestination:string = undefined;
	/**
	 * The door number assoicated with the trailer.
	 * Required: false
	 * Array: false
	 */
	doorNumber:string = undefined;
	/**
	 * The sector number assoicated with the trailer.
	 * Required: false
	 * Array: false
	 */
	sectorNumber:number = undefined;
	/**
	 * The percent of the trailer that is filled with shipment.
	 * Required: false
	 * Array: false
	 */
	cubeUtilizationPct:number = undefined;
	/**
	 * The weight of the contents.
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * The close status of the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerShiftCd:string = undefined;
	/**
	 * The timestamp when the trailer was closed.
	 * Required: false
	 * Array: false
	 */
	closeTmst:Date = undefined;
	/**
	 * The current status for the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerCurrentStatus:string = undefined;
	/**
	 * The length of the trailer in feet.
	 * Required: false
	 * Array: false
	 */
	trailerLength:number = undefined;
	/**
	 * The current timestamp.
	 * Required: false
	 * Array: false
	 */
	currentEventTmst:Date = undefined;
	/**
	 * The date and time when the photo was taken.
	 * Required: false
	 * Array: false
	 */
	photoTakenTmst:Date = undefined;
	/**
	 * The identifier of the document.
	 * Required: false
	 * Array: false
	 */
	documentId:number = undefined;
	/**
	 * The identifier for the stored document in DMS for the Full Trailer Photo stored along with the thumbnail.
	 * Required: false
	 * Array: false
	 */
	fullPhotoDocumentId:number = undefined;
	/**
	 * The type of the document which is stored in DMS for the Full Trailer Photo stored along with the thumbnail.
	 * Required: false
	 * Array: false
	 */
	fullPhotoDocumentType:string = undefined;
	/**
	 * Unique identifier for the Trailer record to group trailer cycle from LDDK to LDDK.
	 * Required: false
	 * Array: false
	 */
	sequenceKey:string = undefined;
	/**
	 * Details about the loaded trailer contents.
	 * Required: false
	 * Array: true
	 */
	loadedTrailerContents:LoadedTrailerContent[] = undefined;
	/**
	 * Loadtrailer origin sic.
	 * Required: false
	 * Array: false
	 */
	origSic:string = undefined;
	/**
	 * Equipment current sic.
	 * Required: false
	 * Array: false
	 */
	currSic:string = undefined;
	/**
	 * Dispatch To (Move Leg) of the trailer
	 * Required: false
	 * Array: false
	 */
	moveToSic:string = undefined;
	/**
	 * The weight of the trailer closed.
	 * Required: false
	 * Array: false
	 */
	loadWeight:number = undefined;
	/**
	 * Standard Carrier Alpha Code, the code of the carrier
	 * Required: false
	 * Array: false
	 */
	foreignScacCd:string = undefined;
	/**
	 * Rental indicator, indicates if the trailer is a rental or subservice
	 * Required: false
	 * Array: false
	 */
	foreignRentalInd:string = undefined;
	/**
	 * Timestamp of the trailer closed
	 * Required: false
	 * Array: false
	 */
	closedTrailerTmst:Date = undefined;
	/**
	 * Loaded by Employee ID
	 * Required: false
	 * Array: false
	 */
	loadedBy:string = undefined;
}
/**
 * Path parameters used to return details for a load photo event.
 */
export class GetTrailerLoadPhotoEventDetailsPath {
	/**
	 * The unique identifier for the equipment.
	 * Required: true
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * The timestamp for the event.
	 * Required: true
	 * Array: false
	 */
	eventTmst:string = undefined;
}
/**
 * Response to the request to return details for a load photo event.
 */
export class GetTrailerLoadPhotoEventDetailsResp {
	/**
	 * The number associated with the trailer.
	 * Required: false
	 * Array: true
	 */
	events:Event[] = undefined;
}
/**
 * Path parameters used to return details for trailer load history.
 */
export class GetTrailerLoadHistoryPath {
	/**
	 * The identifier for the equipment.
	 * Required: true
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * The last upated timestamp for the trailer load.
	 * Required: true
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
}
/**
 * Query parameter used to return details for trailer load history.
 */
export class GetTrailerLoadHistoryQuery {
	/**
	 * Used to check when status changes to LDDK.
	 * Required: true
	 * Array: false
	 */
	equipmentStatus:string = undefined;
}
/**
 * Response to the request to return details for trailer load history.
 */
export class GetTrailerLoadHistoryResp {
	/**
	 * The details of the trailer load history.
	 * Required: false
	 * Array: false
	 */
	trailerLoadHist:TrailerLoadHist = undefined;
}
/**
 * Query parameters used to return the geo area for a location.
 */
export class GetTrailerWithScheduleInfoQuery {
	/**
	 * The unique identifier for the equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * The number associated with the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
}
/**
 * Response to the request to return the geo area for a location.
 */
export class GetTrailerWithScheduleInfoResp {
	/**
	 * The number associated with the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * The current location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The destination location at which the trailer will be broken.
	 * Required: false
	 * Array: false
	 */
	loadDestination:string = undefined;
	/**
	 * The door number assoicated with the trailer.
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * The sector number assoicated with the trailer.
	 * Required: false
	 * Array: false
	 */
	sectorNbr:number = undefined;
	/**
	 * The percent of the trailer that is filled with shipment.
	 * Required: false
	 * Array: false
	 */
	cubeUtilizationPct:number = undefined;
	/**
	 * The weight of the contents.
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * The close status of the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerShiftCd:string = undefined;
	/**
	 * The timestamp when the trailer was closed.
	 * Required: false
	 * Array: false
	 */
	closeTmst:Date = undefined;
	/**
	 * The current status for the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerCurrentStatus:string = undefined;
	/**
	 * The length of the trailer in feet.
	 * Required: false
	 * Array: false
	 */
	trailerLength:number = undefined;
	/**
	 * The current timestamp.
	 * Required: false
	 * Array: false
	 */
	currentEventTmst:Date = undefined;
	/**
	 * Loadtrailer origin sic.
	 * Required: false
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * Equipment current sic.
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * Dispatch To (Move Leg) of the trailer
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * The FAC move to SIC.
	 * Required: false
	 * Array: false
	 */
	facMoveToSicCd:string = undefined;
	/**
	 * The OB move to SIC.
	 * Required: false
	 * Array: false
	 */
	obMoveToSicCd:string = undefined;
	/**
	 * The weight of the trailer closed.
	 * Required: false
	 * Array: false
	 */
	loadWeight:number = undefined;
	/**
	 * Standard Carrier Alpha Code, the code of the carrier
	 * Required: false
	 * Array: false
	 */
	foreignScacCd:string = undefined;
	/**
	 * Rental indicator, indicates if the trailer is a rental or subservice
	 * Required: false
	 * Array: false
	 */
	foreignRentalInd:string = undefined;
	/**
	 * Timestamp of the trailer closed
	 * Required: false
	 * Array: false
	 */
	closedTrailerTmst:Date = undefined;
	/**
	 * First Move Leg from trailer destination (using flo).
	 * Required: false
	 * Array: false
	 */
	firstMoveLegTrailer:string = undefined;
	/**
	 * The schedule's final destination.
	 * Required: false
	 * Array: false
	 */
	scheduleFinalDestination:string = undefined;
	/**
	 * The schedule's destination.
	 * Required: false
	 * Array: false
	 */
	scheduleDestination:string = undefined;
	/**
	 * The ID number associated with the schedule.
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * The schedule estimated arrival timestamp.
	 * Required: false
	 * Array: false
	 */
	scheduleEstimatedArrivalTmst:Date = undefined;
	/**
	 * Indicates a schedule SIC meet and turn.
	 * Required: false
	 * Array: false
	 */
	isSicMeetAndTurn:boolean = undefined;
	/**
	 * Indicates a pre-model closing trailer.
	 * Required: false
	 * Array: false
	 */
	isPreModelClosingTrailer:boolean = undefined;
	/**
	 * The allowed or pilot SICs for alert creation.
	 * Required: false
	 * Array: true
	 */
	allowedSics:string[] = undefined;
	/**
	 * The cube threshold for underutilized bypass pups.
	 * Required: false
	 * Array: false
	 */
	underutilizedCube:string = undefined;
	/**
	 * The weight threshold for underutilized bypass pups.
	 * Required: false
	 * Array: false
	 */
	underutilizedWeight:string = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Request to Return wholesum view of model schedules (Planned) and Actual schedules.
 */
export class ListAllowableSchedulesPath {
	/**
	 * The location for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The different shifts followed by the operations teams, e.g. outbound, FAC, etc.
	 * Required: true
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The date for which the user wants to see the model instructions.
	 * Required: true
	 * Array: false
	 */
	plannedDate:Date = undefined;
	/**
	 * The collection of SICs under a single label representing SIC locations used in a model optimization.
	 * Required: true
	 * Array: false
	 */
	modelGroupCd:ModelGroupCd = undefined;
}
/**
 * Response to the request to return a list of planned and Actual schedules.
 */
export class ListAllowableSchedulesResp {
	/**
	 * The list of schedules by move including load lanes.
	 * Required: false
	 * Array: true
	 */
	allowableSchedulesSummary:AllowableSchedulesSummary[] = undefined;
	/**
	 * Summary of the metrics for schedules
	 * Required: false
	 * Array: false
	 */
	schedulesMetricsSummary:SchedulesMetricsSummary = undefined;
}
/**
 * Request to return the the changes for specific sic code and shift
 */
export class ListChangesForLanesPath {
	/**
	 * The unique identifier for service center.
	 * Required: true
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The selected shift code for the sic.
	 * Required: true
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The selected date to retrieve the changes.
	 * Required: true
	 * Array: false
	 */
	runDate:Date = undefined;
}
/**
 * Response to the request to return changes for specific sic code and shift.
 */
export class ListChangesForLanesResp {
	/**
	 * The unique identifier for service center.
	 * Required: true
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The selected shift code for the sic.
	 * Required: true
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The selected created timestamp to retrieve the changes.
	 * Required: true
	 * Array: false
	 */
	createdTmst:Date = undefined;
	/**
	 * Details about the change/s for the specific lane.
	 * Required: false
	 * Array: true
	 */
	changesContents:ChangesContent[] = undefined;
}
export class ListLoadRequestsRqst {
}
/**
 * Response to the request to list all Load Requests.
 */
export class ListLoadRequestsResp {
	/**
	 * A list of all of the Load Requests.
	 * Required: false
	 * Array: true
	 */
	loadRequests:LoadRequest[] = undefined;
	/**
	 * time since a LR can be approved or declined
	 * Required: false
	 * Array: false
	 */
	expirationDateFrom:Date = undefined;
	/**
	 * time until a LR can be approved or declined
	 * Required: false
	 * Array: false
	 */
	expirationDateTo:Date = undefined;
}
export class ListLoadRequestReasonCodesRqst {
}
/**
 * Response to the request to list all Load Request Reason Codes.
 */
export class ListLoadRequestReasonCodesResp {
	/**
	 * A list of all of the Load Request Reason Codes.
	 * Required: false
	 * Array: true
	 */
	loadRequestReasonCodes:LoadRequestReasonCode[] = undefined;
}
/**
 * 
 */
export class ListMetricsPath {
	/**
	 * The different shifts followed by the operations teams, e.g. outbound, FAC, etc.
	 * Required: true
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The current date for the shift.
	 * Required: true
	 * Array: false
	 */
	planDate:Date = undefined;
}
/**
 * 
 */
export class ListMetricsQuery {
	/**
	 * The unique identifier for the sic code selected.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The linehaul region that enclose several SIC.
	 * Required: true
	 * Array: false
	 */
	regionCd:string = undefined;
}
/**
 * ListMetricsPath response.
 */
export class ListMetricsResp {
	/**
	 * The linehaul region that enclose several SIC.
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * List of metric that match the input search criteria.
	 * Required: false
	 * Array: true
	 */
	summaryMetric:SummaryMetric[] = undefined;
}
/**
 * Request to upsert a Load Request.
 */
export class UpsertLoadRequestRqst {
	/**
	 * The Load Request which will be created or updated.
	 * Required: true
	 * Array: false
	 */
	loadRequest:LoadRequest = undefined;
}
/**
 * Response to the request to upsert a Load Request.
 */
export class UpsertLoadRequestResp {
	/**
	 * The Load Request which was created or updated.
	 * Required: false
	 * Array: false
	 */
	loadRequest:LoadRequest = undefined;
}
/**
 * Request to upsert a Load Request Reason Code.
 */
export class UpsertLoadRequestReasonCodeRqst {
	/**
	 * The Load Request Reason Code which will be created or updated.
	 * Required: true
	 * Array: false
	 */
	loadRequestReasonCode:LoadRequestReasonCode = undefined;
}
/**
 * Response to the request to upsert a Load Request Reason Code.
 */
export class UpsertLoadRequestReasonCodeResp {
	/**
	 * The Load Request Reason Codewhich was created or updated.
	 * Required: false
	 * Array: false
	 */
	loadRequestReasonCode:LoadRequestReasonCode = undefined;
}
/**
 * Request to upsert a Linehaul Bypass Trailer record.
 */
export class UpsertLinehaulBypassTrailerRqst {
	/**
	 * The Linehaul Bypass Trailer to be upserted
	 * Required: true
	 * Array: false
	 */
	lnhBypassTrailer:LnhBypassTrailer = undefined;
}
/**
 * Response to upserting a Linehaul Bypass Trailer record.
 */
export class UpsertLinehaulBypassTrailerResp {
	/**
	 * The Linehaul Bypass Trailer that was upserted
	 * Required: false
	 * Array: false
	 */
	lnhBypassTrailer:LnhBypassTrailer = undefined;
}
/**
 * Path parameter used to create an arrival schedule.
 */
export class CreateArrivalSchedulePath {
	/**
	 * The unique identifier for the arrival service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Request to create an arrival schedule.
 */
export class CreateArrivalScheduleRqst {
	/**
	 * The identifier of the schedule which is created on planning schedules.
	 * Required: true
	 * Array: false
	 */
	scheduleId:number = undefined;
	/**
	 * The current event timestamp..
	 * Required: true
	 * Array: false
	 */
	currentEventTmst:Date = undefined;
	/**
	 * The mileage of the tractor when the schedule is dispatched.
	 * Required: true
	 * Array: false
	 */
	mileage:number = undefined;
}
/**
 * Query parameters used to return the details for a Model Optimizer Run.
 */
export class GetModelRunDetailsQuery {
	/**
	 * Unique identifier for a model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:number = undefined;
	/**
	 * The collection of SICs under a single label representing SIC locations used in a model optimization.
	 * Required: false
	 * Array: false
	 */
	modelGroupCd:string = undefined;
	/**
	 * The shift code for an optimizer run.
	 * Required: false
	 * Array: false
	 */
	runShiftCd:string = undefined;
	/**
	 * The date of a model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runDate:Date = undefined;
}
/**
 * Response to the request to return a list of planned and Actual Loads.
 */
export class GetModelRunDetailsResp {
	/**
	 * The details of the model run.
	 * Required: false
	 * Array: false
	 */
	modelRun:ModelRun = undefined;
}
/**
 * Path parameters used to return trailer planned and actual counts per lane.
 */
export class GetTrailerCountsPerLanePath {
	/**
	 * The origin SIC.
	 * Required: true
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * The shift code.
	 * Required: true
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The planned date.
	 * Required: true
	 * Array: false
	 */
	plannedDate:string = undefined;
}
/**
 * Query parameters used to return trailer planned and actual counts per lane.
 */
export class GetTrailerCountsPerLaneQuery {
	/**
	 * The destination SIC.
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * The door number at which the trailer is being loaded.
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
}
/**
 * Response to the request to return trailer planned and actual counts per lane.
 */
export class GetTrailerCountsPerLaneResp {
	/**
	 * The lane resultant for the given data.
	 * Required: true
	 * Array: false
	 */
	lane:string = undefined;
	/**
	 * The count of planned trailers for the lane.
	 * Required: true
	 * Array: false
	 */
	plannedCount:number = undefined;
	/**
	 * The count of actual trailers for the lane.
	 * Required: true
	 * Array: false
	 */
	actualCount:number = undefined;
	/**
	 * The list of CLOS/LDDK trailers.
	 * Required: false
	 * Array: true
	 */
	closedAndLddkTrailers:string[] = undefined;
}
/**
 * Query parameter used to return a list of allowed triple move legs.
 */
export class ListAllowedTripleMoveLegsQuery {
	/**
	 * The assumed current SIC of any shipment on the corresponding move path.
	 * Required: true
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The assumed current shift of any shipment on the corresponding move path.
	 * Required: true
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * This field contains a number denoting the sequence of the current move path.  Values are: 1 - First occurrence and use for nightly revert, 2 or higher - Edited and denote as current move path to replace at cutoff for model.
	 * Required: false
	 * Array: false
	 */
	mpathSequenceNbr:number = undefined;
	/**
	 * The SIC at which the current move leg will be completed.
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * The shift during which the current move leg is set to be completed.
	 * Required: false
	 * Array: false
	 */
	moveToShiftCd:string = undefined;
	/**
	 * Flag indicating whether or not the corresponding move leg allows triples.
	 * Required: false
	 * Array: false
	 */
	tripleAllowedInd:string = undefined;
}
/**
 * Response to the request to return a list of allowed triple move legs.
 */
export class ListAllowedTripleMoveLegsResp {
	/**
	 * The details of the trailer load history.
	 * Required: false
	 * Array: true
	 */
	flowMovePaths:FlowMovePath[] = undefined;
}
/**
 * Request to Return wholesum view of model instructions (Planned) and Actual loads.
 */
export class ListModelInstructionsPath {
	/**
	 * The location for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The different shifts followed by the operations teams, e.g. outbound, FAC, etc.
	 * Required: true
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The date for which the user wants to see the model instructions.
	 * Required: true
	 * Array: false
	 */
	plannedDate:Date = undefined;
	/**
	 * The collection of SICs under a single label representing SIC locations used in a model optimization.
	 * Required: true
	 * Array: false
	 */
	modelGroupCd:ModelGroupCd = undefined;
}
/**
 * Query parameters used to list a wholesum view of model instructions (Planned) and Actual loads.
 */
export class ListModelInstructionsQuery {
	/**
	 * The subdivision, which is based on the door within the SIC.
	 * Required: false
	 * Array: false
	 */
	sector:string = undefined;
}
/**
 * Response to the request to return a list of planned and Actual Loads.
 */
export class ListModelInstructionsResp {
	/**
	 * The list of instructions by move including load lanes.
	 * Required: false
	 * Array: true
	 */
	moveLaneInstructionSummary:MoveLaneInstructionSummary[] = undefined;
	/**
	 * The current model release status for the SIC.
	 * Required: false
	 * Array: false
	 */
	releaseStatus:string = undefined;
	/**
	 * The shift code associated with the model run. Â The value is either O, F, E, or B.
	 * Required: false
	 * Array: false
	 */
	runShiftCd:string = undefined;
}
/**
 * Request to update allowed trip move legs.
 */
export class UpdateAllowedTripleMoveLegsRqst {
	/**
	 * The details of the trailer load history.
	 * Required: false
	 * Array: true
	 */
	flowMovePaths:FlowMovePath[] = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Search Record details.
 */
export class LoadedTrailerSearchRecord {
	/**
	 * Required: false
	 * Array: false
	 */
	loadedTrailerInstId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	closedByEmployeeId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status:TrailerStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shift:ShiftCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	door:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sector:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cubeUtilizationPct:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	images:LoadedTrailerImageDetail[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedBy:string = undefined;
}
/**
 * Details about schedules of a move lane.
 */
export class AllowableSchedulesSummary {
	/**
	 * Indicates schedules for the move lane.
	 * Required: false
	 * Array: false
	 */
	laneSchedulesSummary:LaneSchedulesSummary = undefined;
	/**
	 * Indicates schedules within the move lane.
	 * Required: false
	 * Array: true
	 */
	loadSchedulesSummary:LoadSchedulesSummary[] = undefined;
}
/**
 * 
 */
export class ChangesContent {
	/**
	 * The lane (move to sic)
	 * Required: false
	 * Array: false
	 */
	lane:string = undefined;
	/**
	 * The close to (loaded to sic code) where the freight is sent to.
	 * Required: false
	 * Array: false
	 */
	closeTo:string = undefined;
	/**
	 * The description type for the specific change.
	 * Required: false
	 * Array: false
	 */
	changeType:string = undefined;
	/**
	 * The description for the specific change.
	 * Required: false
	 * Array: false
	 */
	changeDescription:string = undefined;
	/**
	 * The user that modified the change.
	 * Required: false
	 * Array: false
	 */
	modifiedBy:string = undefined;
	/**
	 * When the change was modified.
	 * Required: false
	 * Array: false
	 */
	modifiedTmst:Date = undefined;
}
/**
 * HSS Run details from CMS for a given load destination.
 */
export class CmsPull {
	/**
	 * booking number from CMS
	 * Required: false
	 * Array: false
	 */
	booking:number = undefined;
	/**
	 * SCAC code on the pull or booking
	 * Required: false
	 * Array: false
	 */
	scac:string = undefined;
	/**
	 * Hazmat indicator on the pull or booking
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Service alert indicator on the pull or booking
	 * Required: false
	 * Array: false
	 */
	serviceAlertInd:boolean = undefined;
	/**
	 * Rate per mile for the pull or booking
	 * Required: false
	 * Array: false
	 */
	ratePerMile:number = undefined;
}
/**
 * HSS Instructions info from the model for a given load destination.
 */
export class HssPull {
	/**
	 * arrival shift
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * planned HSS cube
	 * Required: false
	 * Array: false
	 */
	cube:number = undefined;
	/**
	 * planned HSS weight
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * planned HSS net Diversions
	 * Required: false
	 * Array: false
	 */
	netDiversion:number = undefined;
	/**
	 * planned HSS loads
	 * Required: false
	 * Array: false
	 */
	pullCnt:number = undefined;
	/**
	 * Trailer Utilization Capability based on the planned HSS loads
	 * Required: false
	 * Array: false
	 */
	trailerUtilizationCapability:number = undefined;
}
/**
 * HSS Run Summary from HSS instructions and CMS for a given lane.
 */
export class HssRunSummary {
	/**
	 * The move mode type of a lane
	 * Required: false
	 * Array: false
	 */
	moveMode:MoveModeCd = undefined;
	/**
	 * The load to SIC location.
	 * Required: false
	 * Array: false
	 */
	loadToSicCd:string = undefined;
	/**
	 * The list HSS instructions for a load destination.
	 * Required: false
	 * Array: true
	 */
	hssPull:HssPull[] = undefined;
	/**
	 * The list of CMS runs for a load destination.
	 * Required: false
	 * Array: true
	 */
	cmsPull:CmsPull[] = undefined;
	/**
	 * The total CMS pull count for a given load destination.
	 * Required: false
	 * Array: false
	 */
	cmsPullCnt:number = undefined;
}
/**
 * Metrics for an instruction for loads, weight, cube etc.
 */
export class InstructionMetric {
	/**
	 * Number of loads
	 * Required: false
	 * Array: false
	 */
	loadCount:number = undefined;
	/**
	 * Number of loads
	 * Required: false
	 * Array: false
	 */
	loadAverage:number = undefined;
	/**
	 * Total weight for the loads
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * Total cube for the loads
	 * Required: false
	 * Array: false
	 */
	cube:number = undefined;
}
/**
 * Details about a loaded trailer.
 */
export class InstructionSummary {
	/**
	 * Lane.
	 * Required: false
	 * Array: false
	 */
	lane:string = undefined;
	/**
	 * Close To Sic.
	 * Required: false
	 * Array: false
	 */
	closeToSicCd:string = undefined;
	/**
	 * If there are one or more Xdock Shipments with guaranteed Ind = Y in the lane.
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Indicates bypass loads part of the load lane
	 * Required: false
	 * Array: false
	 */
	bypassLoadCount:number = undefined;
	/**
	 * Indicates planned HSS loads/weight/cube part of the load lane.
	 * Required: false
	 * Array: false
	 */
	plannedHss:InstructionMetric = undefined;
	/**
	 * Indicates actual HSS loads/weight/cube part of the load lane.
	 * Required: false
	 * Array: false
	 */
	loadedHss:InstructionMetric = undefined;
	/**
	 * Loads planned for the load lane
	 * Required: false
	 * Array: false
	 */
	planned:InstructionMetric = undefined;
	/**
	 * Loads planned for the load lane
	 * Required: false
	 * Array: false
	 */
	forecast:InstructionMetric = undefined;
	/**
	 * Loads planned for the load lane
	 * Required: false
	 * Array: false
	 */
	loaded:InstructionMetric = undefined;
	/**
	 * Loads planned for the load lane
	 * Required: false
	 * Array: false
	 */
	remaining:InstructionMetric = undefined;
	/**
	 * Trailer Utilization Capability based on the planned loads
	 * Required: false
	 * Array: false
	 */
	trailerUtilizationCapability:number = undefined;
	/**
	 * trailer Utilization based on the actual close trailers
	 * Required: false
	 * Array: false
	 */
	closedTrailerUtilization:number = undefined;
	/**
	 * Source of the Planned Instructions
	 * Required: false
	 * Array: false
	 */
	sourceCd:string = undefined;
	/**
	 * status of Move lane based on the current loads
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * Indicators on the lane whenever there is any exception on any of the trailers under that lane or Close-To SIC.
	 * Required: false
	 * Array: false
	 */
	exceptionInd:boolean = undefined;
	/**
	 * Move mode type
	 * Required: false
	 * Array: false
	 */
	moveMode:MoveModeCd = undefined;
	/**
	 * Indicates number of xpo trailers pr lane
	 * Required: false
	 * Array: false
	 */
	pupCnt:number = undefined;
	/**
	 * Indicates number of van per lane
	 * Required: false
	 * Array: false
	 */
	vanCnt:number = undefined;
}
/**
 * Details about schedules of a lane.
 */
export class LaneSchedulesSummary {
	/**
	 * Move to Sic of a lane
	 * Required: false
	 * Array: false
	 */
	laneSic:string = undefined;
	/**
	 * Planned schedules of a lane
	 * Required: false
	 * Array: false
	 */
	plannedMetrics:SchedulesMetrics = undefined;
	/**
	 * Actual schedules of a lane
	 * Required: false
	 * Array: false
	 */
	actualMetrics:SchedulesMetrics = undefined;
	/**
	 * Actual loaded weight of a lane
	 * Required: false
	 * Array: false
	 */
	loadedWgtLbs:number = undefined;
	/**
	 * Planned overages of a lane
	 * Required: false
	 * Array: false
	 */
	plannedOverages:number = undefined;
	/**
	 * List vias of a lane
	 * Required: false
	 * Array: true
	 */
	via:Via[] = undefined;
	/**
	 * List via helps of a lane
	 * Required: false
	 * Array: true
	 */
	viaHelp:Via[] = undefined;
	/**
	 * Loads covered by other drivers of a lane
	 * Required: false
	 * Array: false
	 */
	ldCoveredByOthers:number = undefined;
	/**
	 * Loads covered by current Sic of a lane
	 * Required: false
	 * Array: false
	 */
	ldCoveredByCurrSic:number = undefined;
	/**
	 * Number of sets
	 * Required: false
	 * Array: false
	 */
	setCount:number = undefined;
	/**
	 * Number of triples
	 * Required: false
	 * Array: false
	 */
	trpleCount:number = undefined;
	/**
	 * Number of RMDs
	 * Required: false
	 * Array: false
	 */
	rmdCount:number = undefined;
	/**
	 * Number of bob tails
	 * Required: false
	 * Array: false
	 */
	bobTailCount:number = undefined;
	/**
	 * Number of single vans
	 * Required: false
	 * Array: false
	 */
	snglVanCount:number = undefined;
	/**
	 * Number of single pups
	 * Required: false
	 * Array: false
	 */
	snglPupCount:number = undefined;
	/**
	 * Number of double runs
	 * Required: false
	 * Array: false
	 */
	dblTurnCount:number = undefined;
	/**
	 * SubService indicator (Excl / Supp) of a lane
	 * Required: false
	 * Array: false
	 */
	hssInd:string = undefined;
	/**
	 * HSS Run Summary from HSS instructions and CMS for a given lane.
	 * Required: false
	 * Array: true
	 */
	hssRunSummary:HssRunSummary[] = undefined;
}
/**
 * Details about a load lane.
 */
export class LoadLaneInstructionSummary {
	/**
	 * Indicates instructions for load lanes within the move lane.
	 * Required: false
	 * Array: false
	 */
	loadLaneInstructionSummary:InstructionSummary = undefined;
	/**
	 * Indicates instructions for load lanes within the move lane.
	 * Required: false
	 * Array: true
	 */
	trailerLoadInfo:TrailerLoadInfo[] = undefined;
}
/**
 * list schedules of a lane.
 */
export class LoadSchedulesSummary {
	/**
	 * driver name1 of a schedule
	 * Required: false
	 * Array: false
	 */
	drvrNm1:string = undefined;
	/**
	 * driver name2 of a schedule
	 * Required: false
	 * Array: false
	 */
	drvrNm2:string = undefined;
	/**
	 * Via sic of a schedule
	 * Required: false
	 * Array: false
	 */
	viaSic:string = undefined;
	/**
	 * Departure time of a schedule
	 * Required: false
	 * Array: false
	 */
	departTmst:Date = undefined;
	/**
	 * ETA time of a schedule
	 * Required: false
	 * Array: false
	 */
	eta:Date = undefined;
	/**
	 * Tractor number of a schedule
	 * Required: false
	 * Array: false
	 */
	tractorNbr:string = undefined;
	/**
	 * Trialer info of a schedule
	 * Required: true
	 * Array: false
	 */
	trailer1:TrailerInfo = undefined;
	/**
	 * Dolly of a schedule
	 * Required: false
	 * Array: false
	 */
	dolly1:string = undefined;
	/**
	 * Trialer info of a schedule
	 * Required: false
	 * Array: false
	 */
	trailer2:TrailerInfo = undefined;
	/**
	 * Dolly of a schedule
	 * Required: false
	 * Array: false
	 */
	dolly2:string = undefined;
	/**
	 * Trialer info of a schedule
	 * Required: false
	 * Array: false
	 */
	trailer3:TrailerInfo = undefined;
}
/**
 * Details about a loaded trailer.
 */
export class LoadedTrailerContent {
	/**
	 * The shipments associated with the trailer.
	 * Required: false
	 * Array: false
	 */
	shipment:Shipment = undefined;
	/**
	 * The shift code (I, O, N, D) when the shipment was loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	loadedShiftCd:ShiftCd = undefined;
	/**
	 * The door number at which the loading took place.
	 * Required: false
	 * Array: false
	 */
	loadedDoorNumber:string = undefined;
	/**
	 * The employee who did the loading.
	 * Required: false
	 * Array: false
	 */
	loadedBy:string = undefined;
	/**
	 * The timestamp at which the loading occurred.
	 * Required: false
	 * Array: false
	 */
	loadedTmst:Date = undefined;
	/**
	 * Indicates if a misload is authorized on this PRO.
	 * Required: false
	 * Array: false
	 */
	misloadedAuthorizedInd:boolean = undefined;
	/**
	 * Who authorized the misload.
	 * Required: false
	 * Array: false
	 */
	misloadAuthorizedBy:string = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Details about a loaded trailer image.
 */
export class LoadedTrailerImageDetail {
	/**
	 * Required: false
	 * Array: false
	 */
	imageTakenTimestamp:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	imageType:PhotoTypeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	thumbnail:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedBy:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	utilization:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	door:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shift:ShiftCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerStatus:string = undefined;
	/**
	 * The identifier for the stored document in DMS for the Full Trailer Photo stored along with the thumbnail.
	 * Required: false
	 * Array: false
	 */
	fullPhotoDocumentId:string = undefined;
	/**
	 * The type of the document which is stored in DMS for the Full Trailer Photo stored along with the thumbnail.
	 * Required: false
	 * Array: false
	 */
	fullPhotoDocumentType:string = undefined;
	/**
	 * The identifier for the thumbnail photo document.
	 * Required: false
	 * Array: false
	 */
	thumbnailPhotoDocumentId:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	pros:LoadedTrailerProDetail[] = undefined;
}
/**
 * Details about a loaded trailer pro.
 */
export class LoadedTrailerProDetail {
	/**
	 * Instance ID of the shipment
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	motorMoves:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	utilization:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	freightType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedByEmployeeId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedTimestamp:Date = undefined;
	/**
	 * Represents whether or not the shipment is guaranteed.
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Indicates if a misload is authorized on this PRO.
	 * Required: false
	 * Array: false
	 */
	misloadedAuthorizedInd:boolean = undefined;
	/**
	 * Who authorized the misload.
	 * Required: false
	 * Array: false
	 */
	misloadAuthorizedBy:string = undefined;
}
/**
 * Details about a move lane.
 */
export class MoveLaneInstructionSummary {
	/**
	 * Indicates instructions for the move lane.
	 * Required: false
	 * Array: false
	 */
	instructionSummary:InstructionSummary = undefined;
	/**
	 * Indicates instructions for load lanes within the move lane.
	 * Required: false
	 * Array: true
	 */
	loadLaneInstructionSummary:LoadLaneInstructionSummary[] = undefined;
}
/**
 * A schedule's equipment to be assigned.
 */
export class PlannedSchedule {
	/**
	 * mileage of the tractor when the schedule is dispatched.
	 * Required: false
	 * Array: false
	 */
	mileage:number = undefined;
	/**
	 * The final destination for the trailer.
	 * Required: true
	 * Array: false
	 */
	finalDestinationSic:string = undefined;
	/**
	 * The location where the trailer is scheduled.
	 * Required: true
	 * Array: false
	 */
	scheduleDestinationSic:string = undefined;
	/**
	 * The tractor equipment identification with prefix and suffix.
	 * Required: true
	 * Array: false
	 */
	tractorEquipmentId:string = undefined;
	/**
	 * The list of trailers identified by prefix and sufix
	 * Required: true
	 * Array: true
	 */
	trailerEquipmentIds:string[] = undefined;
	/**
	 * The list of indicators which determine if trailers are empty.
	 * Required: false
	 * Array: true
	 */
	emptiesInds:string[] = undefined;
	/**
	 * The list of dollies identified by prefix and sufix.
	 * Required: true
	 * Array: true
	 */
	dollyEquipmentIds:string[] = undefined;
	/**
	 * The list of driver identifiers.
	 * Required: true
	 * Array: true
	 */
	driverIds:string[] = undefined;
	/**
	 * The Current event time stamp.
	 * Required: true
	 * Array: false
	 */
	detailedDispatchLocalDateTime:Date = undefined;
	/**
	 * The estimated time of arrival in timestamp format.
	 * Required: true
	 * Array: false
	 */
	hssEta:Date = undefined;
}
/**
 * Summary schedules count of a lane.
 */
export class SchedulesMetrics {
	/**
	 * Number of drivers
	 * Required: false
	 * Array: false
	 */
	drvrCount:number = undefined;
	/**
	 * Number of sets
	 * Required: false
	 * Array: false
	 */
	loadsCount:number = undefined;
	/**
	 * Number of triples
	 * Required: false
	 * Array: false
	 */
	empties:number = undefined;
}
/**
 * The summary of the metrics for schedules
 */
export class SchedulesMetricsSummary {
	/**
	 * The unique identifier for service center.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Number of available drivers at a sic.
	 * Required: false
	 * Array: false
	 */
	dsrsAvailable:number = undefined;
	/**
	 * Number of required drivers at a sic.
	 * Required: false
	 * Array: false
	 */
	dsrsRequired:number = undefined;
	/**
	 * Number of dispatched drivers at a sic.
	 * Required: false
	 * Array: false
	 */
	dsrsDispatched:number = undefined;
	/**
	 * Number of planned empties at a sic.
	 * Required: false
	 * Array: false
	 */
	plannedEmpties:number = undefined;
	/**
	 * Number of actual empties at a sic.
	 * Required: false
	 * Array: false
	 */
	actualEmpties:number = undefined;
	/**
	 * Number of planned overages at a sic.
	 * Required: false
	 * Array: false
	 */
	plannedOverages:number = undefined;
}
/**
 * The metric that matches the input search criteria.
 */
export class SummaryMetric {
	/**
	 * The unique identifier for the SIC code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Number of  drivers at a SIC as entered into system by the location SIC.
	 * Required: false
	 * Array: false
	 */
	dsrsAvailable:number = undefined;
	/**
	 * Number of drivers defined as required by the Model instructions.
	 * Required: false
	 * Array: false
	 */
	dsrsRequired:number = undefined;
	/**
	 * Number of loads to be created by a location SIC's shift, defined by the Model instructions.
	 * Required: false
	 * Array: false
	 */
	loadsPlanned:number = undefined;
	/**
	 * Number of loads to be created by a location SIC's shift, defined by the Model instructions.
	 * Required: false
	 * Array: false
	 */
	loadsActual:number = undefined;
	/**
	 * Number of empty trailers to be moved from location SIC on the shift.
	 * Required: false
	 * Array: false
	 */
	empties:number = undefined;
	/**
	 * Number of loads instructed by the Model which doesn't have drivers.
	 * Required: false
	 * Array: false
	 */
	loadsOver:number = undefined;
	/**
	 * Number of drivers who can dispatch and return from a location SIC's shift twice.
	 * Required: false
	 * Array: false
	 */
	doubleTurns:number = undefined;
	/**
	 * The weighted average weight (lbs) by distance (miles) dispatched by location SIC/shift.
	 * Required: false
	 * Array: false
	 */
	loadFactor:number = undefined;
	/**
	 * Represents trailer utilization (TU) percentage based on actually closed loads.
	 * Required: false
	 * Array: false
	 */
	trailerUtilization:number = undefined;
	/**
	 * Represents Trailer utilization capability (TUC) percentage provided by Model.
	 * Required: false
	 * Array: false
	 */
	trailerUtilizationCapability:number = undefined;
	/**
	 * Closed loaded weight / # of Loads closed at a location SIC on a shift.
	 * Required: false
	 * Array: false
	 */
	loadAvgPlanActual:number = undefined;
	/**
	 * Closed loaded weight / # of Loads closed at a location SIC on a shift.
	 * Required: false
	 * Array: false
	 */
	loadAvgActual:number = undefined;
	/**
	 * The status of the Model instructions for the location SIC (e.g. pending, released).
	 * Required: false
	 * Array: false
	 */
	releaseStatus:string = undefined;
}
/**
 * Loaded Trailer Info
 */
export class TrailerInfo {
	/**
	 * equipmentIdPrefix
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * equipmentIdSuffixNbr
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:string = undefined;
	/**
	 * Load destination of a trailer
	 * Required: false
	 * Array: false
	 */
	loadDestSic:string = undefined;
	/**
	 * Actual loaded weight of a trailer
	 * Required: false
	 * Array: false
	 */
	loadedWgtLbs:number = undefined;
	/**
	 * Indicators (G, G12, RSS) of a trailer
	 * Required: false
	 * Array: true
	 */
	indicators:string[] = undefined;
	/**
	 * Amount of time the trailer is dwelling at the dock after it is closed and before it is dispatched
	 * Required: false
	 * Array: false
	 */
	dwellTime:string = undefined;
}
/**
 * Loaded Trailer Info
 */
export class TrailerLoadInfo {
	/**
	 * Trailer Number
	 * Required: false
	 * Array: false
	 */
	trailerLoadHist:TrailerLoadHist = undefined;
	/**
	 * Move mode type
	 * Required: false
	 * Array: false
	 */
	moveMode:MoveModeCd = undefined;
	/**
	 * Information about a headload
	 * Required: false
	 * Array: false
	 */
	headLoadDetail:HeadLoadDetail = undefined;
	/**
	 * Indicators on the lane whenever there is any exception on any of the trailers under that lane or Close-To SIC.
	 * Required: false
	 * Array: false
	 */
	exceptionInd:boolean = undefined;
	/**
	 * Indicates if this load is a bypass trailer.
	 * Required: false
	 * Array: false
	 */
	bypassTrlrInd:boolean = undefined;
	/**
	 * Indicates if this load is a partial bypass trailer.
	 * Required: false
	 * Array: false
	 */
	ptlTrlrInd:boolean = undefined;
	/**
	 * List of shipments on trailer.
	 * Required: false
	 * Array: true
	 */
	loadedShipment:LoadedShipment[] = undefined;
	/**
	 * source for this trailer info
	 * Required: false
	 * Array: false
	 */
	source:string = undefined;
}
/**
 * Vias of a lane.
 */
export class Via {
	/**
	 * Current sic of a lane
	 * Required: false
	 * Array: false
	 */
	currSic:string = undefined;
	/**
	 * Via sic of a lane
	 * Required: false
	 * Array: false
	 */
	viaSic:string = undefined;
	/**
	 * Number of via drops
	 * Required: false
	 * Array: false
	 */
	viaDropCount:number = undefined;
	/**
	 * Number of via picks
	 * Required: false
	 * Array: false
	 */
	viaPickCount:number = undefined;
}
/**
 * Path parameters for get Ensemble status counts
 */
export class GetEnsembleStatusCountsPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * From timestamp
	 * Required: true
	 * Array: false
	 */
	fromTmst:Date = undefined;
	/**
	 * To timestamp
	 * Required: true
	 * Array: false
	 */
	toTmst:Date = undefined;
}
/**
 * Response to the request to get Ensemble status counts
 */
export class GetEnsembleStatusCountsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleMetrics:EnsembleMetrics = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	queueMetrics:QueueMetrics = undefined;
}
/**
 * Path parameters for get Ensemble status counts
 */
export class GetQueuePendingCountPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Response for getQueuePendingCount
 */
export class GetQueuePendingCountResp {
	/**
	 * Number of messages in either ready or wait status. This count indicates the queue depth.
	 * Required: false
	 * Array: false
	 */
	pendingCount:number = undefined;
}
/**
 * Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsemblePath {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Unique identifier for the application workflow.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsembleRqst {
}
/**
 * Response to the Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsembleResp {
	/**
	 * Ensemble that was Restarted.
	 * Required: false
	 * Array: false
	 */
	ensembleRestartedInd:boolean = undefined;
}
/**
 * Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsemblePath {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Unique identifier for the application workflow.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsembleRqst {
}
/**
 * Response to the Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsembleResp {
	/**
	 * Ensemble that was Resumed
	 * Required: false
	 * Array: false
	 */
	ensembleResumedInd:boolean = undefined;
}
/**
 * Request to start ensemble.
 */
export class StartMessageDrivenEnsembleRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	queue:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	message:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	eventDataType:string = undefined;
}
/**
 * Enter comments
 */
export class StartMessageDrivenEnsembleResp {
	/**
	 * Instance ID of the ensemble that was started.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to stop execution of an Ensemble
 */
export class KillEnsembleRqst {
	/**
	 * Unique identifier for the ensemble.
	 * Required: true
	 * Array: true
	 */
	ensembleInstIds:string[] = undefined;
}
/**
 * Response to the request to stop execution of an Ensemble
 */
export class KillEnsembleResp {
	/**
	 * Ensembles that were killed.
	 * Required: false
	 * Array: true
	 */
	status:KillEnsembleStatus[] = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueuePath {
	/**
	 * Name of the queue to stop
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueueWebhookPath {
	/**
	 * Name of the queue to stop
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueueWebhookQuery {
	/**
	 * Authorization token
	 * Required: false
	 * Array: false
	 */
	auth_token:string = undefined;
}
/**
 * Path parameters for startQueue
 */
export class StartQueuePath {
	/**
	 * Name of the queue to start
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Response for listQueues
 */
export class ListQueuesResp {
	/**
	 * Name of the queue to start
	 * Required: false
	 * Array: true
	 */
	queues:SymphonyQueue[] = undefined;
}
/**
 * Path parameters
 */
export class ListExpiredMessagesPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * From enqueue timestamp
	 * Required: true
	 * Array: false
	 */
	enqueueFromTmst:Date = undefined;
	/**
	 * Tom enqueue timestamp
	 * Required: true
	 * Array: false
	 */
	enqueueToTmst:Date = undefined;
}
/**
 * Response
 */
export class ListExpiredMessagesResp {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: true
	 */
	message:SymphonyMessage[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteExpiredMessagesPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters
 */
export class DeleteExpiredMessagesQuery {
	/**
	 * Id of the message to be deleted
	 * Required: true
	 * Array: true
	 */
	messageId:string[] = undefined;
}
/**
 * Response to the request to stop execution of an Ensemble
 */
export class DeleteExpiredMessagesResp {
	/**
	 * Ensembles that were killed.
	 * Required: false
	 * Array: true
	 */
	status:DeleteExpiredMessageStatus[] = undefined;
}
/**
 * Path parameters
 */
export class ReplayMessagePath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * ID of the message to replay. It needs to be in expired status
	 * Required: true
	 * Array: false
	 */
	messageId:string = undefined;
}
export class MessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The event occurrence timestamp
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:object = undefined;
}
export class DynamicMessageDrivenPayload {
	/**
	 * The type of the payload
	 * Required: true
	 * Array: false
	 */
	eventDataType:string = undefined;
	/**
	 * The message payload
	 * Required: true
	 * Array: false
	 */
	payload:string = undefined;
}
/**
 * Status of a delete expired message request
 */
export class DeleteExpiredMessageStatus {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Indicates whether the ensemble was killed.
	 * Required: false
	 * Array: false
	 */
	messageDeletedInd:boolean = undefined;
	/**
	 * A status describing why the message could not be deleted. Populated if messageDeletedInd is false
	 * Required: false
	 * Array: false
	 */
	failStatus:string = undefined;
}
/**
 * Status of a kill ensemble request
 */
export class KillEnsembleStatus {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
	/**
	 * Indicates whether the ensemble was killed.
	 * Required: false
	 * Array: false
	 */
	ensembleKilledInd:boolean = undefined;
	/**
	 * A status describing why the ensemble could not be killed. Populated if ensembleKilledInd is false
	 * Required: false
	 * Array: false
	 */
	failStatus:string = undefined;
}
/**
 * Stores execution information for application workflows.
 */
export class EnsembleStateSearchRecord {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	instId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState:EnsembleState = undefined;
}
export class Payload {
	/**
	 * Required: true
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	value:object = undefined;
}
export class EnsembleState {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	instId:string = undefined;
	/**
	 * Name of the application workflow.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * A short description of the ensemble
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * Name of the queue publishing this message.
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Id of the message.
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * The timestamp of when the trigger event was created.
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The timestamp of when the message was received by ensemble.
	 * Required: false
	 * Array: false
	 */
	recvTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble was dispatched to the executor thread.
	 * Required: false
	 * Array: false
	 */
	dispatchTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble processing started.
	 * Required: false
	 * Array: false
	 */
	strtTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble processing ended.
	 * Required: false
	 * Array: false
	 */
	endTmst:Date = undefined;
	/**
	 * Dequeue time in milliseconds. Populated for started ensembles, and is the difference between the received and event timestamps.
	 * Required: false
	 * Array: false
	 */
	dequeueTime:number = undefined;
	/**
	 * Dispatch time in milliseconds. Populated for started ensembles, and is the difference between the dispatch and received timestamps.
	 * Required: false
	 * Array: false
	 */
	dispatchTime:number = undefined;
	/**
	 * Wait time in milliseconds. Populated for started ensembles, and is the difference between the start and dispatch timestamps.
	 * Required: false
	 * Array: false
	 */
	waitTime:number = undefined;
	/**
	 * Time in milliseconds it took to process this ensemble from the time it was started. Populated for completed ensembles, and is the difference between the end and start timestamps.
	 * Required: false
	 * Array: false
	 */
	processTime:number = undefined;
	/**
	 * Total elapsed time in milliseconds to process this ensemble from the time the event was created. Populated for completed ensembles, and is the difference between the start and event timestamps.
	 * Required: false
	 * Array: false
	 */
	totalTime:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * Status of workflow execution.  
	 * 
	 * Valid values: 
	 * NOT_STARTED 
	 * STARTED
	 * SUSPENDED 
	 * COMPLETED
	 * Required: false
	 * Array: false
	 */
	statCd:EnsembleExecutionStatusCd = undefined;
	/**
	 * Status message for the workflow execution.  This could be an error message, stacktrace, success message, etc.
	 * Required: false
	 * Array: false
	 */
	statMsg:string = undefined;
	/**
	 * Same value for CORRELATION 
	 * 
	 * id is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multiple rows across various tables for a single business 
	 * 
	 * transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Name of the host on which the ensemble was last processed
	 * Required: false
	 * Array: false
	 */
	host:string = undefined;
	/**
	 * Name of the thread on which the ensemble was last processed
	 * Required: false
	 * Array: false
	 */
	thread:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	minuetStates:MinuetState[] = undefined;
	/**
	 * Payload that is input to the ensemble execution.
	 * Required: false
	 * Array: false
	 */
	payload:Payload = undefined;
}
/**
 * Stores execution information for the tasks that compose the application workflow.
 */
export class MinuetState {
	/**
	 * Name of the task.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Response payload that is output  from the task.
	 * Required: false
	 * Array: false
	 */
	respPayload:Payload = undefined;
	/**
	 * Datetime the task execution started
	 * Required: false
	 * Array: false
	 */
	strtTmst:Date = undefined;
	/**
	 * Datetime the task execution ended
	 * Required: false
	 * Array: false
	 */
	endTmst:Date = undefined;
	/**
	 * Time in milliseconds it took to process this minuet from the time it was started. Populated for completed minuets, and is the difference between the end and start timestamps.
	 * Required: false
	 * Array: false
	 */
	processTime:number = undefined;
	/**
	 * Number of times this minuet was retried
	 * Required: false
	 * Array: false
	 */
	executionCount:number = undefined;
	/**
	 * Status of task execution.  
	 * 
	 * Valid values:  
	 * STARTED
	 * FAILED 
	 * COMPLETED
	 * Required: false
	 * Array: false
	 */
	statCd:MinuetExecutionStatusCd = undefined;
	/**
	 * Status message for the task.  This could be an error message, stacktrace, success message, etc.
	 * Required: false
	 * Array: false
	 */
	statMsg:string = undefined;
}
export class EnsembleMetrics {
	/**
	 * Required: false
	 * Array: false
	 */
	notStartedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	startedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	completedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suspendedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	failedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	killedCount:number = undefined;
}
export class QueueMetrics {
	/**
	 * Required: false
	 * Array: false
	 */
	readyCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	waitCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	processedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expiredCount:number = undefined;
}
export class EqpEventMessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:EventSnapshot = undefined;
}
export class ScoEventMessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:ScoEventSnapshot = undefined;
}
export class OpsProMvmtHistMessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:ProMovementHistSnapshot = undefined;
}
export class LhoEventMessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:LhoEventSnapshot = undefined;
}
/**
 * An Update response from a BULK update to elastic
 */
export class BulkUpdateResp {
	/**
	 * Required: false
	 * Array: false
	 */
	_index:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	_id:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	error:BulkUpdateItemError = undefined;
}
/**
 * Details of an error for an item
 */
export class BulkUpdateItemError {
	/**
	 * Required: false
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	reason:string = undefined;
}
/**
 * An item in the bulk api response
 */
export class BulkApiRespItem {
	/**
	 * Required: false
	 * Array: false
	 */
	update:BulkUpdateResp = undefined;
}
export class LoadedTrailerSearchFilterNot {
	/**
	 * Required: false
	 * Array: true
	 */
	and:LoadedTrailerSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:LoadedTrailerSearchFilterOr[] = undefined;
}
export class LoadedTrailerSearchFilterOr {
	/**
	 * Required: false
	 * Array: false
	 */
	loadedTrailerInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedTrailerInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sector:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sector_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	door:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	door_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shift:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shift_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	closedByEmployeeId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	closedByEmployeeId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_guaranteedInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_guaranteedInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_hazmatInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_hazmatInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingDate_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_imageType:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_imageType_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cubeUtilizationPct:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cubeUtilizationPct_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moveToSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedBy:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedBy_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:LoadedTrailerSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:LoadedTrailerSearchFilterNot[] = undefined;
	public toJSON = function(this: LoadedTrailerSearchFilterOr) {
		return {
			"loadedTrailerInstId" : this.loadedTrailerInstId,
			"loadedTrailerInstId.keyword" : this.loadedTrailerInstId_keyword,
			"loadingSic" : this.loadingSic,
			"loadingSic.keyword" : this.loadingSic_keyword,
			"loadDestinationSic" : this.loadDestinationSic,
			"loadDestinationSic.keyword" : this.loadDestinationSic_keyword,
			"sector" : this.sector,
			"sector.keyword" : this.sector_keyword,
			"door" : this.door,
			"door.keyword" : this.door_keyword,
			"shift" : this.shift,
			"shift.keyword" : this.shift_keyword,
			"status" : this.status,
			"status.keyword" : this.status_keyword,
			"closedByEmployeeId" : this.closedByEmployeeId,
			"closedByEmployeeId.keyword" : this.closedByEmployeeId_keyword,
			"trailerNbr" : this.trailerNbr,
			"trailerNbr.keyword" : this.trailerNbr_keyword,
			"images.pros.guaranteedInd" : this.images_pros_guaranteedInd,
			"images.pros.guaranteedInd.keyword" : this.images_pros_guaranteedInd_keyword,
			"images.pros.hazmatInd" : this.images_pros_hazmatInd,
			"images.pros.hazmatInd.keyword" : this.images_pros_hazmatInd_keyword,
			"loadingDate" : this.loadingDate,
			"loadingDate.keyword" : this.loadingDate_keyword,
			"weight" : this.weight,
			"weight.keyword" : this.weight_keyword,
			"images.imageType" : this.images_imageType,
			"images.imageType.keyword" : this.images_imageType_keyword,
			"cubeUtilizationPct" : this.cubeUtilizationPct,
			"cubeUtilizationPct.keyword" : this.cubeUtilizationPct_keyword,
			"currentSicCd" : this.currentSicCd,
			"currentSicCd.keyword" : this.currentSicCd_keyword,
			"originSicCd" : this.originSicCd,
			"originSicCd.keyword" : this.originSicCd_keyword,
			"moveToSicCd" : this.moveToSicCd,
			"moveToSicCd.keyword" : this.moveToSicCd_keyword,
			"loadedBy" : this.loadedBy,
			"loadedBy.keyword" : this.loadedBy_keyword,
			"and" : this.and,
			"not" : this.not		}
	}
}
export class LoadedTrailerSearchFilterAnd {
	/**
	 * Required: false
	 * Array: false
	 */
	loadedTrailerInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedTrailerInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sector:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sector_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	door:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	door_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shift:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shift_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	closedByEmployeeId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	closedByEmployeeId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_guaranteedInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_guaranteedInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_hazmatInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_hazmatInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingDate_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_imageType:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_imageType_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cubeUtilizationPct:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cubeUtilizationPct_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moveToSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedBy:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedBy_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:LoadedTrailerSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:LoadedTrailerSearchFilterNot[] = undefined;
	public toJSON = function(this: LoadedTrailerSearchFilterAnd) {
		return {
			"loadedTrailerInstId" : this.loadedTrailerInstId,
			"loadedTrailerInstId.keyword" : this.loadedTrailerInstId_keyword,
			"loadingSic" : this.loadingSic,
			"loadingSic.keyword" : this.loadingSic_keyword,
			"loadDestinationSic" : this.loadDestinationSic,
			"loadDestinationSic.keyword" : this.loadDestinationSic_keyword,
			"sector" : this.sector,
			"sector.keyword" : this.sector_keyword,
			"door" : this.door,
			"door.keyword" : this.door_keyword,
			"shift" : this.shift,
			"shift.keyword" : this.shift_keyword,
			"status" : this.status,
			"status.keyword" : this.status_keyword,
			"closedByEmployeeId" : this.closedByEmployeeId,
			"closedByEmployeeId.keyword" : this.closedByEmployeeId_keyword,
			"trailerNbr" : this.trailerNbr,
			"trailerNbr.keyword" : this.trailerNbr_keyword,
			"images.pros.guaranteedInd" : this.images_pros_guaranteedInd,
			"images.pros.guaranteedInd.keyword" : this.images_pros_guaranteedInd_keyword,
			"images.pros.hazmatInd" : this.images_pros_hazmatInd,
			"images.pros.hazmatInd.keyword" : this.images_pros_hazmatInd_keyword,
			"loadingDate" : this.loadingDate,
			"loadingDate.keyword" : this.loadingDate_keyword,
			"weight" : this.weight,
			"weight.keyword" : this.weight_keyword,
			"images.imageType" : this.images_imageType,
			"images.imageType.keyword" : this.images_imageType_keyword,
			"cubeUtilizationPct" : this.cubeUtilizationPct,
			"cubeUtilizationPct.keyword" : this.cubeUtilizationPct_keyword,
			"currentSicCd" : this.currentSicCd,
			"currentSicCd.keyword" : this.currentSicCd_keyword,
			"originSicCd" : this.originSicCd,
			"originSicCd.keyword" : this.originSicCd_keyword,
			"moveToSicCd" : this.moveToSicCd,
			"moveToSicCd.keyword" : this.moveToSicCd_keyword,
			"loadedBy" : this.loadedBy,
			"loadedBy.keyword" : this.loadedBy_keyword,
			"or" : this.or,
			"not" : this.not		}
	}
}
export class LoadedTrailerSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedTrailerInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedTrailerInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sector:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sector_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	door:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	door_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shift:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shift_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	closedByEmployeeId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	closedByEmployeeId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_guaranteedInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_guaranteedInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_hazmatInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_pros_hazmatInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadingDate_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_imageType:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	images_imageType_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cubeUtilizationPct:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cubeUtilizationPct_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moveToSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedBy:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedBy_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:LoadedTrailerSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:LoadedTrailerSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:LoadedTrailerSearchFilterNot[] = undefined;
	public toJSON = function(this: LoadedTrailerSearchFilter) {
		return {
			"q" : this.q,
			"b" : this.b,
			"loadedTrailerInstId" : this.loadedTrailerInstId,
			"loadedTrailerInstId.keyword" : this.loadedTrailerInstId_keyword,
			"loadingSic" : this.loadingSic,
			"loadingSic.keyword" : this.loadingSic_keyword,
			"loadDestinationSic" : this.loadDestinationSic,
			"loadDestinationSic.keyword" : this.loadDestinationSic_keyword,
			"sector" : this.sector,
			"sector.keyword" : this.sector_keyword,
			"door" : this.door,
			"door.keyword" : this.door_keyword,
			"shift" : this.shift,
			"shift.keyword" : this.shift_keyword,
			"status" : this.status,
			"status.keyword" : this.status_keyword,
			"closedByEmployeeId" : this.closedByEmployeeId,
			"closedByEmployeeId.keyword" : this.closedByEmployeeId_keyword,
			"trailerNbr" : this.trailerNbr,
			"trailerNbr.keyword" : this.trailerNbr_keyword,
			"images.pros.guaranteedInd" : this.images_pros_guaranteedInd,
			"images.pros.guaranteedInd.keyword" : this.images_pros_guaranteedInd_keyword,
			"images.pros.hazmatInd" : this.images_pros_hazmatInd,
			"images.pros.hazmatInd.keyword" : this.images_pros_hazmatInd_keyword,
			"loadingDate" : this.loadingDate,
			"loadingDate.keyword" : this.loadingDate_keyword,
			"weight" : this.weight,
			"weight.keyword" : this.weight_keyword,
			"images.imageType" : this.images_imageType,
			"images.imageType.keyword" : this.images_imageType_keyword,
			"cubeUtilizationPct" : this.cubeUtilizationPct,
			"cubeUtilizationPct.keyword" : this.cubeUtilizationPct_keyword,
			"currentSicCd" : this.currentSicCd,
			"currentSicCd.keyword" : this.currentSicCd_keyword,
			"originSicCd" : this.originSicCd,
			"originSicCd.keyword" : this.originSicCd_keyword,
			"moveToSicCd" : this.moveToSicCd,
			"moveToSicCd.keyword" : this.moveToSicCd_keyword,
			"loadedBy" : this.loadedBy,
			"loadedBy.keyword" : this.loadedBy_keyword,
			"and" : this.and,
			"or" : this.or,
			"not" : this.not		}
	}
}
/**
 * Path parameters
 */
export class GetElasticDocumentLoadedTrailerPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	loadedTrailerInstId:string = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentLoadedTrailerQuery {
	/**
	 * Source filter
	 * Required: false
	 * Array: false
	 */
	_source:string = undefined;
	/**
	 * Source include filter
	 * Required: false
	 * Array: false
	 */
	_source_includes:string = undefined;
	/**
	 * Source exclude filter
	 * Required: false
	 * Array: false
	 */
	_source_excludes:string = undefined;
}
/**
 * Response from getElasticDocument.
 */
export class GetElasticDocumentLoadedTrailerResp {
	/**
	 * The record if found
	 * Required: false
	 * Array: false
	 */
	record:LoadedTrailerSearchRecord = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentLoadedTrailerCountQuery {
	/**
	 * Query filter
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
}
/**
 * Response from getElasticDocumentCount.
 */
export class GetElasticDocumentLoadedTrailerCountResp {
	/**
	 * The count of the documents
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
}
/**
 * Request message to update information for LoadedTrailer.
 */
export class UpdateElasticDocumentLoadedTrailerRqst {
	/**
	 * The record to upsert in elastic
	 * Required: false
	 * Array: false
	 */
	doc:LoadedTrailerSearchRecord = undefined;
	/**
	 * Indicate if an upsert operation should be executed if the document was not found
	 * Required: false
	 * Array: false
	 */
	doc_as_upsert:boolean = undefined;
}
/**
 * Path parameters
 */
export class UpdateElasticDocumentLoadedTrailerPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	loadedTrailerInstId:string = undefined;
}
/**
 * Response from updateElasticDocument.
 */
export class UpdateElasticDocumentLoadedTrailerResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Response from bulkUpdateElasticDocument.
 */
export class BulkUpdateElasticDocumentLoadedTrailerResp {
	/**
	 * The time it took to process this request
	 * Required: false
	 * Array: false
	 */
	took:number = undefined;
	/**
	 * Indicates if there are any errors
	 * Required: false
	 * Array: false
	 */
	errors:boolean = undefined;
	/**
	 * Results of the bulk operations
	 * Required: false
	 * Array: true
	 */
	items:BulkApiRespItem[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteElasticDocumentLoadedTrailerPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	loadedTrailerInstId:string = undefined;
}
/**
 * Request message to delete information for LoadedTrailer.
 */
export class DeleteElasticDocumentLoadedTrailerResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Request message to search information for loaded-trailer-images.
 */
export class SearchLoadedTrailerImagesRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:LoadedTrailerSearchFilter = undefined;
}
/**
 * Response to the request to search information for loaded-trailer-images.
 */
export class SearchLoadedTrailerImagesResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:LoadedTrailerSearchRecord[] = undefined;
}
/**
 * Request message to export information for loaded-trailer-images.
 */
export class ExportLoadedTrailerImagesRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileType:string = undefined;
	/**
	 * Query for exporting
	 * Required: false
	 * Array: false
	 */
	filter:LoadedTrailerSearchFilter = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterLoadedTrailerImagesRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:LoadedTrailerSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterLoadedTrailerImagesResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterLoadedTrailerImagesRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedLoadedTrailerImagesRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:LoadedTrailerSearchRecord[] = undefined;
}
/**
 * Request message to search information for loaded-trailer-images-unfiltered.
 */
export class SearchLoadedTrailerImagesUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:LoadedTrailerSearchFilter = undefined;
}
/**
 * Response to the request to search information for loaded-trailer-images-unfiltered.
 */
export class SearchLoadedTrailerImagesUnfilteredResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:LoadedTrailerSearchRecord[] = undefined;
}
/**
 * Request message to export information for loaded-trailer-images-unfiltered.
 */
export class ExportLoadedTrailerImagesUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileType:string = undefined;
	/**
	 * Query for exporting
	 * Required: false
	 * Array: false
	 */
	filter:LoadedTrailerSearchFilter = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterLoadedTrailerImagesUnfilteredRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:LoadedTrailerSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterLoadedTrailerImagesUnfilteredResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterLoadedTrailerImagesUnfilteredRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedLoadedTrailerImagesUnfilteredRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:LoadedTrailerSearchRecord[] = undefined;
}
export class EnsembleStateSearchFilterNot {
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
}
export class EnsembleStateSearchFilterOr {
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilterOr) {
		return {
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"and" : this.and,
			"not" : this.not		}
	}
}
export class EnsembleStateSearchFilterAnd {
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilterAnd) {
		return {
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"or" : this.or,
			"not" : this.not		}
	}
}
export class EnsembleStateSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilter) {
		return {
			"q" : this.q,
			"b" : this.b,
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"and" : this.and,
			"or" : this.or,
			"not" : this.not		}
	}
}
/**
 * Path parameters
 */
export class GetElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentEnsembleStateQuery {
	/**
	 * Source filter
	 * Required: false
	 * Array: false
	 */
	_source:string = undefined;
	/**
	 * Source include filter
	 * Required: false
	 * Array: false
	 */
	_source_includes:string = undefined;
	/**
	 * Source exclude filter
	 * Required: false
	 * Array: false
	 */
	_source_excludes:string = undefined;
}
/**
 * Response from getElasticDocument.
 */
export class GetElasticDocumentEnsembleStateResp {
	/**
	 * The record if found
	 * Required: false
	 * Array: false
	 */
	record:EnsembleStateSearchRecord = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentEnsembleStateCountQuery {
	/**
	 * Query filter
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
}
/**
 * Response from getElasticDocumentCount.
 */
export class GetElasticDocumentEnsembleStateCountResp {
	/**
	 * The count of the documents
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
}
/**
 * Request message to update information for EnsembleState.
 */
export class UpdateElasticDocumentEnsembleStateRqst {
	/**
	 * The record to upsert in elastic
	 * Required: false
	 * Array: false
	 */
	doc:EnsembleStateSearchRecord = undefined;
	/**
	 * Indicate if an upsert operation should be executed if the document was not found
	 * Required: false
	 * Array: false
	 */
	doc_as_upsert:boolean = undefined;
}
/**
 * Path parameters
 */
export class UpdateElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Response from updateElasticDocument.
 */
export class UpdateElasticDocumentEnsembleStateResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Response from bulkUpdateElasticDocument.
 */
export class BulkUpdateElasticDocumentEnsembleStateResp {
	/**
	 * The time it took to process this request
	 * Required: false
	 * Array: false
	 */
	took:number = undefined;
	/**
	 * Indicates if there are any errors
	 * Required: false
	 * Array: false
	 */
	errors:boolean = undefined;
	/**
	 * Results of the bulk operations
	 * Required: false
	 * Array: true
	 */
	items:BulkApiRespItem[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Request message to delete information for EnsembleState.
 */
export class DeleteElasticDocumentEnsembleStateResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Request message to search information for ensemble-state-unfiltered.
 */
export class SearchEnsembleStateUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Response to the request to search information for ensemble-state-unfiltered.
 */
export class SearchEnsembleStateUnfilteredResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:EnsembleStateSearchRecord[] = undefined;
}
/**
 * Request message to export information for ensemble-state-unfiltered.
 */
export class ExportEnsembleStateUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileType:string = undefined;
	/**
	 * Query for exporting
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterEnsembleStateUnfilteredRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterEnsembleStateUnfilteredResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterEnsembleStateUnfilteredRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedEnsembleStateUnfilteredRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:EnsembleStateSearchRecord[] = undefined;
}
/**
 * 
 */
export class Event {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	subTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventOccurredDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histCurrentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histCurrentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmsDocId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transactionSrcCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reason:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of Event. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class EventSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Event = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Event = undefined;
}
/**
 * 
 */
export class InterfaceFlowPlanDetail {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentOpnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob1Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob1LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob1UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob1MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob1ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac1Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac1LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac1UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac1MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac1ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib1Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib1LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib1UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib1MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib1ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day1Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day1LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day1UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day1MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob2Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob2LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob2UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob2MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob2ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac2Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac2LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac2UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac2MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac2ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib2Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib2LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib2UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib2MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib2ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day2Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day2LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day2UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day2MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob3Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob3LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob3UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob3MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob3ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac3Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac3LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac3UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac3MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac3ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib3Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib3LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib3UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib3MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib3ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day3Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day3LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day3UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day3MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob4Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob4LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob4UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob4MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob4ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac4Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac4LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac4UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac4MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac4ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib4Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib4LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib4UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib4MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib4ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day4Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day4LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day4UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day4MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob5Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob5LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob5UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob5MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob5ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac5Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac5LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac5UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac5MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac5ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib5Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib5LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib5UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib5MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib5ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day5Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day5LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day5UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day5MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob6Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob6LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob6UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob6MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob6ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac6Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac6LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac6UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac6MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac6ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib6Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib6LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib6UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib6MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib6ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day6Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day6LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day6UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day6MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob7Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob7LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob7UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob7MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob7ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac7Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac7LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac7UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac7MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac7ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib7Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib7LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib7UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib7MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib7ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hostedCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob1EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac1EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib1EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day1EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob2EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac2EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib2EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day2EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob3EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac3EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib3EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day3EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob4EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac4EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib4EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day4EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob5EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac5EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib5EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day5EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob6EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac6EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib6EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day6EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob7EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac7EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib7EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day7Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day7LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day7UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day7MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day7EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob8Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob8LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob8UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob8MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob8ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob8EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac8Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac8LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac8UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac8MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac8ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac8EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib8Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib8LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib8UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib8MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib8ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib8EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day8Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day8LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day8UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day8MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day8EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob9Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob9LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob9UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob9MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob9ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob9EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac9Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac9LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac9UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac9MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac9ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac9EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib9Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib9LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib9UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib9MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib9ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib9EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day9Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day9LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day9UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day9MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day9EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob10Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob10LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob10UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob10MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob10ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob10EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac10Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac10LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac10UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac10MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac10ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac10EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib10Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib10LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib10UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib10MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib10ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib10EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day10Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day10LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day10UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day10MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day10EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob11Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob11LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob11UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob11MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob11ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob11EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac11Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac11LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac11UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac11MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac11ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac11EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib11Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib11LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib11UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib11MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib11ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib11EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day11Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day11LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day11UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day11MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day11EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob12Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob12LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob12UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob12MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob12ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob12EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac12Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac12LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac12UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac12MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac12ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac12EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib12Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib12LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib12UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib12MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib12ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib12EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day12Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day12LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day12UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day12MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day12EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob13Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob13LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob13UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob13MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob13ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob13EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac13Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac13LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac13UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac13MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac13ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac13EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib13Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib13LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib13UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib13MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib13ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib13EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day13Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day13LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day13UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day13MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day13EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob14Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob14LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob14UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob14MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob14ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob14EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac14Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac14LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac14UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac14MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac14ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac14EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib14Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib14LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib14UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib14MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib14ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib14EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day14Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day14LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day14UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day14MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	day14EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob15Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob15LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob15UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob15MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob15ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ob15EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac15Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac15LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac15UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac15MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac15ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fac15EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib15Sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib15LoadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib15UnloadFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib15MoveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib15ShuttleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ib15EslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Details about employees that process and approve claims.
 */
export class InterfaceEmployee {
	/**
	 * Employee Id of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * First Name of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Middle Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	middleName:string = undefined;
	/**
	 * Last Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Job Description of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * The domicile SIC location for the employee.
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * The employee id of this employee's supervisor.
	 * Required: false
	 * Array: false
	 */
	supervisorEmployeeId:string = undefined;
	/**
	 * Desc: The business phone number of the employee.  It is sourced from HRS_EMPL_PHONE_VW in DB2 for typ_cd = 'B'.  01/10/2019 Added by Cathy
	 * Required: false
	 * Array: false
	 */
	businessPhoneTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	racfId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrCellPhone:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class BypassMovePathDetail {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift 
	 * 
	 * from which this collection of drivers, equipment and shipments are going to 
	 * 
	 * move on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current 
	 * 
	 * location of the drivers, equipment and freight during the shift it will move 
	 * 
	 * on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The 
	 * 
	 * location to which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * Abbreviated 
	 * 
	 * word representing the type of run. Used in conjunction with the Current Shift. 
	 * 
	 * Values OTB FAC MMT OMT DIR SIT INB DAY
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * Sequence 
	 * 
	 * number denoting the shift sequence.  1 indicates it is the first occurence of 
	 * 
	 * this shift.
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * The 
	 * 
	 * location representing the next load point
	 * Required: false
	 * Array: false
	 */
	loadedToSicCd:string = undefined;
	/**
	 * The shift 
	 * 
	 * code for which the freight is expected to unload at
	 * Required: false
	 * Array: false
	 */
	unloadShiftCd:string = undefined;
	/**
	 * The 
	 * 
	 * original Move To location, according to standard freight flow.  This value will 
	 * 
	 * differ from the parent Move To when a full trailer diversion exists.
	 * Required: false
	 * Array: false
	 */
	originalMoveToSicCd:string = undefined;
	/**
	 * The unique 
	 * 
	 * identifier for the trailer
	 * Required: false
	 * Array: false
	 */
	trailerInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movePathLegSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveLegOriginSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveLegOriginShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveLegDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveLegDestinationShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardFlwInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Each bypass trailer row represents 
 * 
 * a trailer that arrives at this location, is not unloaded during this shift, and 
 * 
 * moves out of this shift
 */
export class BypassTrailer {
	/**
	 * Unique identifier supplied 
	 * 
	 * for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift from which 
	 * 
	 * this collection of drivers, equipment and shipments are going to move on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current location 
	 * 
	 * of the drivers, equipment and freight during the shift it will move on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The location to 
	 * 
	 * which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * Abbreviated word 
	 * 
	 * representing the type of run. Used in conjunction with the Current Shift. 
	 * 
	 * Values OTB FAC MMT OMT DIR SIT INB DAY
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * Sequence number 
	 * 
	 * denoting the shift sequence.  1 indicates it is the first occurence of this 
	 * 
	 * shift.
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * The location 
	 * 
	 * representing the next load point
	 * Required: false
	 * Array: false
	 */
	loadedToSicCd:string = undefined;
	/**
	 * The shift code for 
	 * 
	 * which the freight is expected to unload at
	 * Required: false
	 * Array: false
	 */
	unloadShiftCd:string = undefined;
	/**
	 * The original 
	 * 
	 * Move To location, according to standard freight flow.  This value will differ 
	 * 
	 * from the parent Move To when a full trailer diversion exists.
	 * Required: false
	 * Array: false
	 */
	originalMoveToSicCd:string = undefined;
	/**
	 * The unique 
	 * 
	 * identifier for the trailer
	 * Required: false
	 * Array: false
	 */
	trailerInstId:number = undefined;
	/**
	 * This is the 4 
	 * 
	 * character prefix of the trailer
	 * Required: false
	 * Array: false
	 */
	trailerPrefix:string = undefined;
	/**
	 * This is the six 
	 * 
	 * digit suffix of the trailer
	 * Required: false
	 * Array: false
	 */
	trailerSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerTypeCd:string = undefined;
	/**
	 * The cube of the 
	 * 
	 * trailer contents, as entered during TCON time
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * Total weight of the 
	 * 
	 * shipments loaded on the trailer
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * The location 
	 * 
	 * for which this trailer load was built
	 * Required: false
	 * Array: false
	 */
	tconLoadedDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	orgnlTrailerActnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	optmzTrailerActnCd:string = undefined;
	/**
	 * The location 
	 * 
	 * for which this trailer load is deems to be built for, according to the 
	 * 
	 * Optmizer
	 * Required: false
	 * Array: false
	 */
	optmzLoadedDestinationSicCd:string = undefined;
	/**
	 * The SIC which 
	 * 
	 * owns the plan that this trailer is included in, based on SCO data.  May be 
	 * 
	 * different than the SIC that the Optimizer accounted for, which is the 
	 * 
	 * CURR_SIC_CD on the parent Move Lane Summary.
	 * Required: false
	 * Array: false
	 */
	originalPlanSicCd:string = undefined;
	/**
	 * The location that 
	 * 
	 * loaded the trailer
	 * Required: false
	 * Array: false
	 */
	loadedBySicCd:string = undefined;
	/**
	 * The shift at the 
	 * 
	 * location that loaded the trailer
	 * Required: false
	 * Array: false
	 */
	loadedByShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInstTbdInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	bypassMovePathDetail:BypassMovePathDetail[] = undefined;
}
/**
 * A Driver Summary row represents 
 * 
 * the number of drivers moving in the parent Move Lane Summary
 */
export class DriverSummary {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift from 
	 * 
	 * which this collection of drivers, equipment and shipments are going to move 
	 * 
	 * on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current 
	 * 
	 * location of the drivers, equipment and freight during the shift it will move 
	 * 
	 * on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The location to 
	 * 
	 * which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * Abbreviated word 
	 * 
	 * representing the type of run. Used in conjunction with the Current Shift.  
	 * 
	 * Values OTB FAC MMT OMT DIR SIT INB DAY
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	drvrTypeCd:string = undefined;
	/**
	 * The number of drivers 
	 * 
	 * of this type, moving in this Move Lane
	 * Required: false
	 * Array: false
	 */
	drvrCount:string = undefined;
	/**
	 * Number of drivers that 
	 * 
	 * are expected to drive a tractor pulling two trailers referred to as pup 
	 * 
	 * (set).
	 * Required: false
	 * Array: false
	 */
	setCount:string = undefined;
	/**
	 * Number of drivers 
	 * 
	 * that are expected to drive a tractor pulling three trailers referred to as pup 
	 * 
	 * (triple).
	 * Required: false
	 * Array: false
	 */
	trpleCount:string = undefined;
	/**
	 * Number of drivers that 
	 * 
	 * are expected to drive a tractor pulling two trailers, a pup and a van, referred 
	 * 
	 * to as Rocky Mountains Double (RMD).
	 * Required: false
	 * Array: false
	 */
	rmdCount:string = undefined;
	/**
	 * Number of drivers 
	 * 
	 * that are expected to drive a tractor without any trailers, referred to as a 
	 * 
	 * bobtail.
	 * Required: false
	 * Array: false
	 */
	bobTailCount:string = undefined;
	/**
	 * Number of drivers 
	 * 
	 * that are expected to drive an HSS tractor pulling an HSS van
	 * Required: false
	 * Array: false
	 */
	snglVanCount:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	snglPupCount:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dblTurnCount:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	viaSummary:ViaSummary[] = undefined;
}
/**
 * A Driver Summary row represents 
 * 
 * the number of drivers moving in the parent Move Lane Summary
 */
export class DriverSummaryHist {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift from 
	 * 
	 * which this collection of drivers, equipment and shipments are going to move on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current location 
	 * 
	 * of the drivers, equipment and freight during the shift it will move on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The location to 
	 * 
	 * which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * Abbreviated word 
	 * 
	 * representing the type of run. Used in conjunction with the Current Shift.  Values OTB 
	 * 
	 * FAC MMT OMT DIR SIT INB DAY
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	drvrTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chngEventInstId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlActnCd:string = undefined;
	/**
	 * The number of drivers 
	 * 
	 * of this type, moving in this Move Lane
	 * Required: false
	 * Array: false
	 */
	drvrCount:string = undefined;
	/**
	 * Number of drivers that 
	 * 
	 * are expected to drive a tractor pulling two trailers referred to as pup (set).
	 * Required: false
	 * Array: false
	 */
	setCount:string = undefined;
	/**
	 * Number of drivers that 
	 * 
	 * are expected to drive a tractor pulling three trailers referred to as pup (triple).
	 * Required: false
	 * Array: false
	 */
	trpleCount:string = undefined;
	/**
	 * Number of drivers that 
	 * 
	 * are expected to drive a tractor pulling two trailers, a pup and a van, referred to as 
	 * 
	 * Rocky Mountains Double (RMD).
	 * Required: false
	 * Array: false
	 */
	rmdCount:string = undefined;
	/**
	 * Number of drivers 
	 * 
	 * that are expected to drive a tractor without any trailers, referred to as a bobtail.
	 * Required: false
	 * Array: false
	 */
	bobTailCount:string = undefined;
	/**
	 * Number of drivers 
	 * 
	 * that are expected to drive an HSS tractor pulling a single HSS van
	 * Required: false
	 * Array: false
	 */
	snglVanCount:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	snglPupCount:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dblTurnCount:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LhoEvent {
	/**
	 * Generated sequence; PK.
	 * Required: false
	 * Array: false
	 */
	lhoEventId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lhoTypeCd:string = undefined;
	/**
	 * Unique identifier supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:number = undefined;
	/**
	 * Release Status of the Model Run for this Sic code
	 * Required: false
	 * Array: false
	 */
	releaseStatusCd:string = undefined;
	/**
	 * Sic code where the event occurred
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * The date when the event occurred
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * The shift when the event occurred
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The sic (lane) related to the event.
	 * Required: false
	 * Array: false
	 */
	lhoLane:string = undefined;
	/**
	 * The sic (close to) related to the event.
	 * Required: false
	 * Array: false
	 */
	lhoLoadTo:string = undefined;
	/**
	 * Total number of trailer type pup for this load lane.
	 * Required: false
	 * Array: false
	 */
	totalPupCount:string = undefined;
	/**
	 * Total number of trailer type van for this load lane.
	 * Required: false
	 * Array: false
	 */
	totalVanCount:string = undefined;
	/**
	 * Total number of bypass trailers for this move lane.
	 * Required: false
	 * Array: false
	 */
	totalBypassCount:string = undefined;
	/**
	 * Total number of empty trailers for this move lane.
	 * Required: false
	 * Array: false
	 */
	totalEmptyPupCount:string = undefined;
	/**
	 * Total cube for all loads off the dock and bypass for this load lane. It is expressed as a percentage.
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * Total weight for all loads off the dock and bypass for this load lane. The unit of measure for the weight is in pounds.
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Count of the driver required for linehaul move from the sic.
	 * Required: false
	 * Array: false
	 */
	dsrReqCount:string = undefined;
	/**
	 * Refers back to source table.  Added 9/16/2019
	 * Required: false
	 * Array: false
	 */
	chngEventInstId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of LhoEvent. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class LhoEventSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:LhoEvent = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:LhoEvent = undefined;
}
/**
 * Stores move path information as 
 * 
 * defined by FFO lho_flow_mp.csv.  Added 5/3/2019 as per Triet
 */
export class FlowMovePath {
	/**
	 * The unique numeric ID 
	 * 
	 * code for the move path
	 * Required: false
	 * Array: false
	 */
	mpathInstId:number = undefined;
	/**
	 * This field contains a 
	 * 
	 * number denoting the sequence of the current move path.
	 * 
	 * 
	 * 
	 * Values are:
	 * 
	 * 1 - First occurrence and use for nightly revert
	 * 
	 * 2 or higher - Edited and denote as current move path to replace at cutoff for 
	 * 
	 * model.
	 * Required: false
	 * Array: false
	 */
	mpathSequenceNbr:string = undefined;
	/**
	 * The SIC at which the 
	 * 
	 * corresponding move path originates
	 * Required: false
	 * Array: false
	 */
	loadAtSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadAtShiftCd:string = undefined;
	/**
	 * The SIC at which the 
	 * 
	 * current load leg is scheduled to end
	 * Required: false
	 * Array: false
	 */
	loadToSicCd:string = undefined;
	/**
	 * The shift during 
	 * 
	 * which the current load leg is scheduled to end
	 * Required: false
	 * Array: false
	 */
	loadToShiftCd:string = undefined;
	/**
	 * The assumed current 
	 * 
	 * SIC of any shipment on the corresponding move path
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The assumed current 
	 * 
	 * shift of any shipment on the corresponding move path
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * This is the movement 
	 * 
	 * mode of the move path.
	 * 
	 * Values are:
	 * L - Linehaul movement
	 * S - Exclusive Subservice movement
	 * M - Supplemental Subservice movement
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * Represents the 
	 * 
	 * number of cycles that it takes a shipment following the 
	 * corresponding move path to reach its destination (that is the move path's 
	 * 
	 * destination, 
	 * not the shipment's final destination)
	 * Required: false
	 * Array: false
	 */
	loadToCycleNbr:string = undefined;
	/**
	 * The SIC at which the 
	 * 
	 * current move leg will be completed
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * The shift during 
	 * 
	 * which the current move leg is set to be completed
	 * Required: false
	 * Array: false
	 */
	moveToShiftCd:string = undefined;
	/**
	 * The number of 
	 * 
	 * cycles that will elapse before the current move leg is completed
	 * Required: false
	 * Array: false
	 */
	moveToCycleNbr:string = undefined;
	/**
	 * The distance 
	 * 
	 * (in miles) covered by the corresponding move leg
	 * Required: false
	 * Array: false
	 */
	distanceInMilesNbr:string = undefined;
	/**
	 * Flag indicating 
	 * 
	 * whether or not the corresponding move leg allows triples
	 * Required: false
	 * Array: false
	 */
	tripleAllowedInd:boolean = undefined;
	/**
	 * Flag 
	 * 
	 * indicating whether or not this move leg allows for RMD (Rocky Mountain double) 
	 * pulls, which refer to tractors pulling both a van and a pup simultaneously
	 * Required: false
	 * Array: false
	 */
	rockyMtnDoubleAllowedInd:boolean = undefined;
	/**
	 * Flag 
	 * 
	 * indicating whether or not this move leg is an extended service lane (ESL), 
	 * 
	 * which refers to a
	 * lane that covers a distance which is exceeds what a single DSR can pull in a 
	 * 
	 * day, therefore requiring multiple DSRs to be sent to complete the lane
	 * Required: false
	 * Array: false
	 */
	extendedServiceLaneInd:boolean = undefined;
	/**
	 * Flag indicating whether 
	 * 
	 * or not the corresponding move path is part of a shuttle run
	 * Required: false
	 * Array: false
	 */
	shuttleInd:boolean = undefined;
	/**
	 * The maximum number of 
	 * 
	 * extended service lanes on the move lane
	 * Required: false
	 * Array: false
	 */
	maxEslRunCount:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class InstructionChngEvent {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chngEventInstId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	comment:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentShftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class InstructionChngEventReasonCd {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	instructionChngOption:InstructionChngOption[] = undefined;
}
/**
 * 
 */
export class InstructionChngEventTypeCd {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	instructionChngOption:InstructionChngOption[] = undefined;
}
/**
 * 
 */
export class InstructionChngOption {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Each Lane Diversion row 
 * 
 * represents one shipment diversion instruction
 */
export class LaneDiversion {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The current shift 
	 * 
	 * of the SIC that will divert freight to fulfill this instruction
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current 
	 * 
	 * location of the freight that should be diverted to fulfill this instruction
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * A unique 
	 * 
	 * number to identify the diversion instruction
	 * Required: false
	 * Array: false
	 */
	dvrsnInstructionSequenceNbr:number = undefined;
	/**
	 * The Load 
	 * 
	 * Dest of the freight that will be diverted, if it moved according to standard 
	 * 
	 * freight flow
	 * Required: false
	 * Array: false
	 */
	originalLoadedDestinationSicCd:string = undefined;
	/**
	 * The Unload 
	 * 
	 * shift of the freight that will be diverted, if it moved according to standard 
	 * 
	 * freight flow
	 * Required: false
	 * Array: false
	 */
	originalUnloadShiftCd:string = undefined;
	/**
	 * The 
	 * 
	 * location that the freight would move to next, if it moved according to standard 
	 * 
	 * freight flow
	 * Required: false
	 * Array: false
	 */
	originalMoveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalFreightTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalMoveModeCd:string = undefined;
	/**
	 * The Load 
	 * 
	 * Dest of the freight that will be diverted, if it moved according to the 
	 * 
	 * diversion
	 * Required: false
	 * Array: false
	 */
	dvrsnLoadedDestinationSicCd:string = undefined;
	/**
	 * The Unload 
	 * 
	 * shift of the freight that will be diverted, if it moved according tothe 
	 * 
	 * diversion
	 * Required: false
	 * Array: false
	 */
	dvrsnUnloadShiftCd:string = undefined;
	/**
	 * The 
	 * 
	 * location that the freight would move to next, if it moved according to the 
	 * 
	 * diversion
	 * Required: false
	 * Array: false
	 */
	dvrsnMoveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnFreightTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnMoveModeCd:string = undefined;
	/**
	 * The amount of 
	 * 
	 * shipments to divert, in cube
	 * Required: false
	 * Array: false
	 */
	dvrsnCubePercentage:number = undefined;
	/**
	 * The amount of 
	 * 
	 * shipments to divert, in pounds (weight)
	 * Required: false
	 * Array: false
	 */
	dvrsnWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnTypeCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Each Lane Diversion row 
 * 
 * represents one shipment diversion instruction
 */
export class LaneDiversionHist {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The current shift 
	 * 
	 * of the SIC that will divert freight to fulfill this instruction
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current location 
	 * 
	 * of the freight that should be diverted to fulfill this instruction
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * A unique 
	 * 
	 * number to identify the diversion instruction
	 * Required: false
	 * Array: false
	 */
	dvrsnInstructionSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chngEventInstId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlActnCd:string = undefined;
	/**
	 * The Load 
	 * 
	 * Dest of the freight that will be diverted, if it moved according to standard freight 
	 * 
	 * flow
	 * Required: false
	 * Array: false
	 */
	originalLoadedDestinationSicCd:string = undefined;
	/**
	 * The Unload 
	 * 
	 * shift of the freight that will be diverted, if it moved according to standard freight 
	 * 
	 * flow
	 * Required: false
	 * Array: false
	 */
	originalUnloadShiftCd:string = undefined;
	/**
	 * The 
	 * 
	 * location that the freight would move to next, if it moved according to standard 
	 * 
	 * freight flow
	 * Required: false
	 * Array: false
	 */
	originalMoveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalFreightTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalMoveModeCd:string = undefined;
	/**
	 * The Load 
	 * 
	 * Dest of the freight that will be diverted, if it moved according to the diversion
	 * Required: false
	 * Array: false
	 */
	dvrsnLoadedDestinationSicCd:string = undefined;
	/**
	 * The Unload 
	 * 
	 * shift of the freight that will be diverted, if it moved according tothe diversion
	 * Required: false
	 * Array: false
	 */
	dvrsnUnloadShiftCd:string = undefined;
	/**
	 * The 
	 * 
	 * location that the freight would move to next, if it moved according to the diversion
	 * Required: false
	 * Array: false
	 */
	dvrsnMoveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnFreightTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnMoveModeCd:string = undefined;
	/**
	 * The amount of 
	 * 
	 * shipments to divert, in cube
	 * Required: false
	 * Array: false
	 */
	dvrsnCubePercentage:number = undefined;
	/**
	 * The amount of 
	 * 
	 * shipments to divert, in pounds (weight)
	 * Required: false
	 * Array: false
	 */
	dvrsnWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnTypeCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * A Load Lane Summary row 
 * 
 * represents a lane for which loads are being built.  These loads will move to 
 * 
 * the parent Move Lane.
 */
export class LoadLaneSummary {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift from 
	 * 
	 * which this collection of drivers, equipment and shipments are going to move 
	 * 
	 * on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current 
	 * 
	 * location of the drivers, equipment and freight during the shift it will move 
	 * 
	 * on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The location 
	 * 
	 * to which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * The location 
	 * 
	 * representing the next load point
	 * Required: false
	 * Array: false
	 */
	loadedToSicCd:string = undefined;
	/**
	 * The shift code 
	 * 
	 * for which the freight is expected to unload at
	 * Required: false
	 * Array: false
	 */
	unloadShiftCd:string = undefined;
	/**
	 * The 
	 * 
	 * original Move To location, according to standard freight flow.  This value will 
	 * 
	 * differ from the parent Move To when a full trailer diversion exists.
	 * Required: false
	 * Array: false
	 */
	originalMoveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightTypeCd:string = undefined;
	/**
	 * Total cube for 
	 * 
	 * all loads off the dock for this load lane (whether on dock or already loaded to 
	 * 
	 * a trailer for that lane). It is expressed as a percentage.
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * Total weight 
	 * 
	 * for all loads off the dock for this load lane (whether on dock or already 
	 * 
	 * loaded to a trailer for that lane.) The unit of measure for the weight is in 
	 * 
	 * pounds.
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Total 
	 * 
	 * number of trailer type pup for this load lane.
	 * Required: false
	 * Array: false
	 */
	totalLdsPupCount:string = undefined;
	/**
	 * Total 
	 * 
	 * number of trailer type van for this load lane
	 * Required: false
	 * Array: false
	 */
	totalLdsVanCount:string = undefined;
	/**
	 * For HSS 
	 * 
	 * loads, this represents the date by which the load has to arrive by
	 * Required: false
	 * Array: false
	 */
	hssArrivalByDate:Date = undefined;
	/**
	 * For HSS 
	 * 
	 * loads, this represents the shift by which the load has to arrive by
	 * Required: false
	 * Array: false
	 */
	hssArrivalByShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	loadMovePathDetail:LoadMovePathDetail[] = undefined;
}
/**
 * A Load Lane Summary row 
 * 
 * represents a lane for which loads are being built.  These loads will move to the 
 * 
 * parent Move Lane.
 */
export class LoadLaneSummaryHist {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift from 
	 * 
	 * which this collection of drivers, equipment and shipments are going to move on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current 
	 * 
	 * location of the drivers, equipment and freight during the shift it will move on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The location 
	 * 
	 * to which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * The location 
	 * 
	 * representing the next load point
	 * Required: false
	 * Array: false
	 */
	loadedToSicCd:string = undefined;
	/**
	 * The shift code 
	 * 
	 * for which the freight is expected to unload at
	 * Required: false
	 * Array: false
	 */
	unloadShiftCd:string = undefined;
	/**
	 * The 
	 * 
	 * original Move To location, according to standard freight flow.  This value will differ 
	 * 
	 * from the parent Move To when a full trailer diversion exists.
	 * Required: false
	 * Array: false
	 */
	originalMoveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chngEventInstId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlActnCd:string = undefined;
	/**
	 * Total cube for 
	 * 
	 * all loads off the dock for this load lane (whether on dock or already loaded to a 
	 * 
	 * trailer for that lane). It is expressed as a percentage.
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * Total weight for 
	 * 
	 * all loads off the dock for this load lane (whether on dock or already loaded to a 
	 * 
	 * trailer for that lane.) The unit of measure for the weight is in pounds.
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Total number 
	 * 
	 * of trailer type pup for this load lane.
	 * Required: false
	 * Array: false
	 */
	totalLdsPupCount:string = undefined;
	/**
	 * Total number 
	 * 
	 * of trailer type van for this load lane
	 * Required: false
	 * Array: false
	 */
	totalLdsVanCount:string = undefined;
	/**
	 * For HSS loads, 
	 * 
	 * this represents the date by which the load has to arrive by
	 * Required: false
	 * Array: false
	 */
	hssArrivalByDate:Date = undefined;
	/**
	 * For HSS 
	 * 
	 * loads, this represents the shift by which the load has to arrive by
	 * Required: false
	 * Array: false
	 */
	hssArrivalByShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LoadMovePathDetail {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift 
	 * 
	 * from which this collection of drivers, equipment and shipments are going to 
	 * 
	 * move on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current 
	 * 
	 * location of the drivers, equipment and freight during the shift it will move 
	 * 
	 * on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The 
	 * 
	 * location to which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * The location 
	 * 
	 * representing the next load point
	 * Required: false
	 * Array: false
	 */
	loadedToSicCd:string = undefined;
	/**
	 * The shift 
	 * 
	 * code for which the freight is expected to unload at
	 * Required: false
	 * Array: false
	 */
	unloadShiftCd:string = undefined;
	/**
	 * The 
	 * 
	 * original Move To location, according to standard freight flow.  This value will 
	 * 
	 * differ from the parent Move To when a full trailer diversion exists.
	 * Required: false
	 * Array: false
	 */
	originalMoveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movePathOptSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movePathLegSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveLegOriginSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveLegOriginShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveLegDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveLegDestinationShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardFlwInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalLdsPupCount:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalLdsVanCount:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * To store and process the new load requests from service centers.  Added 01/23/2020
 */
export class LoadRequest {
	/**
	 * Unique identifier
	 * Required: false
	 * Array: false
	 */
	lhoLoadRequestId:number = undefined;
	/**
	 * SIC where Load is being requested
	 * Required: false
	 * Array: false
	 */
	originSicCode:string = undefined;
	/**
	 * SIC where load is headed
	 * Required: false
	 * Array: false
	 */
	moveToSicCode:string = undefined;
	/**
	 * SIC where load is closed to.
	 * Required: false
	 * Array: false
	 */
	loadToSicCode:string = undefined;
	/**
	 * Reason for load request.
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * Number of additional loads requested.
	 * Required: false
	 * Array: false
	 */
	requestQuantity:number = undefined;
	/**
	 * Name of the requestor
	 * Required: false
	 * Array: false
	 */
	requestorName:string = undefined;
	/**
	 * Employee id of the Requestor
	 * Required: false
	 * Array: false
	 */
	requestorEmplid:string = undefined;
	/**
	 * Comments from requestor
	 * Required: false
	 * Array: false
	 */
	requestorComments:string = undefined;
	/**
	 * Number of additional loads approved
	 * Required: false
	 * Array: false
	 */
	approvedQuantity:number = undefined;
	/**
	 * Number of additional loads declined
	 * Required: false
	 * Array: false
	 */
	declinedQuantity:number = undefined;
	/**
	 * Action taken datetime
	 * Required: false
	 * Array: false
	 */
	approveDeclineDateTime:Date = undefined;
	/**
	 * Reason for approved loads
	 * Required: false
	 * Array: false
	 */
	approveReasonCd:string = undefined;
	/**
	 * Reason for declined loads
	 * Required: false
	 * Array: false
	 */
	declineReasonCd:string = undefined;
	/**
	 * Comments from Linehaul Office employee who approved/declined request.
	 * Required: false
	 * Array: false
	 */
	linehaulComments:string = undefined;
	/**
	 * Type of approval.
	 * 
	 * Valid Values:
	 * ALLOWABLE_LOADS : Approve into Allowable Loads
	 * SERVICE_FREIGHT : Approved to Service Freight
	 * Required: false
	 * Array: false
	 */
	approveTypeCd:string = undefined;
	/**
	 * Name of Linehaul Office employee who approved/declined request.
	 * Required: false
	 * Array: false
	 */
	linehaulName:string = undefined;
	/**
	 * Employee id of Linehaul Office employee who approved/declined request.
	 * Required: false
	 * Array: false
	 */
	linehaulEmplid:string = undefined;
	/**
	 * Linehaul region
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * Model plan date
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Current Shift/Input Shift
	 * Required: false
	 * Array: false
	 */
	planShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runId:number = undefined;
	/**
	 * Start of range for which load request is eligible.  Added 04/08/2020
	 * Required: false
	 * Array: false
	 */
	eligibleFromDateTime:Date = undefined;
	/**
	 * End of range for which load request is eligible.  Added 04/08/2020
	 * Required: false
	 * Array: false
	 */
	eligibleToDateTime:Date = undefined;
	/**
	 * This is the status of the Load Request.  When created - status is defaulted to PENDING. Whereupon can only be updated once, to either APPROVED , DECLINED or CANCELLED.
	 * 		added 05/05/2020
	 * Permitted values : 
	 * PENDING
	 * APPROVED
	 * DECLINED
	 * CANCELLED
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The type of request where values can be ADD or CUT.
	 * Required: false
	 * Array: false
	 */
	requestType:string = undefined;
}
/**
 * Reference table for load request reason codes.  Added 01/27/2020
 */
export class LoadRequestReasonCode {
	/**
	 * Identifies one of three types of reason codes.
	 * 
	 * Valid Values:
	 * REASON_CD
	 * APPROVE_REASON_CD
	 * DECLINE_REASON_CD
	 * Required: false
	 * Array: false
	 */
	reasonTypeCd:string = undefined;
	/**
	 * Each REASON TYPE_CD can have one or more actual REASON_CDs. 
	 * 
	 * Valid Values:
	 * 'REASON_TYPE_CD
	 * (VARCHAR2(20))''REASON_CD
	 * (VARCHAR2(20))''REASON_DESC
	 * (VARCHAR2(100))'
	 * REASON_CD                                 ESL                                                 ESL (overweight for an ESL tractor)
	 * REASON_CD                                 HSS_CHANGE_CANCEL                 HSS change/cancel
	 * REASON_CD                                 MARGINAL_CUBE                                 Marginal Cube trailer cut
	 * REASON_CD                                 OP_MAND_MAT                                 Optional/Mandatory Meet and Turn
	 * REASON_CD                                 WEATHER                                 Weather
	 * REASON_CD                                 WEIGHT                                                 Weight (over 20K per trailer for the entire lane)
	 * APPROVE_REASON_CD                 ALLOWABLE_LOADS                 Approve to Allowable Loads
	 * APPROVE_REASON_CD                 SERVICE_FREIGHT                                 Approve to Service Freight
	 * DECLINE_REASON_CD                 NOT_REQUIRED
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * Longer version of Reason that is displayed on screen.
	 * Required: false
	 * Array: false
	 */
	reasonDescription:string = undefined;
	/**
	 * The reason codes need to be displayed in a specific order on the screen, so this faciliates ordering.  Added 06/01/2020
	 * Required: false
	 * Array: false
	 */
	displaySequenceNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * This entity represents each model 
 * 
 * optimizer
 * run for a specific date, shift and model group
 */
export class ModelRun {
	/**
	 * Unique identifier supplied 
	 * 
	 * for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * Collection of SIC codes 
	 * 
	 * under a single label representing SIC locations used in the model optimization. 
	 * 
	 * 	With each model run, the optimizer returns the instructions for the SIC codes 
	 * 
	 * that belong in the model group. Values are CST, PST and EST.
	 * Required: false
	 * Array: false
	 */
	modelGroupCd:ModelGroupCd = undefined;
	/**
	 * Identifier for a day.  This 
	 * 
	 * is the date for which the model optimizer run is executed: for example, a run 
	 * 
	 * date of 01/06/2012 means that the freight optimization is based on the freight, 
	 * 
	 * drivers and equipment availalbe as well as the load plan for 01/06/2012.
	 * Required: false
	 * Array: false
	 */
	runDate:Date = undefined;
	/**
	 * The target shift code 
	 * 
	 * for an optimizer run. Values are F (for FAC) or O (for Outbound).
	 * Required: false
	 * Array: false
	 */
	runShiftCd:RunShiftCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runStep:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	instructionChngEvent:InstructionChngEvent[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	laneDiversion:LaneDiversion[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	moveLaneSummary:MoveLaneSummary[] = undefined;
}
/**
 * 
 */
export class ModelRunReleaseSic {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	releaseStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedByUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	releaseByUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	releaseDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ModelRunStatus {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * this column ahsould 
	 * 
	 * have the same value as that of run_status in LHO_MODEL_RUN table
	 * Required: false
	 * Array: false
	 */
	runStep:string = undefined;
	/**
	 * the step that 
	 * 
	 * was run
	 * Required: false
	 * Array: false
	 */
	stepStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdTimestamp:Date = undefined;
	/**
	 * e.g. 
	 * 
	 * INITIATED, PREPARE INPUT, RUN OPTIMIZER
	 * Required: false
	 * Array: false
	 */
	completionStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * A Move Lane Summary row 
 * 
 * identifies a collection of drivers, equipment and shipments, moving from one 
 * 
 * location to another, out of a specific shift, on a specific move mode and run 
 * 
 * type
 */
export class MoveLaneSummary {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift from 
	 * 
	 * which this collection of drivers, equipment and shipments are going to move 
	 * 
	 * on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current 
	 * 
	 * location of the drivers, equipment and freight during the shift it will move 
	 * 
	 * on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The location 
	 * 
	 * to which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalEmptyPupCount:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	bypassTrailer:BypassTrailer[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	loadLaneSummary:LoadLaneSummary[] = undefined;
}
/**
 * A Move Lane Summary row 
 * 
 * identifies a collection of drivers, equipment and shipments, moving from one location 
 * 
 * to another, out of a specific shift, on a specific move mode and run type
 */
export class MoveLaneSummaryHist {
	/**
	 * Unique identifier 
	 * 
	 * supplied for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift from 
	 * 
	 * which this collection of drivers, equipment and shipments are going to move on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current 
	 * 
	 * location of the drivers, equipment and freight during the shift it will move on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The location 
	 * 
	 * to which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chngEventInstId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlActnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalEmptyPupCount:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Parameter {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parameterScope:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parameterName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parameterDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parameterValue:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creationDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * A Via Summary row represents the 
 * 
 * vias that will be made by the related drivers
 * 
 */
export class ViaSummary {
	/**
	 * Unique identifier supplied 
	 * 
	 * for identifying the model optimizer run.
	 * Required: false
	 * Array: false
	 */
	runId:string = undefined;
	/**
	 * The shift from which 
	 * 
	 * this collection of drivers, equipment and shipments are going to move on
	 * Required: false
	 * Array: false
	 */
	currentShiftCd:string = undefined;
	/**
	 * The current location 
	 * 
	 * of the drivers, equipment and freight during the shift it will move on
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The location to 
	 * 
	 * which the drivers, equipment and shipments will move to
	 * Required: false
	 * Array: false
	 */
	moveToSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	moveModeCd:string = undefined;
	/**
	 * Abbreviated word 
	 * 
	 * representing the type of run. Used in conjunction with the Current Shift.  
	 * 
	 * Values OTB FAC MMT OMT DIR SIT INB DAY
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	drvrTypeCd:string = undefined;
	/**
	 * The location which 
	 * 
	 * these drivers are going to via
	 * Required: false
	 * Array: false
	 */
	viaSicCd:string = undefined;
	/**
	 * Total number of 
	 * 
	 * loaded (non-empty) trailers being dropped by these drivers at this Via 
	 * 
	 * location
	 * Required: false
	 * Array: false
	 */
	totalDrpCount:string = undefined;
	/**
	 * Total number of 
	 * 
	 * loaded (non-empty) trailers being picked up by these drivers at this Via 
	 * 
	 * location
	 * Required: false
	 * Array: false
	 */
	totalPickCount:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateUser:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DeferredLane {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transitDaysNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Equipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeDetailCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleSequence:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvnt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvntDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnScacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnReturnSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRespSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlCntrNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mfrYr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmclSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	wrkRequestInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pmDueDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inServiceDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safeStackInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeToRsiInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	extdSlprLaneInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedAvailableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatusQlfrCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class EquipmentHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeDetailCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleSequence:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnScacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnReturnSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRespSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlCntrNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mfrYr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	wrkRequestInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pmDueDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inServiceDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safeStackInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeToRsiInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	extdSlprLaneInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedAvailableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatusQlfrCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HeadLoadDetail {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lseOrSkdCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cbePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cbeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nbrOfFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
}
/**
 * A Bypass trailer, one which was closed and load destination SICs are not the current SIC. This record is used to allow trailer to be assigned to a door and placed in LDDK status (bump andFILL) at the current SIC and not count against the model instructed load count for the lane.  Added 05/27/2020
 */
export class LnhBypassTrailer {
	/**
	 * This is the surrogate id for equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * OPS_EQUIPMENT.CURR_SIC  on the CLOS or ARIV Event
	 * Required: false
	 * Array: false
	 */
	currentSicCode:string = undefined;
	/**
	 * SCO Plan Date (based on shift time ranges)
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * This is the 4 character prefix of the equipment id. (eg. 0415 or SWFT etc)
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * This is the six digit equipment id suffix. (eg. 123456 or 37 or 3737).
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * Set to CLOS or OVHD. (Could store ORIG_SIC instead to determine if domicile or non-domicile bypass)
	 * Required: false
	 * Array: false
	 */
	trailerStatusCd:string = undefined;
	/**
	 * SIC code of where the trailer originated
	 * Required: false
	 * Array: false
	 */
	trailerOriginSicCode:string = undefined;
	/**
	 * The location for which this trailer load was built
	 * Required: false
	 * Array: false
	 */
	trailerLoadedDestinationSicCode:string = undefined;
	/**
	 * Derived from FLO table for the current SIC to the trailer load destination, when moving on FAC
	 * Required: false
	 * Array: false
	 */
	facMoveToSicCode:string = undefined;
	/**
	 * Derived from FLO table for the current SIC to the trailer load destination, when moving on OB
	 * Required: false
	 * Array: false
	 */
	obMoveToSicCode:string = undefined;
	/**
	 * Same as CRTE_TMST
	 * Required: false
	 * Array: false
	 */
	startDateTime:Date = undefined;
	/**
	 * CRTE_TMST + 18 HRS
	 * Required: false
	 * Array: false
	 */
	endDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LoadedEquipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadEquipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LoadedShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dckLrgMarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn1Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn2Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn3Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn4Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn5Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn6Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	misloadInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class OnlineReportEquipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequence:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class OnlineReportParm {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parm:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequence:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class OnlineReportQue {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	completedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportParms:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lineCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ProMovementHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedAtSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	misloadInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	misloadAuthrzUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventShiftCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of ProMovementHist. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ProMovementHistSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:ProMovementHist = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:ProMovementHist = undefined;
}
/**
 * 
 */
export class Schedule {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	milg:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finalDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	etaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	intmdlScac:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	discrInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	onDfrdLegInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ScheduleHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	milg:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finalDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	etaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	intmdlScac:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	discrInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	drvrId1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	drvrId2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	onDfrdLegInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ScheduledDriver {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
}
/**
 * 
 */
export class TrailerLoad {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evntDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvnt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvntDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLengthFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDestination:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xclvInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closeDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frzblInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	placardInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	garntServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityRoute:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	brkOrProprCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDeckCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dfrdShipmentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDfrdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealMatchCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	poisonShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evntDoorSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evntShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInspReqdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedLoadedDestination:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routingRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideRvwRequiredInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	loadedShipment:LoadedShipment[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadDetail:HeadLoadDetail = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	trailerRouting:TrailerRouting[] = undefined;
}
/**
 * 
 */
export class TrailerRouting {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerActionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	viaInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unloadPtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadPtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	midnightsNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	omtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	processedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class TrailerLoadHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLengthFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xclvInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closeDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frzblInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	placardInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityRoute:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	brkOrProprCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDeckCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dfrdShipmentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDfrdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealMatchCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	poisonShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoorSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInspReqdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedLoadedDestination:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routingRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideRvwRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DoorAssignment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	assignmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	owningSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cutoffTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDoorName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	facCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	secondFacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thirdFacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priorityNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sectorNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceProfileInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceDoorDisplaySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorPlanFacCrossReferenceCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceFacSectorNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	owningSicTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mustMoveSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dayFreightInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ScoEvent {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evtInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	subTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupReqInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sendToDeviceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	event:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serverAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hookInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	applicationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeActivitySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activitySpfcNodeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activitySpfcNodeTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activitySpfcName:string = undefined;
	/**
	 * Unique ID assigned to Route.  
	 * 
	 * Added 7/2/2019
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * Same value for CORRELATION id is 
	 * assigned to various rows from different tables that form a single business trans
	 * actions. In other words, this groups multiple rows across various tables for a s
	 * ingle business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of ScoEvent. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ScoEventSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:ScoEvent = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:ScoEvent = undefined;
}
/**
 * This table will be used for capturing SCO Metrics associated with both current metrics 
 * 
 * and snapshots.  An example is Trip Level metrics.  Added 07/12/2019
 */
export class Metric {
	/**
	 * Unique identifier
	 * Required: false
	 * Array: false
	 */
	metricId:number = undefined;
	/**
	 * The stage of operations for which this metric snapshot was taken. E.g. for Pnd, the stages are: Planning, Dispatched, Dock
	 * Required: false
	 * Array: false
	 */
	stage:MetricStageCd = undefined;
	/**
	 * The specific activity performed in a stage for which this metric was created. E.g. for Pnd, Released or Sequence could be a sub stage during the Planning stage.
	 * Required: false
	 * Array: false
	 */
	subStage:MetricStageSubCd = undefined;
	/**
	 * Service Center Code that owns the Route or Trip, etc.
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * Entity associated with the metric (e.g. Route, Trip, Service Center)
	 * Required: false
	 * Array: false
	 */
	typeCd:MetricTypeCd = undefined;
	/**
	 * Actual Metric Name (e.g. CWT, PPMH) an enumerated value.
	 * Required: false
	 * Array: false
	 */
	valueKeyCd:MetricValueKeyCd = undefined;
	/**
	 * Goals from external source.
	 * Required: false
	 * Array: false
	 */
	goal:number = undefined;
	/**
	 * The variance between actual goal.
	 * 
	 * Valid Values
	 * NEUTRAL
	 * ABOVE 
	 * BELOW
	 * Required: false
	 * Array: false
	 */
	variance:MetricVarianceCd = undefined;
	/**
	 * A description describing how the metric varies from the goal.
	 * Required: false
	 * Array: false
	 */
	varianceDesc:string = undefined;
	/**
	 * A description of the metric value. E.g. Delivery Weight per Trip, Delivery PPMH
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * The actual value in decimal form
	 * Required: false
	 * Array: false
	 */
	value:number = undefined;
	/**
	 * Date for which the plan is active. (added for LH 08/02/2019)
	 * Required: false
	 * Array: false
	 */
	planDate:Date = undefined;
	/**
	 * Depending on the Metric, this could be Dest SIC, Next SIC, FAC SIC, etc. (added for LH 08/02/2019)
	 * Required: false
	 * Array: false
	 */
	targetSicCode:string = undefined;
	/**
	 * A Metric may be at the Region level. (added for LH 08/02/2019)
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * Shift Code this Metric applies to (added for LH 08/02/2019)
	 * 
	 * Valid values are 
	 * 
	 * D = Day FAC 
	 * I = Inbound 
	 * N = Night FAC 
	 * O = Outbound 
	 * P or blank = Pand D
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The formatted string representing the metric value, derived by API.  Added 09/11/2019
	 * Required: false
	 * Array: false
	 */
	displayValue:string = undefined;
	/**
	 * Code that assign a value judgement to the variance e.g, GOOD, BAD or NEUTRAL.  Added 03/30/2020
	 * Required: false
	 * Array: false
	 */
	varianceAlignmentCd:string = undefined;
	/**
	 * RUN_NBR from PnD Optimizer.  Added 05/15/2020
	 * Required: false
	 * Array: false
	 */
	pdoRunNbr:number = undefined;
	/**
	 * SEQ_NBR from PnD Optimizer (could be from one of multiple tables).  Added 05/15/2020
	 * Required: false
	 * Array: false
	 */
	pdoRunSequenceNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An integer representing the order that this metric should appear in the list of metrics.  While this attribute is used internally for
	 * Required: false
	 * Array: false
	 */
	priority:number = undefined;
	/**
	 * The result of the difference between the previous and the new metric value.
	 * Required: false
	 * Array: false
	 */
	deltaValue:string = undefined;
	/**
	 * The difference between the previous and the new metric value expressed as a percentage.
	 * Required: false
	 * Array: false
	 */
	deltaPercentage:number = undefined;
	/**
	 * The type of variance: good, bad or neutral.
	 * Required: false
	 * Array: false
	 */
	deltaVariance:DeltaVarianceTypeCd = undefined;
	/**
	 * Indicates the sign of the delta.
	 * Required: false
	 * Array: false
	 */
	deltaSign:DeltaSignCd = undefined;
}
/**
 * 
 */
export class PlanningEvent {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evtInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Same value for CORRELATI
	 * ON id is assigned to various rows from different tables that form a single busin
	 * ess transactions. In other words, this groups multiple rows across various table
	 * s for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is
	 * 	assigned to various rows from different tables that form a single database tran
	 * sactions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PlanningEventTrailer {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evtInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInclFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reviewRequiredFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedLoadDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Same value for CORR
	 * ELATION id is assigned to various rows from different tables that form a single 
	 * business transactions. In other words, this groups multiple rows across various 
	 * tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event 
	 * id is assigned to various rows from different tables that form a single database
	 * 	transactions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PlanningTrailer {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	etaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	endDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nextFacSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleOriginSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCubeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadOriginSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billCountNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frzblInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDfrdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr1EmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr1Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr2EmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr2Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedCbePrfCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedNbrOfFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedDoorSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedSectorId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedYardCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleViaSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrBrkOrPropCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrExcludeTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrManualInclInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrOverrideEtaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrPlanAccptInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrDoorAssignmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bestDoorSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bestDoorStepsNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	assignmentDoorStepsNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inclHdldProsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrLoadedDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrTmanRvwInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrDoorReqInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrPupTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInclFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reviewRequiredFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	properInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerActnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mustMoveSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedBrkInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nextOutboundSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	partialTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	partialTrailerManualOverrideInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for CORRELATIO
	 * N id is assigned to various rows from different tables that form a single busine
	 * ss transactions. In other words, this groups multiple rows across various tables
	 * 	for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is 
	 * assigned to various rows from different tables that form a single database trans
	 * actions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	thruShipment:ThruShipment[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	xdockShipment:XdockShipment[] = undefined;
}
/**
 * 
 */
export class PlanningTrailerHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	etaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	endDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCubeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nextFacSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleOriginSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadOriginSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billCountNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frzblInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDfrdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr1EmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr1Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr2EmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr2Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedCbePrfCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedNbrOfFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedDoorSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedSectorId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedYardCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleViaSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrBrkOrPropCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrExcludeTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrManualInclInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrOverrideEtaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrPlanAccptInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrDoorAssignmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bestDoorSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bestDoorStepsNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	assignmentDoorStepsNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inclHdldProsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrLoadedDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrTmanRvwInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrDoorReqInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrPupTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInclFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reviewRequiredFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	properInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerActnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mustMoveSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceLastUpdateTransactionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceLastUpdateUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceDeleteBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedBrkInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nextOutboundSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	partialTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	partialTrailerManualOverrideInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
}
/**
 * 
 */
export class ThruShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdServiceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ThruShipmentHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdServiceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceLastUpdateTransactionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceLastUpdateBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceDeleteBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
}
/**
 * 
 */
export class XdockPlan {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	driverCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoInclReshipFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	planningTrailer:PlanningTrailer[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	xdockShipment:XdockShipment[] = undefined;
}
/**
 * 
 */
export class XdockShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockPlanInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockShipmentSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockProgressCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInclInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMmCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ttlPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ttlWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	divertSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnNextMoveSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnLoadToShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnHssCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnDayInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnInstructionSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hssScheduledDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hssLoadedGroupSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class XdockShipmentHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockPlanInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockShipmentSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerPlanInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInclInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMmCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ttlPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ttlWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockProgressCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceLastUpdateTransactionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceLastUpdateBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	divertSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceCreatedTransactionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceCreatedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnNextMoveSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnLoadToShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnHssCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnDayInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnInstructionSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hssScheduledDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hssLoadedGroupSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceDeleteBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
}
/**
 * Table captures comments by a Customer care person managing key accounts regarding reason and resolution of movement exceptions. This information is sent proactively to the customer. The goal is to keep customer happy.  
 * 10/24/2019 This table now stores remarks for Shipments as well as Movement Exceptions.  PK has to be changed.
 */
export class ManagementRemark {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementExceptionSequenceNbr:number = undefined;
	/**
	 * Remark entered by the customer care person, describing the exception. This is typically shared with the customer.
	 * Required: false
	 * Array: false
	 */
	remark:string = undefined;
	/**
	 * Remarks in this table will be of type:
	 * Customer Service Remarks
	 * Exception Remarks
	 * 11/14/2019 Renamed from REASON_CD to TYPE_CD
	 * Required: false
	 * Array: false
	 */
	typeCd:ShipmentManagementRemarkTypeCd = undefined;
	/**
	 * Y- default. The data is shown to the external customer. N- These comments are for XPO internal documentation and will not be shown to the customer.
	 * Required: false
	 * Array: false
	 */
	showToCustomerInd:boolean = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class Movement {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementReportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rshpCreditedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	obcPickupDlvRouteCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	qlfrReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerLocationArrivalDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	movementException:MovementException[] = undefined;
}
/**
 * Holds a snapshot of Movement. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class MovementSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Movement = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Movement = undefined;
}
/**
 * 
 */
export class MovementException {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:MovementExceptionTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rfsdReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	undlvdReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmgdCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exceptionSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exceptionReportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	movementExceptionAction:MovementExceptionAction[] = undefined;
}
/**
 * Holds a snapshot of MovementException. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class MovementExceptionSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:MovementException = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:MovementException = undefined;
}
/**
 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.  Added 01/02/2020
 */
export class MovementExceptionAction {
	/**
	 * Part of unique identifier
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Part of unique identifier
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * Part of unique identifier
	 * Required: false
	 * Array: false
	 */
	movementExceptionSequenceNbr:number = undefined;
	/**
	 * Uniquely identifies a row
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * Responsible sic could be determined to be different from what SHM_movement has recorded
	 * Required: false
	 * Array: false
	 */
	responsibleSicCd:string = undefined;
	/**
	 * Describes what corrective action XPO has taken to address the exception. This is typically shared with the customer.
	 * Required: false
	 * Array: false
	 */
	correctiveAction:string = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Indicates whether the carrier was in a position to control or prevent the exception.  Added 03/23/2020 LISG-4722
	 * Required: false
	 * Array: false
	 */
	controlInd:boolean = undefined;
}
/**
 * Holds a snapshot of MovementExceptionAction. Contains the details at the time on an event. It also provides the previous values if an update occured
 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.  Added 01/02/2020
 */
export class MovementExceptionActionSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:MovementExceptionAction = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:MovementExceptionAction = undefined;
}
/**
 * 
 */
export class Remark {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name :  Type code
	 * 
	 * Desc : This code can be used to categorize the remarks.  
	 * 
	 * Valid Values : 
	 * 1 - Adhoc Hazardous Material Remarks
	 * 2 - Shipping Remarks
	 * 3 - Authority Line text Remarks
	 * 4 - Delivery Attachment Remarks
	 * 5 - Operational Freight Handling Remarks
	 * 6 - Final Delivery Date Correction Remarks
	 * 
	 * Source: New/Entered
	 * 
	 * Design Considerations : This can be used for subsetting purposes and for programatically pulling certain remarks to display and/or print.
	 * Required: false
	 * Array: false
	 */
	typeCd:ShipmentRemarkTypeCd = undefined;
	/**
	 * Expanded Name: Remark Text
	 * 
	 * Desc: Remark Text
	 * Required: false
	 * Array: false
	 */
	remark:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of Remark. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class RemarkSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Remark = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Remark = undefined;
}
/**
 * 
 */
export class Shipment {
	/**
	 * Expanded Name : Instance identifier
	 * 
	 * Desc : System generated unique identifier (as per CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code 
	 * 
	 * Desc : Identifies the type of shipment.
	 * 
	 * 
	 * Valid Values:
	 * A = Normal Movement(NORM)
	 * B = Master Shipment(MSTR) 
	 * C = Segment of a Master shipment (MSEG)
	 * D = Revenue Bill for Split segments (PTLT)
	 * E = Partial Shipment Segment (PSEG)
	 * G = Claims overage Reporting Bill (OREP)
	 * H = Genral Claims Business Shipment (GCBZ)
	 * I =  Astray Freight Segment (MOVR)
	 * J = Company Business Shipment (COBZ) 
	 * K = Expedite
	 * 
	 * 
	 * Note:
	 * Overage Reporting Bill (OREP) is entered into the system just  to indicate to the claims system there is an overage shipment on dock. 
	 * 
	 * Astray freight(MOVR) is used to move freight to it s correct destination after it accidentally ended up somewhere else and its original PRO number is known.
	 * Required: false
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * 
	 * The numbers are printed on labels for each service center.  Labels are put on the Bill-Of-Lading and on the individual pieces of freight for identification.
	 * 
	 * Internal PRO number format is Oppp-sssss-c where 'ppp' is the pro-prefix, 'ssss' is the pro-suffix and 'c' is the check digit (modulus 7). It is usually displayed in the format 'ppp-sssss-c'
	 * 
	 * An expedited shipment may not always have a real PRO number. It may be given a dummy number in the same format and should not be written out to MBF.
	 * 
	 * MBF: root MBPRO
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Bill Status Code
	 * 
	 * Desc : Current status of the Shipment from billing point of view. 
	 * 
	 * Valid Values:
	 * 1 - Unbilled 
	 * 2 - In FBES Suspense Queue
	 * 3 - Billed
	 * 4 - Rated
	 * 5 - Paid
	 * 6 - Cancelled
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	billStatusCd:BillStatusCd = undefined;
	/**
	 * Expanded Name :  Charge to code
	 * 
	 * Desc : Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * 
	 * Valid Values : 
	 * 	P    -  Prepaid - shipper responsible for all charges
	 * 	C    -  Collect - consignee responsible for all charges
	 * 	B    -  Both - shipper and consignee responsible 
	 * 								-  Valid only for Non-Revenue bills.
	 * 			
	 * Source: MBF : MBPRTPPD, MBPRTCOL
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Expanded Name : Original terminal SIC
	 * 
	 * Desc : SIC where the Shipment originates.  Usually associated with PRO prefix and can be overriden.
	 * 
	 * Source : MBF: root MBORGIN
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Destination terminal SIC
	 * 
	 * Desc : SIC that is scheduled to deliver the Shipment. 
	 * 
	 * Source : MBF: root MBDEST
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Current SIC
	 * 
	 * Desc : SIC where the Shipment currently is.  
	 * 
	 * This is a denormalization to avoid scanning all movements to find current location.
	 * 
	 * Source : MBF: root MBLOCX
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * 
	 * Desc : Indicates the source or origination of the Pro.  This code is needed for ACOR processing to determine whether to roll back to the initial data.
	 * 
	 * Valid Values:
	 * 	1  - Web        (Pickup Request web page)
	 * 	2  - EDI        (set 204 or set 211)    
	 * 	3  - FTP        (file transfer)
	 * 	4  - FBES    (manual Bill Entry)
	 * 	5  - TCON or LOAD    (i.e. skeleton TCON or non-TCON LOAD)
	 * 	6  - Pickup Request
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	sourceCd:ShipmentSourceCd = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Earliest available date that Shipment can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Expanded Name : Ready Time                        
	 * 																																																		
	 * Desc : Earliest available time that freight can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	readyTime:string = undefined;
	/**
	 * Expanded Name : Close Time                      
	 * 																																																
	 * Desc : Latest available time that freight can be
	 * pickup up from a customer.                      
	 * 																																																
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	closeTime:string = undefined;
	/**
	 * Expanded Name : Total pieces count
	 * 
	 * Desc : Total pieces from all commodities in the shipment.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTPCS
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * Exapnded Name : Motorized piece count
	 * 
	 * Desc : Identifies the number of moves or lifts made with a forklift or a pallet jack to move freight without manual intervention by the driver. This count may be equal to or less than the number of pieces in the shipment.  This is used by the costing system.  
	 * 
	 * Source : MBF root -  MBMTRPCS
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * Expanded Name : Total weight (in pounds)
	 * 
	 * Desc : Total weight of all the freight being moved as a unit.  If this is a  Mother  bill then this is the total of all of the  Daughter  bills. 
	 * 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTWT
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Expanded Name : Default Tariff
	 * 
	 * Desc : This identifies the default qualifying tariff for the shipment. If the rating process does not identify any other tariffs for the customer from rate aids, this is used as the rating tariff. 
	 * 	
	 * Source : MBF root - MBTARIFF
	 * Required: false
	 * Array: false
	 */
	defaultTariffsId:string = undefined;
	/**
	 * Expanded Name : Total charges
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * Required: false
	 * Array: false
	 */
	totalChargeAmount:number = undefined;
	/**
	 * Expanded Name : Total charges in US dollars
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount in US dollars.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * 
	 * Design Notes: This field was added as a denorm to keep the total charges in USD. Normally this field is same as the tot charges field. However this field is differnt when the rating currncy is not USD.
	 * Required: false
	 * Array: false
	 */
	totalUsdAmount:number = undefined;
	/**
	 * Expanded Name: Split Indicator
	 * 
	 * Desc: Indicates whether the shipment has been split or not. 
	 * 
	 * Valid values: Y/N
	 * 
	 * Source: MBF MBCLAS2 -  This actually replaces the BILL CLASS 2 field in the MBF.
	 * Required: false
	 * Array: false
	 */
	splitInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous Materials indicator
	 * 	
	 * Desc : Indicates whether or not a shipment includes  any commodities with hazardous materials. It is a denormalized attribute for performance puposes. 
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * 
	 * Ideally, it should be specified at the commodity level, but has been de-normalized for Shipment, since that is level at which it is currently captured
	 * 
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * 
	 * Y - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Signature service indicator
	 * 
	 * Desc : Indicates if anyone handling the freight is required to sign on a signature sheet. 
	 * 
	 * Source : MBF: root: MBSGSRVC (x 04  of MBFLAG9)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	signatureServiceInd:boolean = undefined;
	/**
	 * Expanded Name : Revenue Bill Indicator
	 * 
	 * Desc : Indicates whether the shipment will generate revenue for Con-way or not.
	 * 
	 * Valid Values : 
	 * 
	 * Y - generates revenue
	 * N - does not generate revenue
	 * Required: false
	 * Array: false
	 */
	revenueBillInd:boolean = undefined;
	/**
	 * Expanded Name : Manual rating indicator 
	 * 
	 * Desc : Specifies whether the shipment  in question needs to be manually rated or not. If required, it may be changed to a code indicating the reason as well for manually rating it. 
	 * 
	 * It is same as special instructions field on the FBES screen.
	 * 
	 * ==NOTE==
	 * When this indicator is set, it means the rates and charges must be manully reviewed before the bill is marked as RATED. Howerver the auto rating system is allowed to put rates and charges on the bill prior to putting it in a manual entry or manual audit queue.
	 * 
	 * This is different from the indicator AUTO_RATEABLE_IND which indicates whether the auto rating system is even allowed to put rates and charges on the bill.
	 * 
	 * Source : MBF: root: MBSCHDI (x 80  of MBFLAGB)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	manualRatingRequiredInd:boolean = undefined;
	/**
	 * Expanded Name : Audit Required Indicator
	 * 																		
	 * Desc : Used to determine if the pro requires auditing after rating. 
	 * 																	
	 * Valid Values :
	 * Y - Pro does require auditing
	 * N - Pro does not require auditing
	 * 					
	 * Source : MBF:  Root: MBFBAUDT (X 01  of MBFLAGI)
	 * Required: false
	 * Array: false
	 */
	auditInd:boolean = undefined;
	/**
	 * Expanded Name : Cash indicator
	 * 
	 * Desc : This indicates if cash was collected at the tim eof pick up or should be collected at the time of delivery.
	 * 
	 * This information is also printed on the delivery set to inform the driver to collect cash.
	 * 
	 * Source : MBF: root: MBCASH (x 10  of MBFLAG2)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	cashInd:boolean = undefined;
	/**
	 * Expanded Name : Cash collected code                    
	 * 																																																							
	 * Desc : A code indicating whether any cash was          
	 * collected at the time of pick up or delivery or not. Cash may be collected  regardless of whether it was required or not and if  so, the invoice may need to be stopped.                
	 * 																																																							
	 * It has been modelled as a code instead of an indicator 
	 * since it needs to be initialized to a value other than 
	 * NO.                                                    
	 * 																																																							
	 * Source : New                                           
	 * 																																																							
	 * Valid Values :                                         
	 * blank - Initial value                                  
	 * Y - Yes, cash was collected                            
	 * N - No cash collected on delivery
	 * Required: false
	 * Array: false
	 */
	cashCollectInd:boolean = undefined;
	/**
	 * Expanded Name : Government Bill of Lading indicator
	 * 
	 * Desc : Indicates whether this is a government shipment or not.
	 * 
	 * Source : MBF: root: MBGBLT (x 01  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	govtBolTrafficInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Indicator
	 * 
	 * Desc : Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * 
	 * Values : Y/N
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Coupon Indicator
	 * 
	 * Desc : Indicates that a Coupon has been used to allow the shipment to participate in the Con-Way Guaranteed Service Program for free.
	 * 
	 * Values : Y/N
	 * 
	 * Source : BASE
	 * Required: false
	 * Array: false
	 */
	guaranteedCouponInd:boolean = undefined;
	/**
	 * Expanded Name : Purge blocked indicator
	 * 
	 * Desc : Indicates that the details cannot be purged. Usually set when there are inconsistencies for Master / MSEG or Part lot/PSEG  shipments. 
	 * 
	 * Valid values : Y/N
	 * 
	 * Source : MBF: root: MBPUBLK (x 10  of MBFLAG3)
	 * Required: false
	 * Array: false
	 */
	purgeBlockInd:boolean = undefined;
	/**
	 * Expanded Name: Last MOVR PRO Number 
	 * 
	 * Desc: The Pro number for the last MOVR PRO billed for this shipment.
	 * Required: false
	 * Array: false
	 */
	lastMoverPro:string = undefined;
	/**
	 * Expanded Name: Archive Indicator
	 * 
	 * Desc: Indicates whether the record for the PRO is an active one or one retrieved from the archive.
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Expanded Name: COD Indicator
	 * 
	 * Desc: Indictaes whether it is a COD shipment or not. It is a denormalized attribute for performance puposes. The actual COD information is in the MISC_LINE_ITEM entity.
	 * Required: false
	 * Array: false
	 */
	codInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Code 
	 * 
	 * Desc: This is the discount code that was applied to the shipment when it was rated. 
	 * 
	 * Source: MBF - MBRCCODE
	 * Required: false
	 * Array: false
	 */
	discountCd:string = undefined;
	/**
	 * Expanded Name :Movement Reporting SIC Code
	 * 
	 * Desc : SIC where the last movement event for the shipment  happened and reported.  This is a denormalized attribute from the MOVEMENT entity for performance purposes.
	 * 
	 * Source : MBF: root MBMOVE
	 * Required: false
	 * Array: false
	 */
	lastMoveRptgSicCd:string = undefined;
	/**
	 * Expanded Name : Movement Timestamp
	 * 
	 * Desc : Date and time, when the last movement for this shipment  occurred. This is a denormalized attribute from the MOVEMENT entity. 
	 * 
	 * Source: MBF - MBMVTM
	 * Required: false
	 * Array: false
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * Expanded Name : Movement status
	 * 
	 * Desc : The status of the latest movement for a Shipment from an operations point-of-view. This is a denormalized atribute from the MOVEMENT entity.
	 * 
	 * Source: MBF
	 * Name: Various
	 * 
	 * Valid Values : 
	 * Spaces - Not applicable
	 * 1 - On dock
	 * 2 - On trailer
	 * 3 - Out for delivery
	 * 4-  Interim Delivery
	 * 5 - Final Delivered
	 * 6 - Not Applicable
	 * 6 - No movements allowed
	 * 7 - Cancelled
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * Expanded Name : Delivery qualifier
	 * 
	 * Desc : Code representing results of a specific delivery activity. The values are the same as what used to be  Event that stopped service).
	 * 
	 * This has been denormalized from Movement for performance reasons and should always be kept in sync. 
	 * 
	 * Source : MBF: root: MBD1FLG / MBD2FLG
	 * 
	 * Valid Values :
	 * A= Over Shipment
	 * B=Trapped shipment/MBD1TRAP
	 * C=Hold for customs/MBD1CSTM
	 * D=Undelivered/MBD1UNDL
	 * E=Hold for appointment/MBD1APPT
	 * F=On spotted trailer/MBD1SPOT
	 * G=Cartage
	 * H=Attempted delivery/MBD1ATMP
	 * J=All short
	 * K=Partial short/MBD1PSRT
	 * L=Refused delivery/MBD1RFSD
	 * M=Damaged shipment/MBD1RDMG
	 * N=Suspense/MBD1SUSP     - Not Used
	 * O=Wait/MBD1WAIT              - Not Used
	 * P=Purge Blocked/MBD2PBLK
	 * R=Return Status/MBD2RTRN
	 * T=Transfer/MBD2TSFR
	 * Z=Final delivery/MBD1FINL
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * Expanded Name : Rating Currency Code
	 * 
	 * Desc: The rates and charges for the shipment were calculated using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	ratingCurrencyCd:RatingCurrencyCd = undefined;
	/**
	 * Expanded Name : Invoicing Currency Code
	 * 
	 * Desc:  PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * The total charges for the shipment should be displayed using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	invoicingCurrencyCd:InvoiceCurrencyCd = undefined;
	/**
	 * Expanded Name: Currency Converstion Factor
	 * 
	 * Desc: PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * Provides the currency conversion factor on the day of shipment pick up between the rating currency and invoicing currency, if they are different.
	 * Required: false
	 * Array: false
	 */
	currencyConversionFctr:number = undefined;
	/**
	 * Expanded Name : Required Delivery Date
	 * 
	 * Desc : Date the shipment is required to be delivered
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	requiredDeliveryDate:string = undefined;
	/**
	 * Expanded Name : Delivery Time Start (if any)
	 * 
	 * Desc : The start time of the window specified by the customer for delivering the shipment.
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	startDeliveryTime:string = undefined;
	/**
	 * Expanded Name : Delivery Time End (if any)
	 * 
	 * Desc : The end time of the window specified by the customer for delivering the shipment.
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	endDeliveryTime:string = undefined;
	/**
	 * Expanded Name : International manifest from (port) code
	 * 
	 * Desc : Generated by rating. Identifies port of entry code through which freight will enter the US from another country. This may be associated with the destination city or the customer s broker.
	 * 
	 * Source : MBF: root: MBFROMPT
	 * Required: false
	 * Array: false
	 */
	fromPortCd:string = undefined;
	/**
	 * Expanded Name : International  manifest to (port) code
	 * 
	 * Desc : Generated by rating: Identifies the port of entry through which freight will enter another country.  This is either associated with the destination or with the customer s broker.
	 * 
	 * Source : MBF: root: MBTOPORT
	 * Required: false
	 * Array: false
	 */
	toPortCd:string = undefined;
	/**
	 * Expanded Name : OBC pickup delivery route
	 * 
	 * Desc : Pickup delivery route code from CAD used for sorting to print delivery sets in this order.
	 * 
	 * Source : MBF: root: MBPDRTE
	 * Required: false
	 * Array: false
	 */
	obcPickupDlvRouteCd:string = undefined;
	/**
	 * Expanded Name : Customer Profile Instance Id
	 * 
	 * Desc : A unique, randomly-generated number that identifies a registered customer in the Con-Way Customer Profile Component.
	 * 
	 * Source : Customer Profile Component
	 * Required: false
	 * Array: false
	 */
	custProfitabilityInstId:number = undefined;
	/**
	 * Expanded Name :  Rating tariff
	 * 
	 * Desc : This identifies the actual tariff used to rate the shipment. 
	 * 
	 * Source : MBF root - MBRATTAR
	 * Required: false
	 * Array: false
	 */
	ratingTariffsId:string = undefined;
	/**
	 * Expanded Name : Rate auditor initials
	 * 
	 * Desc : Initials of the rating auditor who last audited the shipment for rates. 
	 * 
	 * Source : MBF: root: MBRTADID
	 * Required: false
	 * Array: false
	 */
	rateAuditorInitials:string = undefined;
	/**
	 * Expanded Name : Rate or rate audit queue name
	 * 
	 * Desc : The name of the rating (or the rate audit) queue used when the shipment was last rated / audited. 
	 * 
	 * Source : MBF: root: MBRATEQ
	 * Required: false
	 * Array: false
	 */
	rateOrRateAudtqNm:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	heaviestCommoditySequenceNo:string = undefined;
	/**
	 * Expanded name:  Service type code.
	 * 
	 * Description:  Defines the specific type of service provided for a shipment.
	 * 
	 * Values:  1 - Normal LTL, 2 - Con-Way Deferred Express.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Expanded name: Estimated Delivery Date
	 * 
	 * Description: Date that the shipment should be delivered based on standard lane days.
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * Expanded name: Freight Bill Delivery Set Print Count
	 * 
	 * Description:
	 * The number of times that a FBDS has been printed for this shipment.
	 * Required: false
	 * Array: false
	 */
	fbdsPrintCount:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * 
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * 
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * 
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded name: Delivery Information Required Code
	 * 
	 * Desc: This code indicates that there is a requirement to capture delivery information for this shipment because of customer special requirements.  This is usually an EDI customer requirement that must be met.  The name of the person that signed for delivery and the timestamp are also captured in this record.
	 * 
	 * Values: 
	 * R - delivery info is required 
	 * C - delivery informaiton has been captured  and exists with this record
	 * blank - No delivery information required for this shipment
	 * Required: false
	 * Array: false
	 */
	deliveryInformationRqdCd:DeliveryInfoRequiredCd = undefined;
	/**
	 * Expanded name: Delivery Signature Name
	 * 
	 * Desc: This is the name of the person that signed for the delivery of the freight.  This name is required by some customers and is passed along to them via EDI.  This name is captured if the DLVR_UNFO_RQD_CD is set.
	 * 
	 * Since a shipment can be delivered in parts, (part short, etc) there can be more than one signature.  This field will hold the name of the last delivery movement.  The previous signatures can be retrieved from shipment history records.
	 * Required: false
	 * Array: false
	 */
	deliverySignatureNm:string = undefined;
	/**
	 * Expanded name: Delivery Signature TimeStamp
	 * 
	 * Desc: This is the date and time recorded when the person named in DLVR_SIG_NM_TXT signed for the shipment.
	 * Required: false
	 * Array: false
	 */
	deliverySignatureDateTime:Date = undefined;
	/**
	 * Expanded Name : Standard Transit Days (in number of days)
	 * 
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables. 
	 * 
	 * Source : OSS_SVC_STDS_A/B - TRNST_DAYS
	 * Required: false
	 * Array: false
	 */
	standardTransitDays:string = undefined;
	/**
	 * Expanded Name : Actual Transit Days (in number of days)
	 * 
	 * Desc : The number of days it actually takes to move a shipment from shipper to consignee.
	 * 
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the qualifying delivery event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation  (SPOT, PSRT?, RDMG?, RFSD?, FINL).
	 * Required: false
	 * Array: false
	 */
	actualTransitDays:string = undefined;
	/**
	 * Expanded Name : Transit Movement Sequence Number
	 * 
	 * Desc : The sequence number identifying the movement (qualifying delivery event) for the calculation of ACTL_TRNST_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * 
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 * Required: false
	 * Array: false
	 */
	transitMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Warranty Indicator
	 * 
	 * Desc : Indicates whether the shipment is eligible for the money-back guarantee if moving between direct points under Tariff CNWY-599.
	 * 
	 * Valid Values : 
	 * 
	 * Y - eligible for warranty
	 * N - not eligible for warranty - default value
	 * Required: false
	 * Array: false
	 */
	warrantyInd:boolean = undefined;
	/**
	 * Expanded Name : Warranty Status Code
	 * 
	 * Desc : Identifies the warranty status of a shipment
	 * 
	 * For example, one value of this code would indicate that a warranty  claim has been filed  by the debtor if a shipment has failed service.
	 * 
	 * Valid Values:
	 * 0 - default value; This means either the shipment is not eligible (Warranty flag =  N ) or it is eligible and no one has filed a claim.
	 * 1 - The customer has filed a claim and it has not been audited.
	 * 2 - The customer has filed a claim and it was approved.
	 * 3 - The customer has filed a claim and it was denied.
	 * Required: false
	 * Array: false
	 */
	warrantyStatusCd:WarrantyStatusCd = undefined;
	/**
	 * Expanded name: Notification Code
	 * 
	 * Desc: Indicates whether or not an notification exists for this shipment.  If it exists, the details will exist in the SHM NOTFICATION table.  The notification is created in the SCO (Smart4) system.
	 * 
	 * Values:
	 * A - Appointment exists
	 * N - Notification exists
	 * O -Appointment Not Required  
	 * R- Reappointment candidate.  PSRT/RDMG Pro  could be reappointment for delivery
	 * 
	 * blank - Neither exist
	 * Required: false
	 * Array: false
	 */
	notificationCd:NotificationCd = undefined;
	/**
	 * Expanded Name: Absolute minimum charge indicator
	 * 
	 * Desc: This inidcates if the charge for this shipment is the absolute minimum charge.
	 * Required: false
	 * Array: false
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Percentage
	 * 
	 * Desc: This is the percentage discount applied to the freigt chrages and discountable accessorial charges of the shipment.
	 * Required: false
	 * Array: false
	 */
	discountPercentage:number = undefined;
	/**
	 * Expanded Name: Pricing Agreement Instance Id
	 * 
	 * Desc: This is the instance id of the pricing agreement that was used to rate the shipment.
	 * 
	 * ==NOTE== Link to BASE 5 PRC component
	 * Required: false
	 * Array: false
	 */
	priceAgreementId:number = undefined;
	/**
	 * Expanded Name: Rule set Number
	 * 
	 * Desc: This identifies the rule set of the pricing agreement that was used to rate the shipment.
	 * 
	 * ==NOTE== link to BASE 5 PRC component
	 * Required: false
	 * Array: false
	 */
	priceRulesetNbr:string = undefined;
	/**
	 * Expanded Name: Route prefix 
	 * 
	 * Desc: Identifies the last Route the shipment was put on.
	 * 
	 * ==NOTE== Link to SMART 4 SCO component
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * Expanded Name: Route Suffix
	 * 
	 * Desc: Identifies the last Route the shipment was put on.
	 * 
	 * ==NOTE== Link to SMART 4 SCO component
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * Expanded Name: Route Type Code
	 * 
	 * Desc: Identifies whether the shipment is currently on a  Route (either planning route or dispatched route) Staged (available) for Routing, or is Not on a route.
	 * 
	 * Valid Values
	 * 				- Not Routed
	 * R - Routed
	 * S - Staged
	 * Required: false
	 * Array: false
	 */
	routeTypeCd:RouteTypeCd = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * 
	 * Desc : The volume of the freight; derived from the dimensions captured by driver, or entered by a CSR or dock worker for an SL  (Shipper Load and Count), or from one of a variety of profiles. For details, see the description of CFT_PRFL_MTHD_CD.
	 * 
	 * 06/21/2016 Cathy Doucet modified description as per Ariel/Sunya.
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total Pallet Count
	 * 
	 * Desc: Total Number of Pallets in the Shipment
	 * Required: false
	 * Array: false
	 */
	totalPalletsCount:number = undefined;
	/**
	 * Expanded Name: Area instance Id
	 * 
	 * Desc: Each service center is divided into multiple geographic areas for delivery. This identifies the delivery area of destination service center in which this shipment s consignee is located.
	 * 
	 * Source: SMART 4 Requirement
	 * 
	 * == NOTE ==  Ties back to the geo-area stored in Oracle spatial database for each service center.
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * Expanded Name: Auto Rateable Indicator
	 * 
	 * Desc: This indicates whether the auto rating system is allowed to put rates and charges on the bill. This is irrespective of whether the bill needs to be manually reviewed or not before being marked RATED. 
	 * 
	 * Valid Values: 
	 * Y - Auto rating system may put rates and charges
	 * N - Auto Rating system should not out or change any rates and charges on the bill
	 * Required: false
	 * Array: false
	 */
	autoRateableInd:boolean = undefined;
	/**
	 * Expanded Name: Motorized-Move Pieces Known Indicator
	 * 
	 * Desc: This indicates whether MTRZD_PCS_CNT is known or applicable.. 
	 * 
	 * Valid Values: 
	 * Y - Yes, MTRZD_PCS_CNT is known/applicable
	 * N - No, MTRZD_PCS_CNT is NOT known/applicable
	 * 
	 * Source:  FBES
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesKnownInd:boolean = undefined;
	/**
	 * Expanded Name : Estimated Transit Days (in number of days)
	 * 
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables, adjusted by +1 if LATE_TENDER_IND =  Y 
	 * 
	 * Source : STD_TRNST_DAYS +1, if LATE_TENDER_IND =  Y
	 * Required: false
	 * Array: false
	 */
	estimatedTransitDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Days (in number of days)
	 * 
	 * Desc : The number of days it takes to move a shipment from shipper until it reaches a service stopping event.
	 * 
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the first service-stopping event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 * Required: false
	 * Array: false
	 */
	calculatedServiceDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Timestamp
	 * 
	 * Desc : The timestamp of a service stopping event.
	 * 
	 * Source : (LST_)MVMT_TMST of the first service-stopping event / movement.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 * Required: false
	 * Array: false
	 */
	calculatedServiceDateTime:Date = undefined;
	/**
	 * Expanded Name : Calculated Movement Sequence Number
	 * 
	 * Desc : The sequence number identifying the movement (service-stopping event) for the calculation of CALC_SVC_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * 
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 * Required: false
	 * Array: false
	 */
	calculatedMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Difference of Calculated Days
	 * 
	 * Desc : Difference between CALC_SVC_DAYS and EST_TRNST_DAYS.
	 * 
	 * Source : CALC_SVC_DAYS - EST_TRNST_DAYS.
	 * Required: false
	 * Array: false
	 */
	diffCalculatedDays:string = undefined;
	/**
	 * Expanded Name : Difference of Transit Days
	 * 
	 * Desc : Difference between ACTL_TRNST_DAYS and EST_TRNST_DAYS.
	 * 
	 * Source : ACTL_TRNST_DAYS - EST_TRNST_DAYS.
	 * Required: false
	 * Array: false
	 */
	diffTransitDays:string = undefined;
	/**
	 * Expanded Name : Late Tender Code
	 * 
	 * Desc : Indicates whether shipment could not be picked up in time (due to late tender by shipper) to make service.
	 * 
	 * Values: Y - Late Tender; blank or N - Not Late Tender
	 * 
	 * Source : Bill Entry/FBES; can be populated at Pick-up Request create time if a skeleton PRO is available; may be changed in Corrections
	 * Required: false
	 * Array: false
	 */
	lateTenderCd:LateTenderCd = undefined;
	/**
	 * Expanded Name : Shipment Service Status Code
	 * 
	 * Desc : Indicates whether shipment met service standards.
	 * 
	 * Values: Fail, Success, Exempt, Unknown
	 * 
	 * Source : Calculated
	 * Required: false
	 * Array: false
	 */
	shipmentServiceStatusCd:ServiceStatusCd = undefined;
	/**
	 * Expanded Name : Service Calculation Status Code
	 * 
	 * Desc : Indicates whether shipment service calculations have been performed.
	 * 
	 * Values:
	 * 			0 - Not Calculated Yet
	 * 			1 - Cannot Calculate (all variables not defined)
	 * 			? - Calculated from (service stopping event or appointment - replace '?' with DLVRY_QALFR_CD values)
	 * 
	 * Source : Calculated
	 * Required: false
	 * Array: false
	 */
	serviceCalculatedStatusCd:ServiceCalculationStatusCd = undefined;
	/**
	 * Expanded Name : Exemption Reason Code
	 * 
	 * Desc : Indicates why a shipment may be exempt from meeting service standards.
	 * 
	 * Values:
	 * 			blank - Not Exempt;
	 * 			any valid exemption reason code
	 * 
	 * Source : OPS_EXEMPT_SHPMT; shipment exemptions are created immediately upon full trailer and service center exemption events.
	 * Required: false
	 * Array: false
	 */
	exemptReasonCd:ExemptReasonCd = undefined;
	/**
	 * Expanded Name : Pickup Date Back-Date Indicator
	 * 
	 * Desc : Indicates whether a shipment pickup date was backdated during bill entry.
	 * 
	 * Values:
	 * 			Y - Shipment pickup date was backdated at bill entry
	 * 			N - Shipment pickup date = bill entry date
	 * 
	 * Source : Bill Entry/FBES.
	 * Required: false
	 * Array: false
	 */
	pickupBackdateInd:boolean = undefined;
	/**
	 * Expanded Name: FOOD OR POISON CODE
	 * 
	 * Desc; Indicates whether the shipment contains food or posion. Assumption: A shipment will never contain both.
	 * 
	 * Valid Values:
	 * 
	 * 1 - Neither
	 * 2 - Food
	 * 3 -  Poison
	 * Required: false
	 * Array: false
	 */
	foodPoisonCd:FoodPoisonCd = undefined;
	/**
	 * Expanded Name: Rating Override Code
	 * 
	 * Desc: Indicates if a rating override has been indicated during billing. For example to use a spot quote or lineal feet rating.
	 * 
	 * Valid Values:
	 * 1: Spot Quote Number Specified for rating
	 * 2: Lineal Feet Rating should be used
	 * Required: false
	 * Array: false
	 */
	ratingOverrideCd:string = undefined;
	/**
	 * Expanded Name : Pick-Up Time
	 * 
	 * Desc : Time when the shipment was picked up. This will be required for Late Tender shipments and optional for other shipments.
	 * 
	 * Source : Bill Entry/FBES; can be populated at Pick-up
	 * Request create time if a skeleton PRO is available;
	 * may be changed in Corrections
	 * Required: false
	 * Array: false
	 */
	pickupTime:string = undefined;
	/**
	 * Expanded Name : Service Start Date
	 * 
	 * Desc : Business date on which the service clock starts.  This value will be calculated automatically for all shipments, based on the values of PKUP_DT and LATE_TENDER_CD.  If a shipment is tendered late, the service start date will be set to the next business date after the pick-up date.
	 * 
	 * Source : Will be populated at pkup request create time if a skeleton PRO is available, during bill entry, through the late tender correction screen.
	 * Required: false
	 * Array: false
	 */
	serviceStartDate:string = undefined;
	/**
	 * Expanded Name: Inspected Indicator
	 * 
	 * Desc: Indicates whether the shipment has been inspected or not.
	 * Required: false
	 * Array: false
	 */
	inspectedInd:boolean = undefined;
	/**
	 * Expanded Name: Spot Quote ID
	 * 
	 * Desc: The Spot Quote ID that was entered during Billing. This may be different from the one that was used for rating.  For the latest Spot Quote ID that was used for raing, please look at the rating info record.
	 * Required: false
	 * Array: false
	 */
	spotQuoteId:number = undefined;
	/**
	 * Expanded Name: Shipper to Consignee Miles
	 * 
	 * Desc: This captures the mileage from shipper to consignee as calculated by the Milemaker package (Rand McNally)
	 * 
	 * Added by C.Doucet 05/06/09 as per Shibashis
	 * Required: false
	 * Array: false
	 */
	shipperToConsigneeMiles:number = undefined;
	/**
	 * Expanded name: Shipper loaded trailer code
	 * 
	 * Desc: Identifies whether a shipper loaded a trailer, and if so, where Con-way took possession of trailer. 
	 * 
	 * Values: 
	 * N Â Shipper did not load trailer (default)
	 * H Â Hook loaded; a trailer is loaded by shipper and picked up by Con-way at shipper s site 
	 * D Â Dock Drop; a trailer is loaded by shipper and dropped at Con-way dock by shipper
	 * Required: false
	 * Array: false
	 */
	shipperLoadedTrlrCd:ShipperLoadedTrailerCd = undefined;
	/**
	 * Expanded Name: Consignee unloaded trailer code
	 * 
	 * Desc: Identifies whether a consignee unloaded a trailer, and if so, where consignee took possession of trailer. 
	 * 
	 * Values:
	 * N Â Consignee did not unload trailer (default)
	 * D Â Dock Pickup; a loaded trailer is picked up at Con-way dock by consignee to be unloaded by consignee 
	 * S Â Spot loaded; a loaded trailer is left at the consignee site for consignee to unload
	 * Required: false
	 * Array: false
	 */
	consigneeUnloadTrlrCd:ConsigneeUnloadedTrailerCd = undefined;
	/**
	 * Expanded Name: Lineal Foot Total Number
	 * 
	 * Desc: This is the length in feet that a shipment will take up in a trailer.  This dimension is typically used when the freight is long and narrow and cannot have anything stacked on top of it (e.g. carpet rolls).  For this reason it takes a larger portion of the trailer volume than a shipment of the same weight that is packaged on pallets.  This value can then be used in the planning of trailer loads and to rate shipments that have these characteristics.
	 * Required: false
	 * Array: false
	 */
	linealFootTotalNbr:string = undefined;
	/**
	 * Expanded Name: Single Shipment Acquired Indicator                                                                                                                                                Desc:                                                                                                      This indicates whether this was the only Shipment that we acquired from the Customer at the time that we received this one.
	 * Values:
	 * Y Â Yes:  This was the only Shipment Acquired at the time that we received this one.
	 * N Â No: There were other Shipments Acquired at the time that we received this one.
	 * Required: false
	 * Array: false
	 */
	singleShipmentAcquiredInd:boolean = undefined;
	/**
	 * Desc: This code identifies the method in which Con-way received the freight from the customer.
	 * Current values are: 
	 * PU Â Regular Pickup:  This is when the driver goes to the customer dock and loads the Shipment on the trailer to return to the service center.
	 * HL Â Hook Loaded: This is when the driver goes to the customer site and pickup a loaded trailer that this Shipment was on.
	 * DD Â Dock Drop:  This is when the customer delivers the Shipment to a Con-way Service Center, so no driver went to the Customer site
	 * Required: false
	 * Array: false
	 */
	shipmentAcquiredTypeCd:ShipmentAcquiredTypeCd = undefined;
	/**
	 * Expanded Name : Loose pieces count
	 * 
	 * Desc : Total loose pieces from all commodities in the shipment.
	 * 
	 * Count of non Motor Move pieces associated with a shipment. This count plus Motor Moves defines the total number of pieces moving within the Con-way system for Operations productivity.  This does not necessarily balance with the total pieces or commodity pieces entered during bill entry.
	 * Required: false
	 * Array: false
	 */
	loosePiecesCount:number = undefined;
	/**
	 * Expanded Name: PUP_VOLUME_PERCENTAGE
	 * 
	 * Desc: This is the volume of the shipment expressed as the percentage of a full pup (28 feet long trailer).
	 * Required: false
	 * Array: false
	 */
	pupVolumePercentage:number = undefined;
	/**
	 * Desc: Instance id of the Pickup Request that is associated with this Shipment during the Confirm On-Board process.
	 * Required: false
	 * Array: false
	 */
	purInstId:number = undefined;
	/**
	 * Expanded Name: Bulk Liquid Indicator
	 * 
	 * Desc: Identifies the shipment as containing bulk liquid.  If volume is known, this applies to shipments >119 gallons.
	 * 
	 * Added 8/5/14 by Paul Bramhall for Tanker Endorsement Project.
	 * -------------------------------------------------------------
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cubicFeetProfileMthdCd:ShipmentVolumeMethodCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cubicFeetProfileTypeCd:ShipmentVolumeTypeCd = undefined;
	/**
	 * In the event a customer requires a shipment to travel from origin to destination 
	 * without the loading of any additional shipments on the trailer(s), the shipment is considered Exclusive Use.
	 * Required: false
	 * Array: false
	 */
	exclusiveUseInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates that this shipment is marked as a Mexico Door to Door shipment.
	 * Required: false
	 * Array: false
	 */
	mexicoDoorToDoorInd:boolean = undefined;
	/**
	 * Indicates that this shipment is time date critical and operations needs to call for a delivery appointment.
	 * Required: false
	 * Array: false
	 */
	callForAppointmentInd:boolean = undefined;
	/**
	 * The value of the freight as declared by the customer.
	 * Required: false
	 * Array: false
	 */
	declaredValueAmount:number = undefined;
	/**
	 * Indicates that the customer would like to insure the shipment due to excessive value of the freight.
	 * Required: false
	 * Array: false
	 */
	excessiveValueInd:boolean = undefined;
	/**
	 * Indicates whether the payment terms were flipped on a bill after biller submit, based on input shipment party data.
	 * Required: false
	 * Array: false
	 */
	debtorTermFlipInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	remark:Remark[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	movement:Movement[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	managementRemark:ManagementRemark[] = undefined;
}
/**
 * Holds a snapshot of Shipment. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ShipmentSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Shipment = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Shipment = undefined;
}
/**
 * Details about a message queue. E.g. Oracle Advanced Queue
 */
export class SymphonyQueue {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Maximum number of retries
	 * Required: false
	 * Array: false
	 */
	maxRetries:number = undefined;
	/**
	 * Delay for retries
	 * Required: false
	 * Array: false
	 */
	retryDelay:number = undefined;
	/**
	 * Indicates if enqueue is enabled
	 * Required: false
	 * Array: false
	 */
	enqueueEnabled:string = undefined;
	/**
	 * Indicates if dequeue is enabled
	 * Required: false
	 * Array: false
	 */
	dequeueEnabled:string = undefined;
	/**
	 * The retention time
	 * Required: false
	 * Array: false
	 */
	retention:string = undefined;
}
/**
 * Details of a symphony message
 */
export class SymphonyMessage {
	/**
	 * ID of the message
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Timestamp of when the message was enqueued
	 * Required: false
	 * Array: false
	 */
	enqueueTmst:Date = undefined;
	/**
	 * Number of time the message was retried
	 * Required: false
	 * Array: false
	 */
	retryCount:number = undefined;
	/**
	 * Message payload
	 * Required: false
	 * Array: false
	 */
	payload:string = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class LoadedTrailerSearchRecordMessage extends LoadedTrailerSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	messageHeader:SearchRecordMessageHeader = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class EnsembleStateSearchRecordMessage extends EnsembleStateSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	messageHeader:SearchRecordMessageHeader = undefined;
}




import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive( {
  selector: '[XpoUppercase]'
} )
export class XpoUppercaseDirective implements OnInit {

  constructor( public ref: ElementRef ) { }

  ngOnInit(): void {
    this.ref.nativeElement.value = this.ref.nativeElement.value ? this.ref.nativeElement.value.toUpperCase() : '';
  }

  @HostListener( 'input', ['$event'] ) onInput( event ) {
    this.ref.nativeElement.value = event.target.value.toUpperCase();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { FormatValidationService, ConditioningService } from '@xpo-ltl/common-services';

@Pipe({
  name: 'proFormatter'
})
export class ProFormatterPipe implements PipeTransform {

  constructor(private formatService: FormatValidationService, private conditioningService: ConditioningService) { }

  transform(pro: string, digits: number): string {
    if (!pro || !digits) {
      return undefined;
    }

    if (this.formatService.isValidProNumber(pro)) {
      return this.conditioningService.conditionProNumber(pro, digits);
    }

    return pro;
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyValue'
})
export class EmptyValuePipe implements PipeTransform {

  transform(value: string, emptyValue: string = '-'): any {
    return value || emptyValue;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import {
  MatButtonModule, MatCheckboxModule, MatToolbarModule, MatIconModule, MatCardModule, MatMenuModule, MatInputModule,
  MatSidenavModule, MatRippleModule, MatRadioModule, MatSliderModule, MatFormFieldModule, MatSelectModule,
  MatSlideToggleModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatDialogModule,
  MatProgressBarModule, MatProgressSpinnerModule, MatSnackBarModule, MatIconRegistry, MatDialog
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatRippleModule,
    MatRadioModule,
    MatSliderModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatRippleModule,
    MatRadioModule,
    MatSliderModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ], providers: [

  ]
})
export class MaterialModule { }

import { Directive, DoCheck, forwardRef, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { XpoErrorsDirective } from './xpo-errors.directive';
import { ErrorOptions, toArray } from './xpo-error-helper';

@Directive({
  selector: '[xpoError]'
})
export class XpoErrorDirective implements OnInit, OnDestroy, DoCheck {

  rules: string[] = [];
  errorNames: string[] = [];
  subscription: Subscription;
  _states: Subject<string[]>;
  states: Observable<string[]>;

  private unsubscribe: Subject<void> = new Subject<void>();


  @Input() set xpoError(value: ErrorOptions) {
    this.errorNames = toArray(value);
  }

  @Input() set when(value: ErrorOptions) {
    this.rules = toArray(value);
  }

  @HostBinding('hidden')
  hidden: boolean = true;

  constructor(
    @Inject(forwardRef(() => XpoErrorsDirective)) private xpoErrors: XpoErrorsDirective
  ) { }

  ngOnInit() {

    this._states = new Subject<string[]>();
    this.states = this._states.asObservable().pipe(takeUntil(this.unsubscribe), distinctUntilChanged());

    const errors = this.xpoErrors.subject.pipe(filter(Boolean), filter(obj => !this.errorNames.indexOf(obj.errorName)));
    const states = this.states.pipe(map(ctrlStates => this.rules.every(rule => !ctrlStates.indexOf(rule))));

    this.subscription = combineLatest(states, errors)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([ctrlStates, ctrlErrors]) => {
        this.hidden = !(ctrlStates && ctrlErrors.control.hasError(ctrlErrors.errorName));
      });

  }

  ngDoCheck() {
    this._states.next(
      this.rules.filter((rule) => (this.xpoErrors.control as any)[rule])
    );
  }

  ngOnDestroy() {
    if ( this.unsubscribe ) {
      this.unsubscribe.next();
      this.unsubscribe.complete();
      this.unsubscribe = null;
    }
  }
}

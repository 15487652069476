import { MatAutocomplete, MatOption } from '@angular/material';
import { Directive, HostListener, Input, QueryList } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[xpoSmartSelect]'
})
export class XpoSmartSelectDirective {

  @Input() xpoSmartSelectOptions: MatAutocomplete;

  constructor(private formControl: NgControl) { }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent) {
    let hasFocus = false;
    // if we blurred to the list skip this logic.
    if (event.relatedTarget && event.relatedTarget['className'].toLowerCase().includes('mat-option')) {
      hasFocus = true;
    }

    if (!hasFocus) {

      const value = (this.formControl.value) ? this.formControl.value : '';
      const options: QueryList<MatOption> = this.xpoSmartSelectOptions.options;
      // nothing in the list.  Accept the input value
      if (!options || options.length === 0) {
        this.formControl.control.setValue('');
        return;
      }

      let optionValue = options.find((option) => option.active);
      if ( !optionValue ) {
        const test = new RegExp(`^(${value} -){1}|(^${value}$)$`, 'i');
        optionValue = options.find((option) =>
          test.test(option.value) ||
          test.test(option.viewValue));
      }

      if (optionValue) {
        this.formControl.control.setValue(optionValue.value);
      } else if (value.length > 0 && options.first) {
        this.formControl.control.setValue(options.first.value);
      } else {
        this.formControl.control.setValue('');
      }
    }

    // Ensure that the panel is closed when blurred, if the user clicks on an optiion let the autocomplete
    // component handle closing the panel
    if (this.xpoSmartSelectOptions.showPanel && !hasFocus) {
      this.xpoSmartSelectOptions.showPanel = false;
    }

    this.formControl.control.updateValueAndValidity();
  }
}

import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetLaneRemainingLoadInfoResp,
	GetLaneRemainingLoadInfoPath,
	GetLinehaulBypassTrailerResp,
	GetLinehaulBypassTrailerPath,
	GetMoveToSicResp,
	GetMoveToSicQuery,
	GetMoveToSicPath,
	GetOperationsTrailerResp,
	GetOperationsTrailerPath,
	GetTrailerContentsResp,
	GetTrailerContentsQuery,
	GetTrailerLoadHistoryResp,
	GetTrailerLoadHistoryQuery,
	GetTrailerLoadHistoryPath,
	GetTrailerLoadPhotoEventDetailsResp,
	GetTrailerLoadPhotoEventDetailsPath,
	GetTrailerWithScheduleInfoResp,
	GetTrailerWithScheduleInfoQuery,
	UpsertLinehaulBypassTrailerRqst,
	UpsertLinehaulBypassTrailerResp,
	ListChangesForLanesResp,
	ListChangesForLanesPath,
	GetLoadRequestResp,
	GetLoadRequestPath,
	ListLoadRequestsResp,
	UpsertLoadRequestRqst,
	GetLoadRequestReasonCodeResp,
	GetLoadRequestReasonCodePath,
	ListLoadRequestReasonCodesResp,
	UpsertLoadRequestReasonCodeRqst,
	UpsertLoadRequestReasonCodeResp,
	CreateArrivalScheduleRqst,
	CreateArrivalSchedulePath,
	GetModelRunDetailsResp,
	GetModelRunDetailsQuery,
	GetTrailerCountsPerLaneResp,
	GetTrailerCountsPerLaneQuery,
	GetTrailerCountsPerLanePath,
	ListAllowedTripleMoveLegsResp,
	ListAllowedTripleMoveLegsQuery,
	ListModelInstructionsResp,
	ListModelInstructionsQuery,
	ListModelInstructionsPath,
	UpdateAllowedTripleMoveLegsRqst,
	CreatePlanningSchedulesRqst,
	CreatePlanningSchedulesResp,
	CreatePlanningSchedulesPath,
	ListAllowableSchedulesResp,
	ListAllowableSchedulesPath,
	CalculateLhoEventMetricsResp,
	CalculateLhoEventMetricsPath,
	CalculateScoEventMetricsResp,
	CalculateScoEventMetricsPath,
	GetLoadMetricsResp,
	GetLoadMetricsPath,
	ListMetricsResp,
	ListMetricsQuery,
	ListMetricsPath,
	DeleteExpiredMessagesResp,
	DeleteExpiredMessagesQuery,
	DeleteExpiredMessagesPath,
	GetEnsembleStatusCountsResp,
	GetEnsembleStatusCountsPath,
	GetQueuePendingCountResp,
	GetQueuePendingCountPath,
	KillEnsembleRqst,
	KillEnsembleResp,
	ListExpiredMessagesResp,
	ListExpiredMessagesPath,
	ListQueuesResp,
	ReplayMessagePath,
	RestartEnsembleRqst,
	RestartEnsembleResp,
	RestartEnsemblePath,
	ResumeEnsembleRqst,
	ResumeEnsembleResp,
	ResumeEnsemblePath,
	StartQueuePath,
	StopQueuePath,
	StopQueueWebhookPath,
	StopQueueWebhookQuery,
	ExportLoadedTrailerImagesRqst,
	SearchLoadedTrailerImagesRqst,
	SearchLoadedTrailerImagesResp,
	ChangesFeedLoadedTrailerImagesRqst,
	RegisterFilterLoadedTrailerImagesRqst,
	RegisterFilterLoadedTrailerImagesResp,
	UnregisterFilterLoadedTrailerImagesRqst,
	ExportLoadedTrailerImagesUnfilteredRqst,
	SearchLoadedTrailerImagesUnfilteredRqst,
	SearchLoadedTrailerImagesUnfilteredResp,
	ChangesFeedLoadedTrailerImagesUnfilteredRqst,
	RegisterFilterLoadedTrailerImagesUnfilteredRqst,
	RegisterFilterLoadedTrailerImagesUnfilteredResp,
	UnregisterFilterLoadedTrailerImagesUnfilteredRqst,
	BulkUpdateElasticDocumentLoadedTrailerResp,
	DeleteElasticDocumentLoadedTrailerResp,
	DeleteElasticDocumentLoadedTrailerPath,
	GetElasticDocumentLoadedTrailerResp,
	GetElasticDocumentLoadedTrailerQuery,
	GetElasticDocumentLoadedTrailerPath,
	GetElasticDocumentLoadedTrailerCountResp,
	GetElasticDocumentLoadedTrailerCountQuery,
	UpdateElasticDocumentLoadedTrailerRqst,
	UpdateElasticDocumentLoadedTrailerResp,
	UpdateElasticDocumentLoadedTrailerPath,
	ExportEnsembleStateUnfilteredRqst,
	SearchEnsembleStateUnfilteredRqst,
	SearchEnsembleStateUnfilteredResp,
	ChangesFeedEnsembleStateUnfilteredRqst,
	RegisterFilterEnsembleStateUnfilteredRqst,
	RegisterFilterEnsembleStateUnfilteredResp,
	UnregisterFilterEnsembleStateUnfilteredRqst,
	BulkUpdateElasticDocumentEnsembleStateResp,
	DeleteElasticDocumentEnsembleStateResp,
	DeleteElasticDocumentEnsembleStatePath,
	GetElasticDocumentEnsembleStateResp,
	GetElasticDocumentEnsembleStateQuery,
	GetElasticDocumentEnsembleStatePath,
	GetElasticDocumentEnsembleStateCountResp,
	GetElasticDocumentEnsembleStateCountQuery,
	UpdateElasticDocumentEnsembleStateRqst,
	UpdateElasticDocumentEnsembleStateResp,
	UpdateElasticDocumentEnsembleStatePath
} from './api-linehauloperations';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class LinehaulOperationsApiService extends BaseService {
	private static LinehaulOperationsApiEndpoint = 'linehauloperationsApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Based on a closed trailer's equipment instance ID, returns the remaining load metrics for a lane
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The equipment instance ID must be of a closed trailer.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If input is valid, then return the current and load to SICS, remaining number of planned loads, remaining cubage and weight of shipments planned for this lane.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getLaneRemainingLoadInfo(
							   pathParams: GetLaneRemainingLoadInfoPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLaneRemainingLoadInfoResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/remaining-load-info/{equipmentInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns the record from OPS_LNH_BYPASS_TRLR table.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid equipment instance ID and SIC must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the associated record will be retrieved.
	* <br/>2. Otherwise ana appropriate error message will be returned.
	*/
	public getLinehaulBypassTrailer(
							   pathParams: GetLinehaulBypassTrailerPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLinehaulBypassTrailerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/bypass-trailers/{equipmentInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns MoveToSic from a SIC, shift code, and, a load destination SIC or doorNumber
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The origin sic code and the shift code must be provided.
	* <br/>2. Load destination or door number should be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If input values are valid, then return a MoveToSic code. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getMoveToSic(
							   pathParams: GetMoveToSicPath,
							   queryParams: GetMoveToSicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetMoveToSicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/move-to-sic/{originSicCd}/{shiftCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns the basic trailer information from OPS_TRAILER_LOAD and OPS_EQUIPMENT tables.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid equipment ID is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the trailer details are returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getOperationsTrailer(
							   pathParams: GetOperationsTrailerPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetOperationsTrailerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/operations-trailers/{equipmentId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the contents for a trailer. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Either the equipment instance ID or trailer number must be provided as well as valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If either of the input values is valid, then details about the trailer contents will be returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getTrailerContents(
							   queryParams: GetTrailerContentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetTrailerContentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trailer-contents'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the history for a trailer load. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The event instance ID and timestamp must be provided as well as valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input values are valid, then details about the load photo event will be returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getTrailerLoadHistory(
							   pathParams: GetTrailerLoadHistoryPath,
							   queryParams: GetTrailerLoadHistoryQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetTrailerLoadHistoryResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trailer-loads/histories/{equipmentId}/{updatedTimestamp}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns details for a trailer load photo event. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The event instance ID and timestamp must be provided as well as valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input values are valid, then details about the load photo event will be returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getTrailerLoadPhotoEventDetails(
							   pathParams: GetTrailerLoadPhotoEventDetailsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetTrailerLoadPhotoEventDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trailer-loads/photo-events/{equipmentId}/{eventTmst}/details'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns the trailer basic data and its corresponding schedule.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Either a valid equipment instance ID or trailer number must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If either of the input values is valid, then details about the trailer contents will be returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getTrailerWithScheduleInfo(
							   queryParams: GetTrailerWithScheduleInfoQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetTrailerWithScheduleInfoResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trailer-schedules'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation upserts (creates or updates) a bypass trailer based on the input provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid values for the non-nullable columns for OPS_LNH_BYPASS_TRLR must be provided.
	* <br/>
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the Linehaul Bypass Trailer record is created or the existing entry is updated and the corresponding object is returned.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public upsertLinehaulBypassTrailer(request: UpsertLinehaulBypassTrailerRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertLinehaulBypassTrailerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/bypass-trailers'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Gets the changesContents
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The current sic code, the shift code and the created timestamp must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If input values are valid, then details about the changes contents will be returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listChangesForLanes(
							   pathParams: ListChangesForLanesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListChangesForLanesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/changes-for-lanes/{currentSicCd}/{currentShiftCd}/{runDate}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns a Load Request based on the lhoLoadRequestId provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid lhoLoadRequestId must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the Load Request associated with the load request ID provided is returned.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public getLoadRequest(
							   pathParams: GetLoadRequestPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLoadRequestResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/load-requests/{lhoLoadRequestId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists all the of the Load Requests that exist.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then all Load Requests are listed.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public listLoadRequests(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLoadRequestsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/load-requests'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation upserts (creates or updates) a Load Request based on the input provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid values for the non-nullable columns for LHO_LOAD_REQUEST must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the Load Request is created or updates the existing entry.  The LoadRequest object is returned.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public upsertLoadRequest(request: UpsertLoadRequestRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLoadRequestsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/load-requests'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation returns a Load Request Reason Code based on the reasonTypeCd and reasonCd provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid reasonTypeCd and reasonCd must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the Load Request Reason Code associated with the input provided is returned.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public getLoadRequestReasonCode(
							   pathParams: GetLoadRequestReasonCodePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLoadRequestReasonCodeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/load-request-reason-codes/{reasonTypeCd}/{reasonCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists all the of the Load Request Reason Codes that exist.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then all Load Request Reason Codes are listed.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public listLoadRequestReasonCodes(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLoadRequestReasonCodesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/load-request-reason-codes'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation upserts (creates or updates) a Load Request Reason Code based on the input provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid values for the non-nullable columns for LHO_LOAD_REQUEST_RSN_CODE must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the Load Request Reason Code is created or updates the existing entry.  The LoadRequestReasonCode object is returned.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public upsertLoadRequestReasonCode(request: UpsertLoadRequestReasonCodeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertLoadRequestReasonCodeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/load-request-reason-codes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation creates an arrival schedule.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. The schedule should not have held status discr ind set to 'Y'.
	* <br/>2. The arrival SIC and destination SIC should be different.
	* <br/>
	* <br/>Post-conditions
	* <br/>1. If successful, then the equipment will be updated as it arrives and will create a SCO_PLANNING_EVENT record for each trailer on the schedule.  Reports will also be written on OPS_ONLN_RPT* tables.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public createArrivalSchedule(request: CreateArrivalScheduleRqst,
							   pathParams: CreateArrivalSchedulePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/schedules/arrivals'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns details of a Model Optimizer Run.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Either the model run ID or the run date, run shift code, and group code must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If either of the input values are valid, then Model Run is returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getModelRunDetails(
							   queryParams: GetModelRunDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetModelRunDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/model-run-details'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the planned count and actual count per lane along with the list of closed and loading at dock trailers.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid origin SIC, shift code, and planned date must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the planned count, actual count, and list of CLOS/LDDK trailers for the lane are returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getTrailerCountsPerLane(
							   pathParams: GetTrailerCountsPerLanePath,
							   queryParams: GetTrailerCountsPerLaneQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetTrailerCountsPerLaneResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{originSic}/shifts/{shiftCd}/planned-dates/{plannedDate}/trailer-counts'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists the alllowed triple move legs. 
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The current SIC and shift code must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If either of the input values is valid, then a list of move paths with triple allowed lanes will be returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listAllowedTripleMoveLegs(
							   queryParams: ListAllowedTripleMoveLegsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAllowedTripleMoveLegsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/allowed-triple-move-legs'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists the summary view of model instructions by move lane and load lane.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The current SIC, shift code, and planned date must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If either of the input values is valid, then a list of move paths with triple allowed lanes will be returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listModelInstructions(
							   pathParams: ListModelInstructionsPath,
							   queryParams: ListModelInstructionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListModelInstructionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/{shiftCd}/{plannedDate}/{modelGroupCd}/instructions'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates move path lane's Triple Allowed Ind from "Y" to "N".  And ability to revert back to "N".
	*/
	public updateAllowedTripleMoveLegs(request: UpdateAllowedTripleMoveLegsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/allowed-trip-move-legs'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation creates linehaul planning schedules for the SIC provided.
	* <br/>
	* <br/>Pre-conditioin: 
	* <br/>1. A valid SIC, defaultDispatchLocalDateTime, and hssInd must be provided. 
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, inserts on OPS_SCHEDULE, SCO_PLANNING_EVENT, and SCO_PLANNING_EVENT_TRLR are performed.
	* <br/>2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public createPlanningSchedules(request: CreatePlanningSchedulesRqst,
							   pathParams: CreatePlanningSchedulesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePlanningSchedulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/plans/schedules'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation lists the summary view of related to equipment (trailers, dolly) and drivers used for each Schedule being dispatched at a given SIC.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The current SIC, shift code, planned date, and modelGroupCd must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If either of the input values is valid, then a list of move lanes with allowed schedules will be returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listAllowableSchedules(
							   pathParams: ListAllowableSchedulesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAllowableSchedulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/{shiftCd}/{plannedDate}/{modelGroupCd}/allowable-schedules'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation calculates values and persists LHO event metrics.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid sicCd, typeCd, and runId must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then summary metric details are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public calculateLhoEventMetrics(
							   pathParams: CalculateLhoEventMetricsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CalculateLhoEventMetricsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/lho/metrics/{sicCd}/{typeCd}/{runId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation calculates values and persists SCO event metrics.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid sicCd, shiftCd, callDate, typeCd, and subTypeCd must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then summary metric details are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public calculateScoEventMetrics(
							   pathParams: CalculateScoEventMetricsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CalculateScoEventMetricsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/sco/metrics/{sicCd}/{shiftCd}/{callDate}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Gets load average, load factor, TUC / TU and load count of changesContents
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The current sic code and the shift code must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If input values are valid, then metrics specified will be returned. 
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getLoadMetrics(
							   pathParams: GetLoadMetricsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLoadMetricsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/load-metrics/{currentSicCd}/{currentShiftCd}/{planDate}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns list of metrics for shiftCd and planDate.
	*/
	public listMetrics(
							   pathParams: ListMetricsPath,
							   queryParams: ListMetricsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListMetricsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/list-metrics/{shiftCd}/{planDate}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Delete expired messages in the queue
	*/
	public deleteExpiredMessages(
							   pathParams: DeleteExpiredMessagesPath,
							   queryParams: DeleteExpiredMessagesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteExpiredMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation returns counts for the number of ensembles in each of the status cd values.
	* <br/>Pre-conditions:
	* <br/>1. Input search criteria may be provided.
	* <br/>Post-condition:
	* <br/>1. Counts for each ensemble status are returned.
	*/
	public getEnsembleStatusCounts(
							   pathParams: GetEnsembleStatusCountsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetEnsembleStatusCountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/counts/{fromTmst}/{toTmst}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns counts for the number of messages pending in the queue.
	* <br/>Pre-conditions:
	* <br/>1. Queue name is provided.
	* <br/>Post-condition:
	* <br/>1. Count of all messages in ready and wait statuses
	*/
	public getQueuePendingCount(
							   pathParams: GetQueuePendingCountPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetQueuePendingCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/counts/pending'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation updates one or more SUSPENDED Ensemble execution row to a status of KILLED, in order to indicate it is has been forced to stop processing.
	* <br/>Pre-conditions:
	* <br/>1. One or more Ensemble Instance Ids that is in a status of SUSPENDED is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. Ensemble record(s) updated to a Status of KILLED.
	*/
	public killEnsemble(request: KillEnsembleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<KillEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/ensembles/kill'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* List all expired messages in the queue
	*/
	public listExpiredMessages(
							   pathParams: ListExpiredMessagesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExpiredMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages/{enqueueFromTmst}/{enqueueToTmst}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List the queues.
	*/
	public listQueues(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListQueuesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Replay a message in expired status
	*/
	public replayMessage(request: ReplayMessagePath,
							   pathParams: ReplayMessagePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages/{messageId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation Restarts a STARTED or NOT_STARTED Ensemble execution based on input Ensemble Instance Id.
	* <br/>Pre-conditions:
	* <br/>1. Input Ensemble Instance Id is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. STARTED or NOT_STARTED Ensemble execution status is updated to STARTED and execution is initiated; if any Minuets exist for the Ensemble, those are initiated in sequence and status updates are performed.
	*/
	public restartEnsemble(request: RestartEnsembleRqst,
							   pathParams: RestartEnsemblePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RestartEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/ensembles/{ensembleInstId}/restart'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation Resumes a SUSPENDED Ensemble execution based on input Ensemble Instance Id.
	* <br/>Pre-conditions:
	* <br/>1. Input Ensemble Instance Id is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. SUSPENDED Ensemble execution status is updated to STARTED and execution is initiated; if any Minuets exist for the Ensemble, those are initiated in sequence and status updates are performed.
	*/
	public resumeEnsemble(request: ResumeEnsembleRqst,
							   pathParams: ResumeEnsemblePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ResumeEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/ensembles/{ensembleInstId}/resume'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Start a queue.
	*/
	public startQueue(request: StartQueuePath,
							   pathParams: StartQueuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/start'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Stop a queue. Stopping a queue will only stop the dequeuing process, it will continue to enqueue
	*/
	public stopQueue(request: StopQueuePath,
							   pathParams: StopQueuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/stop'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Stop a queue. Stopping a queue will only stop the dequeuing process, it will continue to enqueue
	*/
	public stopQueueWebhook(request: StopQueueWebhookPath,
							   pathParams: StopQueueWebhookPath,
							   queryParams: StopQueueWebhookQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/webhooks/queues/{queueName}/stop'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Export documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All documents matching the filter will be exported as CSV or Excel format
	* <br/>		
	*/
	public exportLoadedTrailerImages(request: ExportLoadedTrailerImagesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images/exports'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Documents matching the filter will be returned
	* <br/>		
	*/
	public searchLoadedTrailerImages(request: SearchLoadedTrailerImagesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchLoadedTrailerImagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for loaded-trailer-images
	*/
	public changesFeedLoadedTrailerImages(request: ChangesFeedLoadedTrailerImagesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for LoadedTrailerImages
	*/
	public registerFilterLoadedTrailerImages(request: RegisterFilterLoadedTrailerImagesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterLoadedTrailerImagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for LoadedTrailerImages
	*/
	public unregisterFilterLoadedTrailerImages(request: UnregisterFilterLoadedTrailerImagesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Export documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All documents matching the filter will be exported as CSV or Excel format
	* <br/>		
	*/
	public exportLoadedTrailerImagesUnfiltered(request: ExportLoadedTrailerImagesUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images-unfiltered/exports'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Documents matching the filter will be returned
	* <br/>		
	*/
	public searchLoadedTrailerImagesUnfiltered(request: SearchLoadedTrailerImagesUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchLoadedTrailerImagesUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images-unfiltered/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for loaded-trailer-images-unfiltered
	*/
	public changesFeedLoadedTrailerImagesUnfiltered(request: ChangesFeedLoadedTrailerImagesUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images-unfiltered/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for LoadedTrailerImagesUnfiltered
	*/
	public registerFilterLoadedTrailerImagesUnfiltered(request: RegisterFilterLoadedTrailerImagesUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterLoadedTrailerImagesUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images-unfiltered/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for LoadedTrailerImagesUnfiltered
	*/
	public unregisterFilterLoadedTrailerImagesUnfiltered(request: UnregisterFilterLoadedTrailerImagesUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loaded-trailer-images-unfiltered/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Elastic document for LoadedTrailer
	*/
	public deleteElasticDocumentLoadedTrailer(
							   pathParams: DeleteElasticDocumentLoadedTrailerPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteElasticDocumentLoadedTrailerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/loaded-trailer-images-unfiltered/_doc/{loadedTrailerInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get Elastic document from LoadedTrailer index for given primary key
	*/
	public getElasticDocumentLoadedTrailer(
							   pathParams: GetElasticDocumentLoadedTrailerPath,
							   queryParams: GetElasticDocumentLoadedTrailerQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentLoadedTrailerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/loaded-trailer-images-unfiltered/_doc/{loadedTrailerInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get document count from LoadedTrailer index
	*/
	public getElasticDocumentLoadedTrailerCount(
							   queryParams: GetElasticDocumentLoadedTrailerCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentLoadedTrailerCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/loaded-trailer-images-unfiltered/_count'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update Elastic document for LoadedTrailer
	*/
	public updateElasticDocumentLoadedTrailer(request: UpdateElasticDocumentLoadedTrailerRqst,
							   pathParams: UpdateElasticDocumentLoadedTrailerPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateElasticDocumentLoadedTrailerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/loaded-trailer-images-unfiltered/_doc/{loadedTrailerInstId}/_update'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Export documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All documents matching the filter will be exported as CSV or Excel format
	* <br/>		
	*/
	public exportEnsembleStateUnfiltered(request: ExportEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/exports'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Documents matching the filter will be returned
	* <br/>		
	*/
	public searchEnsembleStateUnfiltered(request: SearchEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchEnsembleStateUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for ensemble-state-unfiltered
	*/
	public changesFeedEnsembleStateUnfiltered(request: ChangesFeedEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for EnsembleStateUnfiltered
	*/
	public registerFilterEnsembleStateUnfiltered(request: RegisterFilterEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterEnsembleStateUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for EnsembleStateUnfiltered
	*/
	public unregisterFilterEnsembleStateUnfiltered(request: UnregisterFilterEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Elastic document for EnsembleState
	*/
	public deleteElasticDocumentEnsembleState(
							   pathParams: DeleteElasticDocumentEnsembleStatePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get Elastic document from EnsembleState index for given primary key
	*/
	public getElasticDocumentEnsembleState(
							   pathParams: GetElasticDocumentEnsembleStatePath,
							   queryParams: GetElasticDocumentEnsembleStateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get document count from EnsembleState index
	*/
	public getElasticDocumentEnsembleStateCount(
							   queryParams: GetElasticDocumentEnsembleStateCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentEnsembleStateCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_count'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update Elastic document for EnsembleState
	*/
	public updateElasticDocumentEnsembleState(request: UpdateElasticDocumentEnsembleStateRqst,
							   pathParams: UpdateElasticDocumentEnsembleStatePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}/_update'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(LinehaulOperationsApiService.LinehaulOperationsApiEndpoint);
	}
}

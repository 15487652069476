import { AfterViewInit, Directive, Injector, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormArrayName, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ErrorDetails, ErrorOptions, toArray } from './xpo-error-helper';
import { Unsubscriber } from '../classes';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[xpoErrors]',
  exportAs: 'xpoErrors'
})
export class XpoErrorsDirective implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() xpoErrors: string | number;
  private unsubscriber: Unsubscriber;
  public subject = new BehaviorSubject<ErrorDetails>(null);
  public _control: AbstractControl;
  private formArrayName: FormArrayName;
  public ready = false;

  constructor(private form: FormGroupDirective,
    private injector: Injector
  ) { }

  get errors(): ValidationErrors {
    if (!this.ready) {
      return null;
    }
    return this.control.errors;
  }
  get hasErrors(): boolean {
    return !!this.errors;
  }

  @Input()
  formArrayIndex = -1;


  ngOnInit() {
    this.unsubscriber = new Unsubscriber();
    this.setControl();
  }

  hasError(name: string, conditions: ErrorOptions): boolean {
    return this.checkPropState('invalid', name, conditions);
  }
  isValid(name: string, conditions: ErrorOptions): boolean {
    return this.checkPropState('valid', name, conditions);
  }
  getError(name: string) {
    if (!this.ready) {
      return null;
    }
    return this.control.getError(name);
  }
  private checkPropState(prop: string, name: string, conditions: ErrorOptions): boolean {
    if (!this.ready) {
      return null;
    }
    const controlPropsState = (
      !conditions || toArray(conditions).every((condition: string) => this.control[condition])
    );
    if (name.charAt(0) === '*') {
      return this.control[prop] && controlPropsState;
    }
    return (
      prop === 'valid' ? !this.control.hasError(name) : this.control.hasError(name) && controlPropsState
    );
  }

  private checkStatus() {
    if (!this.control) {
      this.setControl();

      if (!this.control) {
        return;
      }
    }

    if (!this.subject) {
      return;
    }

    const errors = this.control.errors;
    if (!!errors) {
      for (const errorName in errors) {
        if (!!errorName) {
          this.subject.next({ control: this.control, errorName });
        }
      }
    }
    this.ready = true;
  }

  public get control() {
    return this._control;
  }

  ngOnChanges() {
    this.setControl();
  }

  ngAfterViewInit() {
    this.checkStatus();
    if (this.ready && this.control) {
      this.control.statusChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe(this.checkStatus.bind(this));
    }
  }

  private setControl(): void {
    if (this.formArrayIndex > -1 && !this.formArrayName) {
      this.formArrayName = this.injector.get(FormArrayName);
    }

    if (this.formArrayName && this.formArrayIndex >= 0) {
      this._control = (this.form.control.get(this.formArrayName.name) as FormArray).at(this.formArrayIndex);
      if (typeof this.xpoErrors === 'string') {
        this._control = this._control.get(this.xpoErrors);
      }
    } else {
      this._control = this.form.control.get(this.xpoErrors as string);
    }
  }

  ngOnDestroy() {
    if (this.subject) {
      this.subject.complete();
      this.subject = null;
    }
    if (this.unsubscriber) {
      this.unsubscriber.complete();
      this.unsubscriber = undefined;
    }
  }

}

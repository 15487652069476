import { NgModule } from '@angular/core';
import { XpoMinDateValidatorDirective } from './min-date.validators';
import { XpoOneInstanceValidatorDirective } from './one-instance.validators';
import { XpoPhoneFormatValidatorDirective } from './phone.validators';
import { XpoMaxDateValidatorDirective } from './max-date.validators';
import { XpoTimeFormatValidatorDirective } from './time-format.validators';
import { XpoProFormatValidatorDirective } from './pro-format.validators';
import { XpoNotSameValueValidatorDirective } from './not-same-value.validators';
import { XpoBadCharactersValidatorDirective } from './non-use-characters.validators';
import { XpoMaxTimeValidatorDirective } from './max-time.validators';
import { XpoWholeNumberValidatorDirective } from './whole.number.validators';
import { XpoDateValidatorDirective } from './xpo-date-validator.directive';
import { XpoNotGreaterThanDateValidatorDirective } from './not-greater-than-date.validators';
import { XpoNotGreaterThanValueValidatorDirective } from './not-greater-than-value.validators';
import { XpoNotLessThanDateValidatorDirective } from './not-less-than-date.validators';
import { XpoNotLessThanValueValidatorDirective } from './not-less-than-value.validators';
import { XpoEmailValidatorDirective } from './email-validator';

const directives = [
  XpoMaxDateValidatorDirective,
  XpoMinDateValidatorDirective,
  XpoOneInstanceValidatorDirective,
  XpoPhoneFormatValidatorDirective,
  XpoTimeFormatValidatorDirective,
  XpoProFormatValidatorDirective,
  XpoNotSameValueValidatorDirective,
  XpoWholeNumberValidatorDirective,
  XpoBadCharactersValidatorDirective,
  XpoMaxTimeValidatorDirective,
  XpoDateValidatorDirective,
  XpoNotGreaterThanDateValidatorDirective,
  XpoNotLessThanDateValidatorDirective,
  XpoNotGreaterThanValueValidatorDirective,
  XpoNotLessThanValueValidatorDirective,
  XpoEmailValidatorDirective
];

@NgModule({
  imports: [],
  exports: [
    ...directives
  ],
  declarations: [
    ...directives
  ],
})
export class XpoValidatorsModule { }

import { NativeDateAdapter } from '@angular/material';

export class XpoDateAdapter extends NativeDateAdapter {

    parse(value: any): Date | null {
      let dateWithNoSeparators;

      if (typeof value === 'number') {
        return new Date(value);
      }

      if (this._isDateWithNoSeparators(value) && (value.length === 6 || value.length === 8)) {
        const month = this._toSingleDigit(Number(value.slice(0, 2)));
        const date = this._toSingleDigit(Number(value.slice(2, 4)));
        const year = Number(value.slice(4));
        dateWithNoSeparators = `${month}/${date}/${year}`;
      }

      if (dateWithNoSeparators) {
        return new Date(Date.parse(dateWithNoSeparators));
      }

      return value ? new Date(Date.parse(value)) : null;
    }

    private _toSingleDigit(value): string {
      return value[0] === '0' ? value[1] : value;
    }

    private _isDateWithNoSeparators(val): boolean {
      return typeof val === 'string' && val.indexOf('/') === -1 && val.indexOf('-') === -1;
    }
}

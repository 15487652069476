import { XpoWarningsComponent } from './components/xpo-warnings/xpo-warnings.component';
import { NgModule } from '@angular/core';
import { InfrastructureApiService } from '@xpo-ltl/sdk-infrastructure';
import { HttpClientModule } from '@angular/common/http';
import { ConfigManagerModule } from '@xpo-ltl/config-manager';
import { RegionBannerComponent } from './components/region-banner/region-banner.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { MaterialModule } from './material.module';
import { VersionBannerComponent } from './components/version-banner/version-banner.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { DateAdapter } from '@angular/material';
import { XpoDateAdapter } from './classes/date.adapter';
import { XpoSpinnerComponent } from './components/spinner/xpo-spinner.component';

@NgModule({
  declarations: [
    FeedbackComponent,
    RegionBannerComponent,
    VersionBannerComponent,
    PaginatorComponent,
    XpoWarningsComponent,
    XpoSpinnerComponent
  ],
  imports: [
    HttpClientModule,
    MaterialModule,
    ConfigManagerModule
  ],
  entryComponents: [
    FeedbackComponent
  ],
  exports: [
    FeedbackComponent,
    RegionBannerComponent,
    VersionBannerComponent,
    PaginatorComponent,
    XpoWarningsComponent,
    XpoSpinnerComponent
  ],
  providers: [
    InfrastructureApiService,
    {
      provide: DateAdapter, useClass: XpoDateAdapter
    }
  ]
})
export class XpoAngularUtilsModule {
}

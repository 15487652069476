/**
 * ExceptionManagement API
 * Version: 1.0
 * Build: 1.0.0.40
 */

import {
	XrtSearchQueryHeader,
	XrtSearchRespHeader,
	KafkaMessageHeader,
	ListInfo,
	SearchRecordMessageHeader,
	XrtAttributeFilter,
	XrtSortExpression,
	AuditInfo,
	ActionCd,
	AgendaItemStatusCd,
	ExceptionLocationTypeCd,
	ExceptionStatusCd,
	ExceptionSummaryCd,
	ExceptionSummaryTypeCd,
	ExceptionTypeCd,
	ExpectationOwnerTypeCd,
	ExpectationStatusCd,
	RuleActiveTypeCd,
	RuleExpressionValueTypeCd,
	RuleLogicalOperatorCd,
	RuleOperatorCd,
	UserRoleCd,
	WorkflowSelectionModeCd
} from '@xpo-ltl/sdk-common';

/**
 * Path parameter used to create a freight exception.
 */
export class CreateExceptionPath {
	/**
	 * The unique identifier for the exception.
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
	/**
	 * The sequence number associated with the expecation detail.
	 * Required: true
	 * Array: false
	 */
	expectationDetailSequenceNbr:string = undefined;
}
/**
 * Request to create a freight exception.
 */
export class CreateExceptionRqst {
	/**
	 * A freight exception.
	 * Required: false
	 * Array: false
	 */
	exception:Exception = undefined;
}
/**
 * Response to the request to create a freight exception.
 */
export class CreateExceptionResp {
	/**
	 * The exception alert that was created.
	 * Required: false
	 * Array: false
	 */
	exceptionAlert:ExceptionAlert = undefined;
}
/**
 * Path parameter used to create expectations.
 */
export class CreateExpectationsPath {
	/**
	 * The code that represents the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Request to create expectations.
 */
export class CreateExpectationsRqst {
	/**
	 * One or more expectation headers.
	 * Required: false
	 * Array: true
	 */
	expectations:ExpectationHeader[] = undefined;
}
/**
 * Response to the request to create expectations.
 */
export class CreateExpectationsResp {
	/**
	 * A list of expectations that were created.
	 * Required: false
	 * Array: true
	 */
	expectations:ExpectationHeader[] = undefined;
}
/**
 * Path parameter used to create an expectation type.
 */
export class CreateExpectationTypePath {
	/**
	 * The code that represents the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Request to create an expectation type.
 */
export class CreateExpectationTypeRqst {
	/**
	 * The description of the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeDescription:string = undefined;
	/**
	 * A list of roles that will be assigned to the expectation type.
	 * Required: false
	 * Array: true
	 */
	expectationTypeRoles:ExpectationTypeRole[] = undefined;
}
/**
 * Response to the request create an expecation type.
 */
export class CreateExpectationTypeResp {
	/**
	 * The code associated with the type that was created.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Path parameter which is used to delete an exception.
 */
export class DeleteExceptionPath {
	/**
	 * The unique identifier for the exception.
	 * Required: true
	 * Array: false
	 */
	exceptionInstId:number = undefined;
}
/**
 * Response to the request to delete an exception.
 */
export class DeleteExceptionResp {
	/**
	 * The unique identifier for the exception.
	 * Required: true
	 * Array: false
	 */
	exceptionInstId:number = undefined;
}
/**
 * Path parameter used to delete an expectation type.
 */
export class DeleteExpectationTypePath {
	/**
	 * The code that represents the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Requeset to delete an expectation type.
 */
export class DeleteExpectationTypeRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	typeDescription:string = undefined;
}
/**
 * Response to the request to delete an expectation type.
 */
export class DeleteExpectationTypeResp {
	/**
	 * The code that represents the expectation type.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Path parameter used to delete an expectation type role.
 */
export class DeleteExpectationTypeRolePath {
	/**
	 * The unique identifier for the expectation type role.
	 * Required: true
	 * Array: false
	 */
	expectationTypeRoleInstId:number = undefined;
}
/**
 * Query parameters used to delete an expectation type role.
 */
export class DeleteExpectationTypeRoleQuery {
	/**
	 * The identifier associated with the employee role.
	 * Required: false
	 * Array: false
	 */
	emplRoleId:number = undefined;
	/**
	 * The code that represents the expectation type.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Path parameter used to retrieve an expectation header by instance ID.
 */
export class GetExpectationByInstIdPath {
	/**
	 * The unique identifier for the expectation header.
	 * Required: true
	 * Array: false
	 */
	expectationInstId:number = undefined;
}
/**
 * Response to the request to retrieve an expectation header by instance ID.
 */
export class GetExpectationByInstIdResp {
	/**
	 * The expectation header that matches the input criterium.
	 * Required: false
	 * Array: false
	 */
	expectation:ExpectationHeader = undefined;
}
/**
 * Query parameters used to filter a list of exceptions.
 */
export class ListExceptionsQuery {
	/**
	 * The location to filter by.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code used to filter by shift.
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The date that the exception was created.
	 * Required: false
	 * Array: false
	 */
	createdDate:Date = undefined;
	/**
	 * The code that identifies the region to filter by.
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * The type of exception to filter by.
	 * Required: false
	 * Array: false
	 */
	exceptionType:string = undefined;
	/**
	 * The trailer number associated with the exceptions.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * A list of allowed status codes for the exceptions.
	 * Required: false
	 * Array: true
	 */
	allowedStatusCds:string[] = undefined;
	/**
	 * The business identifier for the shipment associated with the exceptions.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
}
/**
 * Response for the request to list exception alert details.
 */
export class ListExceptionsResp {
	/**
	 * The list of exception alerts that match the filter criteria.
	 * Required: false
	 * Array: true
	 */
	exceptionAlerts:ExceptionAlert[] = undefined;
}
/**
 * Path parameters for list expectations
 */
export class ListExpectationsPath {
	/**
	 * Code indicating the owner.
	 * Required: true
	 * Array: false
	 */
	expectationOwnerTypeCd:ExpectationOwnerTypeCd = undefined;
	/**
	 * The identfier which corresponds to the owner type.
	 * Required: true
	 * Array: false
	 */
	expectationOwnerId:string = undefined;
}
/**
 * Query paramters used to filter the list of expectations.
 */
export class ListExpectationsQuery {
	/**
	 * The exception type. Optional filter to restrict to specified expectation type.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * If set to true, return only the current expectations for the given owner.
	 * Required: false
	 * Array: false
	 */
	currentInd:boolean = undefined;
}
/**
 * Response to the request to list expectations.
 */
export class ListExpectationsResp {
	/**
	 * List of expectation headers.
	 * Required: false
	 * Array: true
	 */
	expectations:ExpectationHeader[] = undefined;
}
/**
 * Request to list expectation types.
 */
export class ListExpectationTypesRqst {
}
/**
 * Response to the requst to list expectation types.
 */
export class ListExpectationTypesResp {
	/**
	 * The list of expectation types retrieved.
	 * Required: false
	 * Array: true
	 */
	expectationTypes:ExpectationType[] = undefined;
}
/**
 * Query parameters used to list linehaul exceptions.
 */
export class ListLinehaulExceptionsQuery {
	/**
	 * The unique identifier for the exception.
	 * Required: false
	 * Array: false
	 */
	exceptionInstId:number = undefined;
	/**
	 * Determines which user role will be used to filter the data. Possible values: FOM, FOS, ManagerSupervisorLinehaul, SCM.
	 * Required: false
	 * Array: false
	 */
	userRoleCd:UserRoleCd = undefined;
	/**
	 * The date for the plan that will be used to filter the exceptions/alerts.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * The type of exception used to filter the information.
	 * Required: false
	 * Array: false
	 */
	exceptionTypeCd:ExceptionTypeCd = undefined;
	/**
	 * The service center identification code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The status for the exception which is used to filter the information.
	 * Required: false
	 * Array: false
	 */
	exceptionStatusCd:ExceptionStatusCd = undefined;
}
/**
 * Response to the request to list linehaul exceptions.
 */
export class ListLinehaulExceptionsResp {
	/**
	 * The list of exceptions with their operation exceptions that match the filter criteria.
	 * Required: false
	 * Array: true
	 */
	exceptions:Exception[] = undefined;
}
/**
 * Query parameters used to filter the list of summarized exceptions by location.
 */
export class ListSummarizedExceptionsByLocationQuery {
	/**
	 * The date for the plan that will be used to filter the exceptions/alerts.
	 * Required: true
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Determines which User Role will be used to filter the data. Possible values: FOM, FOS, ManagerSupervisorLinehaul, SCM.
	 * Required: true
	 * Array: false
	 */
	userRoleCd:UserRoleCd = undefined;
	/**
	 * This parameter defines which group type will be used to filter the data. Possible values: 
	 * 						Load,
	 * 						PowerManagement,
	 * 						Shipment
	 * Required: true
	 * Array: false
	 */
	exceptionSummaryCd:ExceptionSummaryCd = undefined;
	/**
	 * This parameter defines if it is an Alert or an Exception. Possible values: 
	 * 						Alert,
	 * 						Exception
	 * Required: true
	 * Array: false
	 */
	exceptionSummaryTypeCd:ExceptionSummaryTypeCd = undefined;
	/**
	 * This parameter defines the status depending on the type selected. 
	 * 						If the type selected is 'Exception' the possible values are:
	 * 							Pending
	 * 							Responded
	 * 							Completed
	 * 						If the type selected is 'Alert' the possible values are:
	 * 							Pending
	 * 							Acknowledged
	 * 							Closed
	 * 							Resolved
	 * 							Expired
	 * Required: true
	 * Array: false
	 */
	exceptionStatusCd:ExceptionStatusCd = undefined;
	/**
	 * This parameter defines the exception type. Posible values:
	 * 						AddLoadRequest,
	 * 						PartialBypass,
	 * 						CutLoadRequest,
	 * 						HSS,
	 * 						LoadRequest,
	 * 						G!Misload,
	 * 						ProgressiveLoad
	 * Required: true
	 * Array: false
	 */
	exceptionTypeCd:ExceptionTypeCd = undefined;
	/**
	 * This parameter defines which location type will be used to filter the data. Possible values: 
	 * 						District,
	 * 						LinehaulRegion,
	 * 						LinehaulSIC,
	 * 						OperationalRegion,
	 * 						OperationSIC
	 * Required: true
	 * Array: false
	 */
	exceptionLocationTypeCd:ExceptionLocationTypeCd = undefined;
	/**
	 * The values used to filter the results based on the selected ExceptionLocationTypeCd. The values should be the codes of the registry in the database. Example:
	 * 				exceptionLocationTypeCd = 'DISTRICT' and locationFilters = ['OAG', 'OCA'], to filter through the DISTRICTs 'ATLANTA DISTRICT' and 'CANADA DISTRICT'
	 *                 exceptionLocationTypeCd = 'LH_REGION' and locationFilters = ['LH11', 'LH10'], to filter by LINEHAUL REGIONs LH11 and LH10
	 *                 exceptionLocationTypeCd = 'LH_SIC' and locationFilters = ['NAA', 'NAB'], to filter by the SICs 'NAA' and 'NAB'
	 *                 exceptionLocationTypeCd = 'OPS_REGION' and locationFilters = ['CEA', 'CCA'], to filter by REGIONs Eastern and Mid-West
	 *                 exceptionLocationTypeCd = 'OPS_SIC' and locationFilters = ['NAA', 'NAB'], to filter by the SICs 'NAA' and 'NAB'
	 * Required: false
	 * Array: true
	 */
	locationFilters:string[] = undefined;
}
/**
 * Response to the request to list summarized exceptions by location.
 */
export class ListSummarizedExceptionsByLocationResp {
	/**
	 * The list of groups with lists of Exception or Alert counts and oldest exception/alert timestamps.
	 * Required: false
	 * Array: true
	 */
	exceptionAlertByTypes:ExceptionAlertByType[] = undefined;
}
/**
 * Query parameters used to list summarized exceptions by status.
 */
export class ListSummarizedExceptionsByStatusQuery {
	/**
	 * The date for the plan that will be used to filter the exceptions/alerts.
	 * Required: true
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Determines which User Role will be used to filter the data. Possible values: FOM, FOS, ManagerSupervisorLinehaul, SCM.
	 * Required: true
	 * Array: false
	 */
	userRoleCd:UserRoleCd = undefined;
	/**
	 * This parameter defines which location type will be used to filter the data. Possible values: 
	 * 						District,
	 * 						LinehaulRegion,
	 * 						LinehaulSIC,
	 * 						OperationalRegion,
	 * 						OperationSIC
	 * Required: false
	 * Array: false
	 */
	exceptionLocationTypeCd:ExceptionLocationTypeCd = undefined;
	/**
	 * The values used to filter the results based on the selected ExceptionLocationTypeCd. The values should be the codes of the registry in the database. Example:
	 * 				exceptionLocationTypeCd = 'DISTRICT' and locationFilters = ['OAG', 'OCA'], to filter through the DISTRICTs 'ATLANTA DISTRICT' and 'CANADA DISTRICT'
	 *                 exceptionLocationTypeCd = 'LH_REGION' and locationFilters = ['LH11', 'LH10'], to filter by LINEHAUL REGIONs LH11 and LH10
	 *                 exceptionLocationTypeCd = 'LH_SIC' and locationFilters = ['NAA', 'NAB'], to filter by the SICs 'NAA' and 'NAB'
	 *                 exceptionLocationTypeCd = 'OPS_REGION' and locationFilters = ['CEA', 'CCA'], to filter by REGIONs Eastern and Mid-West
	 *                 exceptionLocationTypeCd = 'OPS_SIC' and locationFilters = ['NAA', 'NAB'], to filter by the SICs 'NAA' and 'NAB'
	 * Required: false
	 * Array: true
	 */
	locationFilters:string[] = undefined;
}
/**
 * Response to the request to list summarized exceptions by status.
 */
export class ListSummarizedExceptionsByStatusResp {
	/**
	 * The list of groups with lists of Exception and/or Alert counts and the oldest exception/alert timestamp.
	 * Required: false
	 * Array: true
	 */
	exceptionSummaries:ExceptionSummary[] = undefined;
}
/**
 * Query parameters used to list summarized exceptions by type.
 */
export class ListSummarizedExceptionsByTypeQuery {
	/**
	 * The date for the plan that will be used to filter the exceptions/alerts.
	 * Required: true
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * The end of the range that will be used to filter the exceptions/alerts by the creation date.
	 * Required: true
	 * Array: false
	 */
	dashboardViewDateTo:Date = undefined;
	/**
	 * Determines which User Role will be used to filter the data. Possible values: FOM, FOS, ManagerSupervisorLinehaul, SCM.
	 * Required: true
	 * Array: false
	 */
	userRoleCd:UserRoleCd = undefined;
	/**
	 * This parameter defines which group type will be used to filter the data. Possible values: 
	 * 						Load,
	 * 						PowerManagement,
	 * 						Shipment
	 * Required: true
	 * Array: false
	 */
	exceptionSummaryCd:ExceptionSummaryCd = undefined;
	/**
	 * This parameter defines if it is an Alert or an Exception. Possible values: 
	 * 						Alert,
	 * 						Exception
	 * Required: true
	 * Array: false
	 */
	exceptionSummaryTypeCd:ExceptionSummaryTypeCd = undefined;
	/**
	 * This parameter define the status depending on the type selected. 
	 * 						If the type selected is 'Exception' the possible values are:
	 * 							Pending
	 * 							Responded
	 * 							Completed
	 * 						If the type selected is 'Alert' the possible values are:
	 * 							Pending
	 * 							Acknowledged
	 * 							Closed
	 * 							Resolved
	 * 							Expired
	 * Required: true
	 * Array: false
	 */
	exceptionStatusCd:ExceptionStatusCd = undefined;
	/**
	 * This parameter defines which location type will be used to filter the data. Possible values: 
	 * 						District,
	 * 						LinehaulRegion,
	 * 						LinehaulSIC,
	 * 						OperationalRegion,
	 * 						OperationSIC
	 * Required: true
	 * Array: false
	 */
	exceptionLocationTypeCd:ExceptionLocationTypeCd = undefined;
	/**
	 * The values used to filter the results based on the selected ExceptionLocationTypeCd. The values should be the codes of the registry in the database. Example:
	 * 				exceptionLocationTypeCd = 'DISTRICT' and locationFilters = ['OAG', 'OCA'], to filter through the DISTRICTs 'ATLANTA DISTRICT' and 'CANADA DISTRICT'
	 *                 exceptionLocationTypeCd = 'LH_REGION' and locationFilters = ['LH11', 'LH10'], to filter by LINEHAUL REGIONs LH11 and LH10
	 *                 exceptionLocationTypeCd = 'LH_SIC' and locationFilters = ['NAA', 'NAB'], to filter by the SICs 'NAA' and 'NAB'
	 *                 exceptionLocationTypeCd = 'OPS_REGION' and locationFilters = ['CEA', 'CCA'], to filter by REGIONs Eastern and Mid-West
	 *                 exceptionLocationTypeCd = 'OPS_SIC' and locationFilters = ['NAA', 'NAB'], to filter by the SICs 'NAA' and 'NAB'
	 * Required: false
	 * Array: true
	 */
	locationFilters:string[] = undefined;
}
/**
 * Response to get count and age of all Exceptions or alerts.
 */
export class ListSummarizedExceptionsByTypeResp {
	/**
	 * This parameter define the status depending on the type selected. 
	 * 						If the type selected is "Exception" the possible values are:
	 * 							Pending
	 * 							Responded
	 * 							Completed
	 * 						If the type selected is "Alert" the possible values are:
	 * 							Pending
	 * 							Acknowledged
	 * 							Closed
	 * 							Resolved
	 * 							Expired
	 * Required: true
	 * Array: false
	 */
	exceptionStatusCd:ExceptionStatusCd = undefined;
	/**
	 * List of counts with lists of Exceptions or Alerts types, counts and oldest exception/alert timestamp.
	 * Required: false
	 * Array: true
	 */
	exceptionAlertCounts:ExceptionAlertCount[] = undefined;
}
/**
 * Request for searching exceptions.
 */
export class SearchExceptionsRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * The query for searching elastic/XRT
	 * Required: false
	 * Array: false
	 */
	filter:ExpectationSearchFilter = undefined;
}
/**
 * Response to the request to search exceptions.
 */
export class SearchExceptionsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	results:ExpectationSearchRecord[] = undefined;
}
/**
 * Path parameter used to update a freight exception.
 */
export class UpdateExceptionPath {
	/**
	 * The unique identifier for the exception.
	 * Required: true
	 * Array: false
	 */
	exceptionInstId:string = undefined;
}
/**
 * Request to update a freight exception.
 */
export class UpdateExceptionRqst {
	/**
	 * The exception detail that will update the existing record.
	 * Required: false
	 * Array: false
	 */
	exception:Exception = undefined;
	/**
	 * The operation name which is used to determine the exception status.
	 * Required: true
	 * Array: false
	 */
	operationName:string = undefined;
}
/**
 * Path parameter used to update the status of an expectation.
 */
export class UpdateExpectationStatusPath {
	/**
	 * The unique identifier of the expectation.
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
	/**
	 * The sequence number associated with the expectation.
	 * Required: true
	 * Array: false
	 */
	expectationDetailSequenceNbr:string = undefined;
}
/**
 * Request to update the status of an expectation.
 */
export class UpdateExpectationStatusRqst {
	/**
	 * The code associated with the status which will be updated for the expecation.  Valid transitions are New to Met or Exception and Exception to Met.
	 * Required: true
	 * Array: false
	 */
	statusCd:ExpectationStatusCd = undefined;
}
/**
 * Path parameter used to update an expectation type.
 */
export class UpdateExpectationTypePath {
	/**
	 * The code that represents the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Request to update an expectation type.
 */
export class UpdateExpectationTypeRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	typeDescription:string = undefined;
	/**
	 * A list of expectation type roles.
	 * Required: false
	 * Array: true
	 */
	expectationTypeRoles:ExpectationTypeRole[] = undefined;
}
/**
 * Response to the request to update an expectation type.
 */
export class UpdateExpectationTypeResp {
	/**
	 * The code that represents the expectation type.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Request to upsert an expectation type role.
 */
export class UpsertExpectationTypeRoleRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	expectationTypeRoleInstId:number = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	emplRoleId:number = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	actionCd:ActionCd = undefined;
}
/**
 * Response to the request to upsert an expectation type role.
 */
export class UpsertExpectationTypeRoleResp {
	/**
	 * The expectation type role that was upserted.
	 * Required: false
	 * Array: false
	 */
	expectationType:ExpectationType = undefined;
}
/**
 * Elastic Search document for Expectation details
 */
export class ExpectationSearchRecord {
	/**
	 * The id of the expectation
	 * Required: false
	 * Array: false
	 */
	expectationInstId:number = undefined;
	/**
	 * Shift Code this Expectations applies to
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * SIC the expectation was created for
	 * Required: false
	 * Array: false
	 */
	expectationSic:string = undefined;
	/**
	 * The region that the Expectation SIC is in.
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * The district that the Expectation SIC is in.
	 * Required: false
	 * Array: false
	 */
	district:string = undefined;
	/**
	 * The operational region that the Expectation SIC is in.
	 * Required: false
	 * Array: false
	 */
	operationalRegion:string = undefined;
	/**
	 * This is the timestamp when this row was created in the search repository.
	 * Required: false
	 * Array: false
	 */
	createdTmst:Date = undefined;
	/**
	 * This is the timestamp when this row was inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * This is the timestamp when the original event was created.
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The expectation header along with the exception details
	 * Required: false
	 * Array: false
	 */
	expectationHeader:ExpectationHeader = undefined;
}
/**
 * Exception and expectation header details which are used for alerts.
 */
export class ExceptionAlert {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exception:Exception = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationHeader:ExpectationHeader = undefined;
}
/**
 * Exceptions and alerts grouped by type of group (Load Request, Shipments, Power Management).
 */
export class ExceptionSummary {
	/**
	 * The name of the group of exceptions and/or alerts: Load Request, Shipments, Power Management.
	 * Required: false
	 * Array: false
	 */
	groupName:string = undefined;
	/**
	 * List of exceptions with information about type, count and date.
	 * Required: false
	 * Array: true
	 */
	exceptions:ExceptionAlertCount[] = undefined;
	/**
	 * List of alerts with information about type, count and date.
	 * Required: false
	 * Array: true
	 */
	alerts:ExceptionAlertCount[] = undefined;
}
/**
 * Contains the information of exceptions and alerts grouped REGIONS, DISTRICT or SICs
 */
export class ExceptionAlertByType {
	/**
	 * Main header which discribes how the response is grouped by (Type, Location or Region)
	 * Required: false
	 * Array: false
	 */
	parent:string = undefined;
	/**
	 * List of exceptions or Alerts with information about type, count and date.
	 * Required: false
	 * Array: true
	 */
	exceptionAlertCountByLocation:ExceptionAlertCountByLocation[] = undefined;
}
/**
 * The count grouped by exception/alert type.
 */
export class ExceptionAlertCount {
	/**
	 * Exception/Alert type. Example: CUT, ADD, HSS, PROG_LOAD, MISLOAD_GUR.
	 * Required: false
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * The total number of exceptions/alerts by type
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
	/**
	 * Represents the oldest date of the grouped exception/alert by type.
	 * Required: false
	 * Array: false
	 */
	oldestDate:Date = undefined;
}
/**
 * Contains more information about Exception or Alert grouped by Type, groupType and/or status.
 */
export class ExceptionAlertCountByLocation {
	/**
	 * Location Code type. Example: 'CWA', 'OAG', 'NAA', etc.
	 * Required: true
	 * Array: false
	 */
	locationCd:string = undefined;
	/**
	 * Location Name. Example: 'ATLANTA DISTRICT', 'NAA', 'Eastern', 'Mid West', 'Western', etc.
	 * Required: true
	 * Array: false
	 */
	locationName:string = undefined;
	/**
	 * Represents the oldest date of the grouped exception/alert
	 * Required: true
	 * Array: false
	 */
	exceptionAlertCount:ExceptionAlertCount = undefined;
}
/**
 * Request for adding an item to a queue.
 */
export class AddItemToQueueRqst {
	/**
	 * Instance Id for the application item that is being added  For example for a Dispute item, this is the Dispute instance id from the dispute source table.
	 * Required: true
	 * Array: false
	 */
	applicationInstId:string = undefined;
	/**
	 * The employee id that the item should be assigned to.
	 * Required: true
	 * Array: false
	 */
	assigneeEmployeeId:string = undefined;
	/**
	 * Contextual object for mapping the actual item type.
	 * Required: false
	 * Array: false
	 */
	context:Exception = undefined;
	/**
	 * User supplied comment about the status change on an item.
	 * Required: false
	 * Array: false
	 */
	statusUpdateComment:string = undefined;
	/**
	 * Optional Queue Name(s) to add item too.
	 * Required: false
	 * Array: true
	 */
	queueName:string[] = undefined;
}
/**
 * Response for adding item to a queue.
 */
export class AddItemToQueueResp {
	/**
	 * Item that was added to a queue.
	 * Required: false
	 * Array: true
	 */
	items:ItemQueueIdentifier[] = undefined;
}
/**
 * Request for approving an item
 */
export class ApproveItemRqst {
	/**
	 * Instance Id for the item that is being approved.
	 * Required: true
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * Contextual object for mapping the actual item type.
	 * Required: false
	 * Array: false
	 */
	context:Exception = undefined;
	/**
	 * User supplied comment about the status change on an item.
	 * Required: false
	 * Array: false
	 */
	statusUpdateComment:string = undefined;
}
/**
 * Response for approving an item
 */
export class ApproveItemResp {
	/**
	 * Item that was approved
	 * Required: false
	 * Array: true
	 */
	item:Item[] = undefined;
	/**
	 * The queue the item is contained in.
	 * Required: false
	 * Array: false
	 */
	queue:Queue = undefined;
}
/**
 * Request for assigning an item to an employee
 */
export class AssignItemRqst {
	/**
	 * Instance Id for the item that is being assigned.
	 * Required: true
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * The employee id that the item should be assigned to.
	 * Required: true
	 * Array: false
	 */
	assigneeEmployeeId:string = undefined;
	/**
	 * Role Id for assignee employee.
	 * Required: true
	 * Array: false
	 */
	assigneeEmplRoleId:number = undefined;
	/**
	 * Contextual object for mapping the actual item type.
	 * Required: false
	 * Array: false
	 */
	context:Exception = undefined;
	/**
	 * User supplied comment about the status change on an item.
	 * Required: false
	 * Array: false
	 */
	statusUpdateComment:string = undefined;
}
/**
 * Response for assigning an item to an employee
 */
export class AssignItemResp {
	/**
	 * Item that was assigned to an employee
	 * Required: false
	 * Array: false
	 */
	item:Item = undefined;
	/**
	 * The queue the item is contained in.
	 * Required: false
	 * Array: false
	 */
	queue:Queue = undefined;
}
/**
 * Request for canceling an item
 */
export class CancelItemRqst {
	/**
	 * Instance Id for the item that is being cancelled.
	 * Required: true
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * Contextual object for mapping the actual item type.
	 * Required: false
	 * Array: false
	 */
	context:Exception = undefined;
	/**
	 * User supplied comment about the status change on an item.
	 * Required: false
	 * Array: false
	 */
	statusUpdateComment:string = undefined;
}
/**
 * Response for canceling an item
 */
export class CancelItemResp {
	/**
	 * Item that was cancelled
	 * Required: false
	 * Array: false
	 */
	item:Item = undefined;
	/**
	 * The queue the item is contained in.
	 * Required: false
	 * Array: false
	 */
	queue:Queue = undefined;
}
/**
 * Request for declining an item
 */
export class DeclineItemRqst {
	/**
	 * Instance Id for the item that is being declined.
	 * Required: true
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * Contextual object for mapping the actual item type.
	 * Required: false
	 * Array: false
	 */
	context:Exception = undefined;
	/**
	 * User supplied comment about the status change on an item.
	 * Required: false
	 * Array: false
	 */
	statusUpdateComment:string = undefined;
}
/**
 * Response for declining an item
 */
export class DeclineItemResp {
	/**
	 * Item that was approved
	 * Required: false
	 * Array: false
	 */
	item:Item = undefined;
	/**
	 * The queue the item is contained in.
	 * Required: false
	 * Array: false
	 */
	queue:Queue = undefined;
}
/**
 * Request for get item by instance id
 */
export class GetItemPath {
	/**
	 * Instance Id for the item.
	 * Required: true
	 * Array: false
	 */
	itemInstId:number = undefined;
}
/**
 * Response for get item by instance id
 */
export class GetItemResp {
	/**
	 * Item details
	 * Required: false
	 * Array: false
	 */
	item:Item = undefined;
	/**
	 * The queue the item is contained in.
	 * Required: false
	 * Array: false
	 */
	queue:Queue = undefined;
}
/**
 * Request for listing of items.
 */
export class ListItemsByApplicationIdQuery {
	/**
	 * Instance Id for the application item. For example for a Dispute item, this is the Dispute instance id from the dispute source table.
	 * Required: true
	 * Array: false
	 */
	applicationInstId:string = undefined;
	/**
	 * Desc: Current status of the item in the queue.  Valid values:  TBD
	 * Required: false
	 * Array: false
	 */
	currentStatusCd:string = undefined;
	/**
	 * Desc: Employee that is currently assigned to the item in the queue.
	 * Required: false
	 * Array: false
	 */
	assigneeEmployeeId:string = undefined;
	/**
	 * Desc: Employee that currently has the item locked for editing.
	 * Required: false
	 * Array: false
	 */
	lockedByEmployeeId:string = undefined;
	/**
	 * IDM/HRS role of the employee taking action on the queue item. 4/15/19 Added
	 * Required: false
	 * Array: false
	 */
	assigneeEmplRoleId:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of items request.
 */
export class ListItemsByApplicationIdResp {
	/**
	 * All items are returned.
	 * Required: false
	 * Array: true
	 */
	items:ItemQueueDetail[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for unassigning an item from an employee
 */
export class UnassignItemRqst {
	/**
	 * Instance Id for the item that is being unassigned.
	 * Required: true
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * Contextual object for mapping the actual item type.
	 * Required: false
	 * Array: false
	 */
	context:Exception = undefined;
	/**
	 * User supplied comment about the status change on an item.
	 * Required: false
	 * Array: false
	 */
	statusUpdateComment:string = undefined;
}
/**
 * Response for unassigning an item from an employee
 */
export class UnassignItemResp {
	/**
	 * Item that was unassigned
	 * Required: false
	 * Array: false
	 */
	item:Item = undefined;
	/**
	 * The queue the item is contained in.
	 * Required: false
	 * Array: false
	 */
	queue:Queue = undefined;
}
/**
 * Request for approving an item
 */
export class UpdateItemStatusRqst {
	/**
	 * Instance Id for the item that is being updated.
	 * Required: true
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * Status of the item
	 * Required: false
	 * Array: false
	 */
	itemStatus:string = undefined;
	/**
	 * Contextual object for mapping the actual item type.
	 * Required: false
	 * Array: false
	 */
	context:Exception = undefined;
	/**
	 * User supplied comment about the status change on an item.
	 * Required: false
	 * Array: false
	 */
	statusUpdateComment:string = undefined;
}
/**
 * Response for updating the status of an item
 */
export class UpdateItemStatusResp {
	/**
	 * Item whose status was changed
	 * Required: false
	 * Array: false
	 */
	item:Item = undefined;
	/**
	 * The queue the item is contained in.
	 * Required: false
	 * Array: false
	 */
	queue:Queue = undefined;
}
/**
 * Request for listing of items.
 */
export class ListItemsQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	queueInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	assigneeEmployeeId:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of items request.
 */
export class ListItemsResp {
	/**
	 * All items are returned.
	 * Required: false
	 * Array: true
	 */
	items:Item[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for listing of Priorities.
 */
export class ListPrioritiesRqst {
}
/**
 * Response for listing of Priorities request.
 */
export class ListPrioritiesResp {
	/**
	 * All Priorities are returned.
	 * Required: false
	 * Array: true
	 */
	priorities:Priority[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for listing of queues.
 */
export class ListWorkflowQueuesRqst {
}
/**
 * Response for listing of queues request.
 */
export class ListWorkflowQueuesResp {
	/**
	 * All queues are returned.
	 * Required: false
	 * Array: true
	 */
	queues:Queue[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for listing of QueueRoleAssignments.
 */
export class ListQueueRoleAssignmentsQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	queueInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	employeeRoleId:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of QueueRoleAssignments request.
 */
export class ListQueueRoleAssignmentsResp {
	/**
	 * All QueueRoleAssignments are returned.
	 * Required: false
	 * Array: true
	 */
	queueRoleAssignments:QueueRoleAssignment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for listing of status logs for an item
 */
export class ListStatusLogsForItemQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of status logs for an item
 */
export class ListStatusLogsForItemResp {
	/**
	 * All status logs are returned.
	 * Required: false
	 * Array: true
	 */
	statusLogs:StatusLog[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for updating or inserting a Priority.
 */
export class UpsertPriorityRqst {
	/**
	 * Priority being updated or inserted.
	 * Required: true
	 * Array: false
	 */
	priority:Priority = undefined;
}
/**
 * Request for updating or inserting a Queue.
 */
export class UpsertQueueRqst {
	/**
	 * Queue being updated or inserted.
	 * Required: true
	 * Array: false
	 */
	queue:Queue = undefined;
}
/**
 * Request for updating or inserting a QueueRoleAssignment.
 */
export class UpsertQueueRoleAssignmentRqst {
	/**
	 * QueueRoleAssignment being updated or inserted.
	 * Required: true
	 * Array: false
	 */
	queueRoleAssignment:QueueRoleAssignment = undefined;
}
/**
 * Request for listing of items.
 */
export class ListItemsByQueueNamePath {
	/**
	 * Desc: Name of the queue.
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Request for listing of items.
 */
export class ListItemsByQueueNameQuery {
	/**
	 * Desc: Current status of the item in the queue.  Valid values:  TBD
	 * Required: false
	 * Array: false
	 */
	currentStatusCd:string = undefined;
	/**
	 * Desc: Employee that is currently assigned to the item in the queue.
	 * Required: false
	 * Array: false
	 */
	assigneeEmployeeId:string = undefined;
	/**
	 * Desc: Employee that currently has the item locked for editing.
	 * Required: false
	 * Array: false
	 */
	lockedByEmployeeId:string = undefined;
	/**
	 * IDM/HRS role of the employee taking action on the queue item. 4/15/19 Added
	 * Required: false
	 * Array: false
	 */
	assigneeEmplRoleId:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of items request.
 */
export class ListItemsByQueueNameResp {
	/**
	 * All items are returned.
	 * Required: false
	 * Array: true
	 */
	items:ItemQueueDetail[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Detail for Item and its Queue
 */
export class ItemQueueDetail {
	/**
	 * Required: false
	 * Array: false
	 */
	item:Item = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	queue:Queue = undefined;
}
/**
 * Identifiers for an Item and its Queue
 */
export class ItemQueueIdentifier {
	/**
	 * Required: false
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	queueInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Request to create or update a rule.
 * 			
 */
export class UpsertRuleRequest {
	/**
	 * The Rule to be created or updated
	 * Required: false
	 * Array: false
	 */
	rule:Rule = undefined;
}
/**
 * Response for the request to create or update a Rule.
 */
export class UpsertRuleResp {
	/**
	 * The rule unique identifier; generated when the rule is created
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
}
/**
 * Request for listing of rules.
 */
export class ListRulesQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of Rules request.
 */
export class ListRulesResp {
	/**
	 * All rules are returned.
	 * Required: false
	 * Array: true
	 */
	rules:Rule[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to get a rule by identifier.
 */
export class GetRulePath {
	/**
	 * The rule unique identifier; generated when the rule is created
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
}
/**
 * Response to get a rule by identifier.
 */
export class GetRuleResp {
	/**
	 * Rule details
	 * Required: false
	 * Array: false
	 */
	rule:Rule = undefined;
}
/**
 * Request to get a rule by name.
 */
export class GetRuleByNamePath {
	/**
	 * Descriptive Name of the rule
	 * Required: false
	 * Array: false
	 */
	ruleName:string = undefined;
}
/**
 * Response to get a rule by name.
 */
export class GetRuleByNameResp {
	/**
	 * Rule details
	 * Required: false
	 * Array: false
	 */
	rule:Rule = undefined;
}
/**
 * Request for deleting a rule.
 */
export class DeleteRulePath {
	/**
	 * The rule unique identifier; generated when the rule is created
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
}
/**
 * Request to execute a rule
 */
export class ExecuteRuleRqst {
	/**
	 * The name of the rule to be executed
	 * Required: false
	 * Array: false
	 */
	ruleName:string = undefined;
	/**
	 * Desc: Identifies a rule as being a precondition rule.  Precondition rules are used to determine which rules to run.
	 * Required: false
	 * Array: false
	 */
	preconditionInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	context:Exception = undefined;
}
/**
 * Response of the request to execute a rule 
 */
export class ExecuteRuleResp {
	/**
	 * Rule details with the execution outcomes
	 * Required: false
	 * Array: false
	 */
	rule:Rule = undefined;
}
/**
 * Request for listing of rules by rulesetName.
 */
export class ListRulesByRulesetNameQuery {
	/**
	 * Date used to identify active rules.
	 * Required: false
	 * Array: false
	 */
	ruleEffectiveDate:Date = undefined;
	/**
	 * Descriptive Names of the rulesets to include.
	 * Required: false
	 * Array: true
	 */
	rulesetNameInclusion:string[] = undefined;
	/**
	 * Descriptive Names of the rulesets to exclude.
	 * Required: false
	 * Array: true
	 */
	rulesetNameExclusion:string[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of Rules By RulesetName request.
 */
export class ListRulesByRulesetNameResp {
	/**
	 * All rules matching the parameters in the request are returned.
	 * Required: false
	 * Array: true
	 */
	rules:RuleForExecution[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to list rules by rule expressions.
 */
export class ListRulesByExpressionsRqst {
	/**
	 * Rule Expressions to filter the rules.
	 * Required: false
	 * Array: true
	 */
	ruleExpressions:RuleExpression[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of Rules By Expressions request.
 */
export class ListRulesByExpressionsResp {
	/**
	 * All rules matching the expressions in the request are returned.
	 * Required: false
	 * Array: true
	 */
	rules:Rule[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * The basic rule data needed for execution
 */
export class RuleForExecution {
	/**
	 * Desc: The rule Unique identifier; generated when rule is created
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
	/**
	 * Desc: Descriptive Name of the rule
	 * Required: false
	 * Array: false
	 */
	ruleName:string = undefined;
	/**
	 * Desc: Identifies a rule as being a precondition rule.  Precondition rules are used to determine which rules to run.
	 * Required: false
	 * Array: false
	 */
	preconditionInd:boolean = undefined;
	/**
	 * The rule details. A ruleset is associated with a group of conditions(expressions) to evaluate using a specific logical operator. If the evaluation of the conditions is true, the ruleset will return a group of outcomes.
	 * Required: false
	 * Array: true
	 */
	ruleset:RulesetForExecution[] = undefined;
}
/**
 * The rule details. A ruleset is associated with a group of conditions(expressions) to evaluate using a specific logical operator. If the evaluation of the conditions is true, the ruleset will return a group of outcomes.
 */
export class RulesetForExecution {
	/**
	 * Desc: The ruleset Unique identifier; generated when the ruleset is created
	 * Required: false
	 * Array: false
	 */
	rulesetInstId:number = undefined;
	/**
	 * The priority order for the execution of the ruleset. If a rule is associated with multiple rulesets, the ruleset with the highest priority is executed first. The number 1 is a higher priority than 2 and so on.
	 * Required: false
	 * Array: false
	 */
	priorityOrderNbr:number = undefined;
	/**
	 * Desc: Identifies the logical operator value. The Valid Values are: AND, OR
	 * Required: false
	 * Array: false
	 */
	logicalOperatorCd:RuleLogicalOperatorCd = undefined;
	/**
	 * Desc: The action that should be performed if the logical operator function is true.
	 * Required: false
	 * Array: false
	 */
	actionCallBack:string = undefined;
	/**
	 * Descriptive Name of the ruleset.  Added 5/1/2019
	 * Required: false
	 * Array: false
	 */
	rulesetName:string = undefined;
	/**
	 * The group of conditions(expressions) to be evaluated for the ruleset.
	 * Required: false
	 * Array: true
	 */
	ruleExpression:RuleExpressionForExecution[] = undefined;
	/**
	 * The group of outcomes to be returned if the conditions of the ruleset are evaluated to true.
	 * Required: false
	 * Array: true
	 */
	ruleOutcome:RuleOutcomeForExecution[] = undefined;
}
/**
 * The outcome is a key,value entry to be returned if the conditions of the ruleset are evaluated to true.
 */
export class RuleOutcomeForExecution {
	/**
	 * Desc: the outcome Unique identifier; generated when the outcome is created.
	 * Required: false
	 * Array: false
	 */
	outcomeInstId:number = undefined;
	/**
	 * Added as an additional part of PK so that Outcome name/value pairs can be grouped. 4/15/2019
	 * Required: false
	 * Array: false
	 */
	outcomeSeqNbr:number = undefined;
	/**
	 * Desc: The key/name of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeName:string = undefined;
	/**
	 * The value of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeValue:string = undefined;
}
/**
 * A rule expression is a condition to be evaluated in the rule.
 */
export class RuleExpressionForExecution {
	/**
	 * The generated unique identifier.
	 * Required: false
	 * Array: false
	 */
	expressionInstId:number = undefined;
	/**
	 * Desc: Name of the attribute to be used in the logical expression.
	 * Required: false
	 * Array: false
	 */
	leftOperand:string = undefined;
	/**
	 * Desc: The operand to be used in the logical expression. The valid values are: 
	 * EQUAL_TO
	 * NOT_EQUAL_TO
	 * GREATER_THAN
	 * GREATER_THAN_OR_EQUAL
	 * IN
	 * NOT_IN
	 * LESS_THAN
	 * LESS_THAN_OR_EQUAL
	 * MATCHES
	 * NOT_MATCHES
	 * Required: false
	 * Array: false
	 */
	operatorCd:RuleOperatorCd = undefined;
	/**
	 * Desc: The type of value of the attribute to be used in the logical expression.. The valid values are:
	 * BOOLEAN
	 * DATE_TIME
	 * LIST
	 * NUMBER
	 * TEXT
	 * Required: false
	 * Array: false
	 */
	valueTypeCd:RuleExpressionValueTypeCd = undefined;
	/**
	 * Desc: The value of the attribute to be used in the logical expression.
	 * Required: false
	 * Array: false
	 */
	value:string = undefined;
}
/**
 * Response for listing of Rules for execution request.
 */
export class ListRulesForExecutionQuery {
	/**
	 * Date used to identify active rules.
	 * Required: false
	 * Array: false
	 */
	ruleEffectiveDate:Date = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of Rules for execution request.
 */
export class ListRulesForExecutionResp {
	/**
	 * All rules are returned.
	 * Required: false
	 * Array: true
	 */
	rules:RuleForExecution[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response outcome from a ruleset
 */
export class RulesetOutcomeResp {
	/**
	 * Desc: The ruleset Unique identifier; generated when the ruleset is created
	 * Required: false
	 * Array: false
	 */
	rulesetInstId:number = undefined;
	/**
	 * Desc: the outcome Unique identifier; generated when the outcome is created.
	 * Required: false
	 * Array: false
	 */
	outcomeInstId:number = undefined;
	/**
	 * Added as an additional part of PK so that Outcome name/value pairs can be grouped. 4/15/2019
	 * Required: false
	 * Array: false
	 */
	outcomeSeqNbr:number = undefined;
	/**
	 * Desc: The key/name of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeName:string = undefined;
	/**
	 * The value of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeValue:string = undefined;
}
/**
 * An Update response from a BULK update to elastic
 */
export class BulkUpdateResp {
	/**
	 * Required: false
	 * Array: false
	 */
	_index:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	_id:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	error:BulkUpdateItemError = undefined;
}
/**
 * Details of an error for an item
 */
export class BulkUpdateItemError {
	/**
	 * Required: false
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	reason:string = undefined;
}
/**
 * An item in the bulk api response
 */
export class BulkApiRespItem {
	/**
	 * Required: false
	 * Array: false
	 */
	update:BulkUpdateResp = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	index:BulkUpdateResp = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	create:BulkUpdateResp = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	delete:BulkUpdateResp = undefined;
}
export class ExpectationSearchFilterNot {
	/**
	 * Required: false
	 * Array: true
	 */
	and:ExpectationSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:ExpectationSearchFilterOr[] = undefined;
}
export class ExpectationSearchFilterOr {
	/**
	 * Required: false
	 * Array: false
	 */
	expectationSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expectationSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shiftCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shiftCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	district:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	district_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	operationalRegion:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	operationalRegion_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:ExpectationSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:ExpectationSearchFilterNot[] = undefined;
	public toJSON = function(this: ExpectationSearchFilterOr) {
		return {
			"expectationSic" : this.expectationSic,
			"expectationSic.keyword" : this.expectationSic_keyword,
			"shiftCd" : this.shiftCd,
			"shiftCd.keyword" : this.shiftCd_keyword,
			"regionCd" : this.regionCd,
			"regionCd.keyword" : this.regionCd_keyword,
			"createdTmst" : this.createdTmst,
			"createdTmst.keyword" : this.createdTmst_keyword,
			"district" : this.district,
			"district.keyword" : this.district_keyword,
			"operationalRegion" : this.operationalRegion,
			"operationalRegion.keyword" : this.operationalRegion_keyword,
			"and" : this.and,
			"not" : this.not		}
	}
}
export class ExpectationSearchFilterAnd {
	/**
	 * Required: false
	 * Array: false
	 */
	expectationSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expectationSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shiftCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shiftCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	district:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	district_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	operationalRegion:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	operationalRegion_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:ExpectationSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:ExpectationSearchFilterNot[] = undefined;
	public toJSON = function(this: ExpectationSearchFilterAnd) {
		return {
			"expectationSic" : this.expectationSic,
			"expectationSic.keyword" : this.expectationSic_keyword,
			"shiftCd" : this.shiftCd,
			"shiftCd.keyword" : this.shiftCd_keyword,
			"regionCd" : this.regionCd,
			"regionCd.keyword" : this.regionCd_keyword,
			"createdTmst" : this.createdTmst,
			"createdTmst.keyword" : this.createdTmst_keyword,
			"district" : this.district,
			"district.keyword" : this.district_keyword,
			"operationalRegion" : this.operationalRegion,
			"operationalRegion.keyword" : this.operationalRegion_keyword,
			"or" : this.or,
			"not" : this.not		}
	}
}
export class ExpectationSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expectationSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expectationSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shiftCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shiftCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	district:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	district_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	operationalRegion:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	operationalRegion_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:ExpectationSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:ExpectationSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:ExpectationSearchFilterNot[] = undefined;
	public toJSON = function(this: ExpectationSearchFilter) {
		return {
			"q" : this.q,
			"b" : this.b,
			"expectationSic" : this.expectationSic,
			"expectationSic.keyword" : this.expectationSic_keyword,
			"shiftCd" : this.shiftCd,
			"shiftCd.keyword" : this.shiftCd_keyword,
			"regionCd" : this.regionCd,
			"regionCd.keyword" : this.regionCd_keyword,
			"createdTmst" : this.createdTmst,
			"createdTmst.keyword" : this.createdTmst_keyword,
			"district" : this.district,
			"district.keyword" : this.district_keyword,
			"operationalRegion" : this.operationalRegion,
			"operationalRegion.keyword" : this.operationalRegion_keyword,
			"and" : this.and,
			"or" : this.or,
			"not" : this.not		}
	}
}
/**
 * Path parameters
 */
export class GetElasticDocumentExpectationPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentExpectationQuery {
	/**
	 * Source filter
	 * Required: false
	 * Array: false
	 */
	_source:string = undefined;
	/**
	 * Source include filter
	 * Required: false
	 * Array: false
	 */
	_source_includes:string = undefined;
	/**
	 * Source exclude filter
	 * Required: false
	 * Array: false
	 */
	_source_excludes:string = undefined;
}
/**
 * Response from getElasticDocument.
 */
export class GetElasticDocumentExpectationResp {
	/**
	 * The record if found
	 * Required: false
	 * Array: false
	 */
	record:ExpectationSearchRecord = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentExpectationCountQuery {
	/**
	 * Query filter
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
}
/**
 * Response from getElasticDocumentCount.
 */
export class GetElasticDocumentExpectationCountResp {
	/**
	 * The count of the documents
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
}
/**
 * Request message to update information for Expectation.
 */
export class UpdateElasticDocumentExpectationRqst {
	/**
	 * The record to upsert in elastic
	 * Required: false
	 * Array: false
	 */
	doc:ExpectationSearchRecord = undefined;
	/**
	 * Indicate if an upsert operation should be executed if the document was not found
	 * Required: false
	 * Array: false
	 */
	doc_as_upsert:boolean = undefined;
}
/**
 * Path parameters
 */
export class UpdateElasticDocumentExpectationPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
}
/**
 * Response from updateElasticDocument.
 */
export class UpdateElasticDocumentExpectationResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Response from bulkUpdateElasticDocument.
 */
export class BulkUpdateElasticDocumentExpectationResp {
	/**
	 * The time it took to process this request
	 * Required: false
	 * Array: false
	 */
	took:number = undefined;
	/**
	 * Indicates if there are any errors
	 * Required: false
	 * Array: false
	 */
	errors:boolean = undefined;
	/**
	 * Results of the bulk operations
	 * Required: false
	 * Array: true
	 */
	items:BulkApiRespItem[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteElasticDocumentExpectationPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
}
/**
 * Request message to delete information for Expectation.
 */
export class DeleteElasticDocumentExpectationResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Request message to search information for expectation-unfiltered.
 */
export class SearchExpectationUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:ExpectationSearchFilter = undefined;
}
/**
 * Response to the request to search information for expectation-unfiltered.
 */
export class SearchExpectationUnfilteredResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:ExpectationSearchRecord[] = undefined;
}
/**
 * Request message to export information for expectation-unfiltered.
 */
export class ExportExpectationUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileType:string = undefined;
	/**
	 * Query for exporting
	 * Required: false
	 * Array: false
	 */
	filter:ExpectationSearchFilter = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterExpectationUnfilteredRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:ExpectationSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterExpectationUnfilteredResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterExpectationUnfilteredRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedExpectationUnfilteredRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:ExpectationSearchRecord[] = undefined;
}
/**
 * This records parameters for creating check point.  Added 01/21/2020
 */
export class CheckpointMaster {
	/**
	 * Action that can have Agenda/Alerts set up. Ex: ARRIVE, DISPATCH
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * Number of minutes before the due time when checking should start
	 * Required: false
	 * Array: false
	 */
	startCheckBeforeMinutes:number = undefined;
	/**
	 * Frequency of performing the check defined in the Agenda Item every so many minutes.
	 * Required: false
	 * Array: false
	 */
	frequencyMinutes:number = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Exception {
	/**
	 * Primary key, sequential Exception ID.
	 * Required: false
	 * Array: false
	 */
	exceptionInstId:number = undefined;
	/**
	 * Priority of the Exception
	 * 
	 * VALID VALUES
	 * URGENT
	 * HIGH
	 * MEDIUM
	 * LOW
	 * Required: false
	 * Array: false
	 */
	priority:string = undefined;
	/**
	 * Refers to current status of exception (e.g. Assigned). Set of statuses is different for different exception types
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * Date-timestamp of when exception was posted to the system
	 * Required: false
	 * Array: false
	 */
	postedDateTimeUtc:Date = undefined;
	/**
	 * Date-timestamp when exception becomes active in the system. Because of the proactive nature of the exception management system, POSTED_DATE_TIME and ACTIVE_DATE_TIME may be different
	 * Required: false
	 * Array: false
	 */
	activeDateTimeUtc:Date = undefined;
	/**
	 * Date-timestamp of when exception was resolved (null while it is in progress)
	 * Required: false
	 * Array: false
	 */
	resolvedDateTimeUtc:Date = undefined;
	/**
	 * Time period in minutes after which exception automatically expires. 0 means that exception never expires and can only be resolved by a person or a system
	 * Required: false
	 * Array: false
	 */
	nbrOfMinimumBeforeExpiry:number = undefined;
	/**
	 * Free-form text enterd on resolution of the exception, blank otherwise
	 * Required: false
	 * Array: false
	 */
	resolution:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationException:OperationException = undefined;
}
/**
 * Reference table that holds all Request Reasons for an Exception.  Added 05/05/2020
 */
export class ExceptionRequestReason {
	/**
	 * Request reason for the exception - unique
	 * Required: false
	 * Array: false
	 */
	requestReasonCd:string = undefined;
	/**
	 * Description of the request reason
	 * Required: false
	 * Array: false
	 */
	reasonDescription:string = undefined;
	/**
	 * Each REASON_TYPE_CD can have one or more actual REASON_CDs.
	 * 
	 * VALID VALUES matrix:
	 * 
	 * REASON_TYPE_CD                                  REQUEST_RSN_CD                                 REASON_DESC
	 * REASON_CD                                 NATURAL_EMPTY                                 Natural Empty
	 * REASON_CD                                 HSS_CHANGE_CANCEL                 HSS change/cancel
	 * REASON_CD                                 MARGINAL_CUBE                                 Marginal Cube trailer cut
	 * REASON_CD                                 OP_MAND_MAT                                 Optional/Mandatory Meet and Turn
	 * REASON_CD                                 WEATHER                                 Weather
	 * APPROVE_REASON_CD                 ALLOWABLE_LOADS                 Approve to Allowable Loads
	 * APPROVE_REASON_CD                 SERVICE_FREIGHT                                 Approve to Service Freight
	 * DECLINE_REASON_CD                 NOT_REQUIRED
	 * Required: false
	 * Array: false
	 */
	reasonTypeCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ExpectationDetail {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationDetailSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	valueKey:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	value:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	valueDateTimeUtc:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:ExpectationStatusCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	exception:Exception[] = undefined;
}
/**
 * 
 */
export class ExpectationHeader {
	/**
	 * Primary key, sequential number
	 * Required: false
	 * Array: false
	 */
	expectationInstId:number = undefined;
	/**
	 * Date for which this expectation was created for
	 * Required: false
	 * Array: false
	 */
	expectationDate:string = undefined;
	/**
	 * Shift Code this Expectations applies to
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * SIC the expectation was created for
	 * Required: false
	 * Array: false
	 */
	expectationSic:string = undefined;
	/**
	 * Destination SIC for a lane or for a shipment
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * Door for which expectation is set up. If not at the door level - null
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * Trailer ID for which expectation is set up. If not at trailer level, then null
	 * Required: false
	 * Array: false
	 */
	trailerId:number = undefined;
	/**
	 * Concatenated version of Trailer Prefix + Suffix; Trailer NBR for which expectation is set up. If not at trailer level, then null
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * Shipment PRO for which expectation is set up. If not at the PRO level - null
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The region that the Expection SIC is in.  Added 10/02/2019
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	expectationDetail:ExpectationDetail[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationType:ExpectationType = undefined;
}
/**
 * 
 */
export class ExpectationType {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeDescription:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ExpectationTypeRole {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationTypeRoleInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeRoleId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class OperationException {
	/**
	 * Primary key, sequential Exception ID.
	 * Required: false
	 * Array: false
	 */
	exceptionInstId:number = undefined;
	/**
	 * SIC that logged this exception
	 * Required: false
	 * Array: false
	 */
	loggedSic:string = undefined;
	/**
	 * SIC that is responsible for handling this exception (for linehaul may be different from LOGGED_SIC )
	 * Required: false
	 * Array: false
	 */
	responsibleSic:string = undefined;
	/**
	 * SIC that resolved the exception
	 * Required: false
	 * Array: false
	 */
	resolvedSic:string = undefined;
	/**
	 * Guaranteed, Appointment, etc. Default - LTL, regular shipment
	 * Required: false
	 * Array: false
	 */
	ltlProduct:string = undefined;
	/**
	 * 'Bill To' customer nbr
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Dock door (can be null if exception doesnt involve dock door)
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * XPO Trailer ID (can be null if exception doesnt involve trailer)
	 * Required: false
	 * Array: false
	 */
	trailerId:number = undefined;
	/**
	 * Concatenated version of Trailer Prefix + Suffix; XPO Trailer number (can be null if exception doesnt involve trailer)
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * Type of exception encountered.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	exceptionTypeCd:string = undefined;
	/**
	 * District where exception occurred.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	district:string = undefined;
	/**
	 * Operational region where exception occurred.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	operationalRegion:string = undefined;
	/**
	 * Linehaul region where exception occurred.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	linehaulRegion:string = undefined;
	/**
	 * Destination sic code of trailer.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	destinationSicCode:string = undefined;
	/**
	 * Shift  in which existing exception was created. Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * Key for exception meta data.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	valueKey:string = undefined;
	/**
	 * Value of the exception metadata.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	value:string = undefined;
	/**
	 * Move to sic code of the trailer or lane.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	moveToSicCode:string = undefined;
	/**
	 * Load to sic code of the trailer or lane.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	loadToSicCode:string = undefined;
	/**
	 * Linehaul plan date of the exception.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Linehaul model run id of the exception. Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	runId:number = undefined;
	/**
	 * The linehaul plan shift where this load originated on - it might be from previous shift.  Added 05/01/2020
	 * Required: false
	 * Array: false
	 */
	planShiftCd:string = undefined;
	/**
	 * Request Reason for the Exception (comes from XCP_EXCEPTION_REQUEST_RSN)  Added 05/05/2020
	 * Required: false
	 * Array: false
	 */
	requestReasonCd:string = undefined;
	/**
	 * Number of additional loads requested.  Added 05/05/2020
	 * Required: false
	 * Array: false
	 */
	loadRequestedQuantity:number = undefined;
	/**
	 * Number of additional loads approved.  Added 05/05/2020
	 * Required: false
	 * Array: false
	 */
	loadApprovedQuantity:number = undefined;
	/**
	 * Number of additional loads rejected/declined.  Added 05/05/2020
	 * Required: false
	 * Array: false
	 */
	loadRejectedQuantity:number = undefined;
	/**
	 * Type of approval. Added 05/05/2020
	 * Required: false
	 * Array: false
	 */
	approvalTypeCd:string = undefined;
	/**
	 * Reason for approved loads (comes from XCP_EXCEPTION_REQUEST_RSN).  Added 05/05/2020
	 * Required: false
	 * Array: false
	 */
	approveReasonCd:string = undefined;
	/**
	 * Shipment PRO (can be null is exception is not at shipment level)
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Actual value from of exception detail that didnt meet defined expectation. 06/23/2020 Increased length from 50 to 200.
	 * Required: false
	 * Array: false
	 */
	actualValue:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The generated unique identifier for the Xflow item.
	 * Required: false
	 * Array: true
	 */
	itemInstIds:number[] = undefined;
	/**
	 * The name of the employee.
	 * Required: false
	 * Array: false
	 */
	employeeName:string = undefined;
	/**
	 * Reason Code for closing or cancelling an alert or exception  Added 05/05/2020.  Renamed from DECLINE_REASON_CD 10/05/2020  LISG-7691
	 * Required: false
	 * Array: false
	 */
	cancelReasonCd:string = undefined;
}
/**
 * Agenda is a collection of agenda items ordered sequentially. An agenda item is an action to be performed by certain time for a given PRO, in most cases at a given service center. Example Load this shipment on a trailer at UPO by 5pm on 1/3/2020. The agenda will be created when confirm on board or Billed (whichever happens first).  This records the list of expectations.  Added 01/21/2020
 */
export class ShipmentAgendaItem {
	/**
	 * This will be PSEG for all movement related actions. For Appointment and delivery, this will be PARTLOT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Actions that need to happen by certain time. EX: LOAD_TRLR, DISPATCH.
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * Allows an order of agenda items for a given action for a PRO to be defined.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * SIC where the action needs to happen
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * Shift when the action needs to happen.
	 * 
	 * Valid values:
	 * NIGHT_FAC
	 * DAY_RESHIP
	 * INB
	 * OTB
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The action must be completed by this time
	 * Required: false
	 * Array: false
	 */
	dueDateTime:Date = undefined;
	/**
	 * Status of the Agenda item.
	 * 
	 * Valid Values:
	 * NOT_DUE(until due tm), 
	 * MET (at or before due tm),
	 * NOT_MET (past due tim), 
	 * CANCEL (manually). 
	 * NOT_DUE can go to any other status. 
	 * 
	 * Note:
	 * MET is generally the final status (but can flip to not met if action is load and after the load the PRO was unloaded to be reloaded), CANCEL is a final status. NOT_MET is final status (except when MET was changed temporarily because of unload for reload)
	 * Required: false
	 * Array: false
	 */
	statusCd:AgendaItemStatusCd = undefined;
	/**
	 * Checking for the completion of the action will be checked starting from this time onwards.
	 * Required: false
	 * Array: false
	 */
	checkpointStartDateTime:Date = undefined;
	/**
	 * Frequency of performing the check defined in the Agenda Item every so many minutes.
	 * Required: false
	 * Array: false
	 */
	checkpointFrequencyMinutes:number = undefined;
	/**
	 * Time when the exception occurred
	 * Required: false
	 * Array: false
	 */
	exceptionCreatedDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * A check will be performed based on frequency defined in Agenda to see if the given action of an agenda was completed. If action is missed, then an Alert is created in this table. Added 01/21/2020
 */
export class ShipmentCheckpointAlert {
	/**
	 * This will be PSEG for all movement related actions. For Appointment and delivery, this will be PARTLOT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Actions that need to happen by certain time. EX: LOAD_TRLR, DISPATCH.
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * Allows an order of agenda items for a given action for a PRO to be defined.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * Time when Alert was sent.
	 * Required: false
	 * Array: false
	 */
	alertDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Records a shipment checkpoint event. The event describes a point in time to verify if an action for an agenda item has been met or not.  Added 02/27/2020
 */
export class ShipmentCheckpointEvent {
	/**
	 * This will be PSEG for all movement related actions. For Appointment and delivery, this will be PARTLOT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Actions that need to happen by certain time. EX: LOAD_TRLR, DISPATCH.
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * Time that checkpoint event should be released for process.
	 * Required: false
	 * Array: false
	 */
	checkpointReleaseDateTime:Date = undefined;
	/**
	 * Allows an order of agenda items for a given action for a PRO to be defined.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * SIC where the action needs to happen
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * Shift when the action needs to happen.
	 * 
	 * Valid values:
	 * NIGHT_FAC
	 * DAY_RESHIP
	 * INB
	 * OTB
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * Type of event to raise if checkpoint fails.
	 * 
	 * Valid Values:
	 * ALERT
	 * EXCEPTION
	 * Required: false
	 * Array: false
	 */
	checkpointTypeCd:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Number of seconds to hold event before publishing.  Added 03/18/2020
	 * Required: false
	 * Array: false
	 */
	checkpointReleaseDelayScnd:number = undefined;
}
/**
 * Holds a snapshot of ShipmentCheckpointEvent. Contains the details at the time on an event. It also provides the previous values if an update occured
 * Records a shipment checkpoint event. The event describes a point in time to verify if an action for an agenda item has been met or not.  Added 02/27/2020
 */
export class ShipmentCheckpointEventSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:ShipmentCheckpointEvent = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:ShipmentCheckpointEvent = undefined;
}
/**
 * Agenda is a collection of agenda items ordered sequentially. An agenda item is an action to be performed by certain time for a given PRO, in most cases at a given service center. Example Load this shipment on a trailer at UPO by 5pm on 1/3/2020. The agenda will be created when confirm on board or Billed (whichever happens first).  This records the list of expectations.  Added 01/21/2020
 */
export class TrailerAgendaItem {
	/**
	 * Trailer ID for which Agenda item is set up.
	 * Required: false
	 * Array: false
	 */
	trailerId:number = undefined;
	/**
	 * Actions that need to happen by certain time. EX: LOAD_TRLR, DISPATCH.
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * Allows an order of agenda items for a given action for a PRO to be defined.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * Concatenated version of Trailer Prefix + Suffix; Trailer NBR for which Agenda Item is set up.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * SIC where the action needs to happen
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * Shift when the action needs to happen.
	 * 
	 * Valid values:
	 * NIGHT_FAC
	 * DAY_RESHIP
	 * INB
	 * OTB
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The action must be completed by this time
	 * Required: false
	 * Array: false
	 */
	dueDateTime:Date = undefined;
	/**
	 * Status of the Agenda item.
	 * 
	 * Valid Values:
	 * NOT_DUE(until due tm), 
	 * MET (at or before due tm),
	 * NOT_MET (past due tim), 
	 * CANCEL (manually). 
	 * NOT_DUE can go to any other status. 
	 * 
	 * Note:
	 * MET is generally the final status (but can flip to not met if action is load and after the load the PRO was unloaded to be reloaded), CANCEL is a final status. NOT_MET is final status (except when MET was changed temporarily because of unload for reload)
	 * Required: false
	 * Array: false
	 */
	statusCd:AgendaItemStatusCd = undefined;
	/**
	 * Checking for the completion of the action will be checked starting from this time onwards.
	 * Required: false
	 * Array: false
	 */
	checkpointStartDateTime:Date = undefined;
	/**
	 * Frequency of performing the check defined in the Agenda Item every so many minutes.
	 * Required: false
	 * Array: false
	 */
	checkpointFrequencyMinutes:number = undefined;
	/**
	 * Time when the exception occurred
	 * Required: false
	 * Array: false
	 */
	exceptionCreatedDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * A check will be performed based on frequency defined in Agenda to see if the given action of an agenda was completed. If action is missed, then an Alert is created in this table. Added 01/21/2020
 */
export class TrailerCheckpointAlert {
	/**
	 * Trailer ID for which Agenda item is set up.
	 * Required: false
	 * Array: false
	 */
	trailerId:number = undefined;
	/**
	 * Actions that need to happen by certain time. EX: LOAD_TRLR, DISPATCH.
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * Allows an order of agenda items for a given action for a PRO to be defined.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * Time when Alert was sent.
	 * Required: false
	 * Array: false
	 */
	alertDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * Records a Trailer checkpoint event. The event describes a point in time to verify if an action for an agenda item has been met or not.  Added 02/27/2020
 */
export class TrailerCheckpointEvent {
	/**
	 * Trailer ID for which Agenda item is set up.
	 * Required: false
	 * Array: false
	 */
	trailerId:number = undefined;
	/**
	 * Actions that need to happen by certain time. EX: LOAD_TRLR, DISPATCH.
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * Time that checkpoint event should be released for process.
	 * Required: false
	 * Array: false
	 */
	checkpointReleaseDateTime:Date = undefined;
	/**
	 * Allows an order of agenda items for a given action for a PRO to be defined.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * SIC where the action needs to happen
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * Shift when the action needs to happen.
	 * 
	 * Valid values:
	 * NIGHT_FAC
	 * DAY_RESHIP
	 * INB
	 * OTB
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * Type of event to raise if checkpoint fails.
	 * 
	 * Valid Values:
	 * ALERT
	 * EXCEPTION
	 * Required: false
	 * Array: false
	 */
	checkpointTypeCd:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Number of seconds to hold event before publishing.  Added 03/18/2020
	 * Required: false
	 * Array: false
	 */
	checkpointReleaseDelayScnd:number = undefined;
}
/**
 * Holds a snapshot of TrailerCheckpointEvent. Contains the details at the time on an event. It also provides the previous values if an update occured
 * Records a Trailer checkpoint event. The event describes a point in time to verify if an action for an agenda item has been met or not.  Added 02/27/2020
 */
export class TrailerCheckpointEventSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:TrailerCheckpointEvent = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:TrailerCheckpointEvent = undefined;
}
/**
 * Details about employees that process and approve claims.
 */
export class InterfaceEmployee {
	/**
	 * Employee Id of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * First Name of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Middle Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	middleName:string = undefined;
	/**
	 * Last Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Job Description of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * The domicile SIC location for the employee.
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * The employee id of this employee's supervisor.
	 * Required: false
	 * Array: false
	 */
	supervisorEmployeeId:string = undefined;
	/**
	 * Desc: The business phone number of the employee.  It is sourced from HRS_EMPL_PHONE_VW in DB2 for typ_cd = 'B'.  01/10/2019 Added by Cathy
	 * Required: false
	 * Array: false
	 */
	businessPhoneTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	racfId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrCellPhone:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Desc: Stores information about the items in a queue that need to be assigned and processed.
 */
export class Item {
	/**
	 * The generated unique identifier for the item
	 * Required: false
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * Desc: Current status of the item in the queue.  Valid values:  TBD
	 * Required: false
	 * Array: false
	 */
	currentStatusCd:string = undefined;
	/**
	 * Desc: Employee that is currently assigned to the item in the queue.
	 * Required: false
	 * Array: false
	 */
	assigneeEmployeeId:string = undefined;
	/**
	 * Desc: Employee that currently has the item locked for editing.
	 * Required: false
	 * Array: false
	 */
	lockedByEmployeeId:string = undefined;
	/**
	 * Desc: The timestamp when the item was locked for editing.
	 * Required: false
	 * Array: false
	 */
	lockedDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * IDM/HRS role of the employee taking action on the queue item. 4/15/19 Added
	 * Required: false
	 * Array: false
	 */
	assigneeEmplRoleId:string = undefined;
	/**
	 * The unique identifier for the instance of the application object that the workflow item is for.
	 * Required: false
	 * Array: false
	 */
	applicationInstId:string = undefined;
}
/**
 * Desc: Master data identifying the priority that could be assigned to an item in a queue.
 */
export class Priority {
	/**
	 * Desc: Identifies the priority value that could be assigned to an item in a queue.
	 * Required: false
	 * Array: false
	 */
	priorityCd:string = undefined;
	/**
	 * Desc: Description for a priority value that could be assigned to an item in a queue.
	 * Required: false
	 * Array: false
	 */
	priorityDescription:string = undefined;
	/**
	 * Desc: Sequence order for a priority value that could be assigned to an item in a queue.
	 * Required: false
	 * Array: false
	 */
	priorityOrderNbr:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Desc: Stores information about a grouping of items that need to be assigned and processed.
 */
export class Queue {
	/**
	 * Desc: Unique identifier for the queue.
	 * Required: false
	 * Array: false
	 */
	queueInstId:number = undefined;
	/**
	 * Desc: Name of the queue.
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Desc: Duration in minutes of any lock that is placed on items in a queue.
	 * Required: false
	 * Array: false
	 */
	lockDurationMinutes:number = undefined;
	/**
	 * Desc: Description of the queue.
	 * Required: false
	 * Array: false
	 */
	queueDescription:string = undefined;
	/**
	 * Desc: Email address for queue notifications.
	 * Required: false
	 * Array: false
	 */
	notifyEmailAddress:string = undefined;
	/**
	 * Desc: Identifies the type of selection used to allow assignments of items in the queue.  Valid values:  LIST, TOP_OF_STACK_NEXT
	 * Required: false
	 * Array: false
	 */
	selectionModeCd:WorkflowSelectionModeCd = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Identifies the priority value that could be 
	 * 
	 * assigned to an item in a queue.  Added 5/1/2019
	 * Required: false
	 * Array: false
	 */
	priorityCd:string = undefined;
}
/**
 * Desc: Stores information about the role assigned to the queue.
 */
export class QueueRoleAssignment {
	/**
	 * Desc: Unique identifier for the queue.
	 * Required: false
	 * Array: false
	 */
	queueInstId:number = undefined;
	/**
	 * Desc: IDM Role Id that is associated with the queue.
	 * Required: false
	 * Array: false
	 */
	employeeRoleId:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Desc: Order in which to evaluate the Queue assigned to a role
	 * Required: false
	 * Array: false
	 */
	roleOrderNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Desc: Stores history of status changes for an item in a queue.
 */
export class StatusLog {
	/**
	 * The generated unique identifier for the item
	 * Required: false
	 * Array: false
	 */
	itemInstId:number = undefined;
	/**
	 * Desc: The timestamp associated with the status of the item in the queue.
	 * Required: false
	 * Array: false
	 */
	statusDateTime:Date = undefined;
	/**
	 * Desc: Identifies the status of the item in the queue.  05/24/2019 Increased length from 20 to 50
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * The role id for the user that 
	 * 
	 * last updated the status of the item. Added 6/7/2019
	 * Required: false
	 * Array: false
	 */
	lastUpdateRoleId:string = undefined;
	/**
	 * User supplied comment about the status change on an item.  Added 05/13/2020
	 * Required: false
	 * Array: false
	 */
	statusUpdateComment:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * The basic rule data
 */
export class Rule {
	/**
	 * Desc: The rule Unique identifier; generated when rule is created
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
	/**
	 * Desc: Descriptive Name of the rule
	 * Required: false
	 * Array: false
	 */
	ruleName:string = undefined;
	/**
	 * Desc: Description of what the rule does
	 * Required: false
	 * Array: false
	 */
	ruleDescription:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Desc: Identifies a rule as being a precondition rule.  Precondition rules are used to determine which rules to run.
	 * Required: false
	 * Array: false
	 */
	preconditionInd:boolean = undefined;
	/**
	 * You can have multiple rules 
	 * 
	 * entered in the system that run from June-Aug, then Aug-Oct, and you want them both to 
	 * 
	 * be in the system but not be processed until the date being used for comparison is in 
	 * 
	 * that range.   Added 7/19/2019 as per Richard
	 * Required: false
	 * Array: false
	 */
	effectiveDateTime:Date = undefined;
	/**
	 * You can have multiple rules entered 
	 * 
	 * in the system that run from June-Aug, then Aug-Oct, and you want them both to be in 
	 * 
	 * the system but not be processed until the date being used for comparison is in that 
	 * 
	 * range.. Added 07/19/2019 as per Richard.
	 * Required: false
	 * Array: false
	 */
	expiryDateTime:Date = undefined;
	/**
	 * Allows a Rule to be in TEST mode 
	 * 
	 * before it can run fully. When the rule is ready to run, then it will be set to PROD.  
	 * 
	 * If a rule is needed to be turned off, it can be set to INACTIVE.  Added 07/19/2019 as 
	 * 
	 * per Richard.
	 * 
	 * VALID VALUES:
	 * TEST
	 * PROD
	 * INACTIVE
	 * Required: false
	 * Array: false
	 */
	activeTypeCd:RuleActiveTypeCd = undefined;
	/**
	 * The rule details. A ruleset is associated with a group of conditions(expressions) to evaluate using a specific logical operator. If the evaluation of the conditions is true, the ruleset will return a group of outcomes.
	 * Required: false
	 * Array: true
	 */
	ruleset:Ruleset[] = undefined;
}
/**
 * The rule details. A ruleset is associated with a group of conditions(expressions) to evaluate using a specific logical operator. If the evaluation of the conditions is true, the ruleset will return a group of outcomes.
 */
export class Ruleset {
	/**
	 * Desc: The ruleset Unique identifier; generated when the ruleset is created
	 * Required: false
	 * Array: false
	 */
	rulesetInstId:number = undefined;
	/**
	 * The priority order for the execution of the ruleset. If a rule is associated with multiple rulesets, the ruleset with the highest priority is executed first. The number 1 is a higher priority than 2 and so on.
	 * Required: false
	 * Array: false
	 */
	priorityOrderNbr:number = undefined;
	/**
	 * Desc: Identifies the logical operator value. The Valid Values are: AND, OR
	 * Required: false
	 * Array: false
	 */
	logicalOperatorCd:RuleLogicalOperatorCd = undefined;
	/**
	 * Desc: The action that should be performed if the logical operator function is true.
	 * Required: false
	 * Array: false
	 */
	actionCallBack:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists. The valid values are:
	 * ADD - Add entry to list
	 * DELETE - Delete entry from list
	 * UPDATE - Update entry
	 * NO_ACTION
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Descriptive Name of the ruleset.  Added 5/1/2019
	 * Required: false
	 * Array: false
	 */
	rulesetName:string = undefined;
	/**
	 * The group of conditions(expressions) to be evaluated for the ruleset.
	 * Required: false
	 * Array: true
	 */
	ruleExpression:RuleExpression[] = undefined;
	/**
	 * The group of outcomes to be returned if the conditions of the ruleset are evaluated to true.
	 * Required: false
	 * Array: true
	 */
	ruleOutcome:RuleOutcome[] = undefined;
}
/**
 * The outcome is a key,value entry to be returned if the conditions of the ruleset are evaluated to true.
 */
export class RuleOutcome {
	/**
	 * Desc: the outcome Unique identifier; generated when the outcome is created.
	 * Required: false
	 * Array: false
	 */
	outcomeInstId:number = undefined;
	/**
	 * Added as an additional part of PK so that Outcome name/value pairs can be grouped. 4/15/2019
	 * Required: false
	 * Array: false
	 */
	outcomeSeqNbr:number = undefined;
	/**
	 * Desc: The key/name of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeName:string = undefined;
	/**
	 * The value of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeValue:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists. The valid values are:
	 * ADD - Add entry to list
	 * DELETE - Delete entry from list
	 * UPDATE - Update entry
	 * NO_ACTION
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * A rule expression is a condition to be evaluated in the rule.
 */
export class RuleExpression {
	/**
	 * The generated unique identifier.
	 * Required: false
	 * Array: false
	 */
	expressionInstId:number = undefined;
	/**
	 * Desc: Name of the attribute to be used in the logical expression.
	 * Required: false
	 * Array: false
	 */
	leftOperand:string = undefined;
	/**
	 * Desc: The operand to be used in the logical expression. The valid values are: 
	 * EQUAL_TO
	 * NOT_EQUAL_TO
	 * GREATER_THAN
	 * GREATER_THAN_OR_EQUAL
	 * IN
	 * NOT_IN
	 * LESS_THAN
	 * LESS_THAN_OR_EQUAL
	 * MATCHES
	 * NOT_MATCHES
	 * Required: false
	 * Array: false
	 */
	operatorCd:RuleOperatorCd = undefined;
	/**
	 * Desc: The type of value of the attribute to be used in the logical expression. The valid values are:
	 * BOOLEAN
	 * DATE_TIME
	 * LIST
	 * NUMBER
	 * TEXT
	 * Required: false
	 * Array: false
	 */
	valueTypeCd:RuleExpressionValueTypeCd = undefined;
	/**
	 * Desc: The value of the attribute to be used in the logical expression.
	 * Required: false
	 * Array: false
	 */
	value:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class ExceptionKafkaMessage extends ExpectationSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	searchRecordMessageHeader:KafkaMessageHeader = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class ExpectationSearchRecordMessage extends ExpectationSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	messageHeader:SearchRecordMessageHeader = undefined;
}




import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { detect } from 'detect-browser';


@Component({
  selector: 'xpo-utils-version-banner',
  template: `<div class="version-banner" fxLayout="row">
  <div fxFlex="" class="version-banner__font">Version: {{buildVersion}} - {{browserInfo.name}}: {{browserInfo.version}}</div>
</div>
`,
  styles: [`.version-banner{display:flex;padding:16px 0;align-self:center;left:calc(50% - 120px);position:absolute}.version-banner__font{color:rgba(255,255,255,.87);font-size:12px}`],
  encapsulation: ViewEncapsulation.None
})
export class VersionBannerComponent implements OnInit {

  public browserInfo;

  constructor(private configManagerService: ConfigManagerService) {
  }

  ngOnInit(): void {
    this.browserInfo = detect();
  }


  public get buildVersion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }

}
